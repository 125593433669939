"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    ref: "appMain",
    staticClass: "app-main",
    on: {
      scroll: _vm.handleScroll
    }
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("div", {
    staticClass: "content_wrap",
    class: {
      "fix-footer": _vm.fixFooter,
      "no-shadow": _vm.noShadowList.indexOf(_vm.$route.name) > -1
    }
  }, [_vm.noTitleList.indexOf(_vm.$route.name) === -1 ? _c("h3", {
    staticClass: "page_title"
  }, [_vm._v(_vm._s(_vm.$route.meta.title))]) : _vm._e(), _c("keep-alive", [_vm.$route.meta.keepAlive ? _c("router-view", {
    key: _vm.key,
    staticClass: "page_wrapper",
    class: {
      "no-padding": _vm.noPaddingList.indexOf(_vm.$route.name) > -1
    }
  }) : _vm._e()], 1), !_vm.$route.meta.keepAlive ? _c("router-view", {
    key: _vm.key,
    staticClass: "page_wrapper",
    class: {
      "no-padding": _vm.noPaddingList.indexOf(_vm.$route.name) > -1
    }
  }) : _vm._e()], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }]
  }, [_c("button", {
    on: {
      click: _vm.playSound
    }
  }, [_vm._v("播放声音")]), _c("audio", {
    ref: "audioPlayer",
    attrs: {
      controls: "",
      autoplay: ""
    }
  }, [_c("source", {
    attrs: {
      src: require("@/assets/sound/alarm_beeps.mp3"),
      type: "audio/mpeg"
    }
  }), _vm._v("\n            您的浏览器不支持 audio 元素。\n        ")])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showQos,
      expression: "showQos"
    }],
    staticClass: "qos-box"
  }, [_c("div", {
    staticClass: "close pointer",
    on: {
      click: function click($event) {
        return _vm.closeQos();
      }
    }
  }, [_vm._v("X")]), _c("div", {
    staticClass: "blue-font pointer qos-main",
    on: {
      click: function click($event) {
        return _vm.goQos();
      }
    }
  }, [_vm._v("问题反馈未处理,点击跳转")])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;