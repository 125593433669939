"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _vuex = require("vuex");
var _default = {
  data: function data() {
    return {};
  },
  methods: {
    getRouteName: function getRouteName() {
      var myRoute = this.permission_routes.find(function (item) {
        return item.name === "my";
      });
      if (myRoute) {
        var myIndexRoute = myRoute.children.find(function (c) {
          return c.name === "myIndex";
        });
        if (myIndexRoute) {
          return "myIndex";
        }
      }
      var routeName = this.permission_routes.find(function (item) {
        return !item.hidden && item.children;
      }).name;
      return routeName;
    }
  },
  mounted: function mounted() {
    var routeName = this.getRouteName();
    // const routeName = this.permission_routes.find(item => !item.hidden && item.children).name;
    this.$router.push({
      name: routeName
    });
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['permission_routes']))
};
exports.default = _default;