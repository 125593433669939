"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.dialogTitle,
      visible: _vm.handleDialog,
      "before-close": _vm.closeDialog,
      "close-on-click-modal": false,
      width: "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.handleDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "customForm",
    attrs: {
      model: _vm.customForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "选择品类：",
      "label-width": "140px",
      prop: "category_id"
    }
  }, [_vm.handleDialog ? _c("el-cascader", {
    attrs: {
      options: _vm.cateList,
      props: _vm.selectProps,
      "show-all-levels": false
    },
    on: {
      change: _vm.changeCascader
    },
    model: {
      value: _vm.customForm.category_id,
      callback: function callback($$v) {
        _vm.$set(_vm.customForm, "category_id", $$v);
      },
      expression: "customForm.category_id"
    }
  }) : _vm._e()], 1), _c("el-form-item", {
    attrs: {
      label: "字段排序：",
      "label-width": "140px",
      prop: "field_order"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.customForm.field_order,
      callback: function callback($$v) {
        _vm.$set(_vm.customForm, "field_order", $$v);
      },
      expression: "customForm.field_order"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "字段描述：",
      "label-width": "140px",
      prop: "field_title"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.customForm.field_title,
      callback: function callback($$v) {
        _vm.$set(_vm.customForm, "field_title", $$v);
      },
      expression: "customForm.field_title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "字段类型：",
      "label-width": "140px",
      prop: "field_type"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.customForm.field_type,
      callback: function callback($$v) {
        _vm.$set(_vm.customForm, "field_type", $$v);
      },
      expression: "customForm.field_type"
    }
  }, [_c("el-option", {
    attrs: {
      label: "文本框",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "单选",
      value: 2
    }
  }), _c("el-option", {
    attrs: {
      label: "多选",
      value: 3
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "字段值：",
      "label-width": "140px",
      prop: "field_val"
    }
  }, [_vm._l(_vm.tagsList, function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        closable: "",
        "disable-transitions": false
      },
      on: {
        close: function close($event) {
          return _vm.closeTag(tag);
        }
      }
    }, [_vm._v("\n        " + _vm._s(tag) + "\n      ")]);
  }), _vm.inputVisible ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      blur: _vm.tagInputConfirm
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.tagInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputValue,
      callback: function callback($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showInput
    }
  }, [_vm._v("+添加字段值")])], 2), _c("el-form-item", {
    attrs: {
      label: "必填项：",
      "label-width": "140px",
      prop: "must_is"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.customForm.must_is,
      callback: function callback($$v) {
        _vm.$set(_vm.customForm, "must_is", $$v);
      },
      expression: "customForm.must_is"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("是")]), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("否")])], 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("customForm");
      }
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;