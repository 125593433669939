"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "errPage-container"
  }, [_c("el-button", {
    staticClass: "pan-back-btn",
    attrs: {
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.back
    }
  }, [_vm._v("\n    返回\n  ")]), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("h1", {
    staticClass: "text-jumbo text-ginormous"
  }, [_vm._v("\n        Oops!\n      ")]), _c("h2", [_vm._v("你没有权限去该页面")]), _c("ul", {
    staticClass: "list-unstyled"
  }, [_c("li", [_vm._v("或者你可以去:")]), _c("li", {
    staticClass: "link-type"
  }, [_c("router-link", {
    attrs: {
      to: "/"
    }
  }, [_vm._v("\n            回首页\n          ")])], 1)])]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("img", {
    attrs: {
      src: _vm.errGif,
      width: "313",
      height: "428",
      alt: "Girl has dropped her ice cream."
    }
  })])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;