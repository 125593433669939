"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _system = require("@/api/system");
var _info = require("@/api/info");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _previewImg = _interopRequireDefault(require("@/components/previewImg"));
var _addLink = _interopRequireDefault(require("./addLink"));
var _default = {
  components: {
    customPage: _customPage.default,
    previewImg: _previewImg.default,
    addLink: _addLink.default
  },
  data: function data() {
    return {
      appid: '',
      tableData: [],
      dialogTitle: '',
      handleDialog: false,
      isEdit: true,
      total: 0,
      searchForm: {
        title: ''
      },
      formData: {
        status: 1
      }
    };
  },
  mounted: function mounted() {
    this.getAlipayAppid();
    this.getList();
  },
  methods: {
    // 获取列表
    getList: function getList() {
      var _this = this;
      (0, _info.getAliApplinkList)({
        title: this.searchForm.title
      }).then(function (res) {
        _this.loading = false;
        _this.tableData = res;
      });
    },
    getAlipayAppid: function getAlipayAppid() {
      var _this2 = this;
      (0, _system.getAlipayAppid)().then(function (res) {
        if (res) {
          _this2.appid = res;
        }
      });
    },
    search: function search() {
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = Object.assign({});
      this.getList();
    },
    addLink: function addLink() {
      if (this.$refs.addLink.$refs.formData) {
        this.$refs.addLink.$refs.formData.resetFields();
      }
      this.dialogTitle = '新增链接';
      this.handleDialog = true;
      this.isEdit = true;
      this.formData = {
        status: 1
      };
    },
    submitForm: function submitForm(imagesList) {
      var _this3 = this;
      this.formData.images = imagesList;
      (0, _info.editAliAppLink)(this.formData).then(function (res) {
        _this3.handleDialog = false;
        _this3.getList();
      });
    },
    changeStatus: function changeStatus(id, status) {
      var _this4 = this;
      var title = status == 1 ? '隐藏' : '显示';
      this.$confirm('确认要修改状态吗?', title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _info.editAliAppLink)({
          id: id,
          status: status == 1 ? 0 : 1
        }).then(function (res) {
          _this4.getList();
        });
      });
    },
    editItem: function editItem(row) {
      if (this.$refs.addLink.$refs.formData) {
        this.$refs.addLink.$refs.formData.resetFields();
      }
      this.dialogTitle = '编辑链接';
      this.handleDialog = true;
      this.isEdit = false;
      var form = (0, _objectSpread2.default)({}, row);
      delete form.status;
      this.formData = form;
    },
    closeDialog: function closeDialog() {
      this.handleDialog = false;
    },
    alipayExample: function alipayExample(url) {
      var path = "alipays://platformapi/startapp?appId=".concat(this.appid, "&page=") + encodeURIComponent(url) + '&query=' + encodeURIComponent('channel=KEY');
      return path;
    },
    alipayH5Example: function alipayH5Example(url) {
      var path = 'https://ds.alipay.com/?scheme=' + encodeURIComponent(this.alipayExample(url));
      return path;
    },
    copySuccess: function copySuccess() {
      this.$message.success('已成功复制到粘贴板');
    }
  }
};
exports.default = _default;