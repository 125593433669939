"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _shop = require("@/api/shop");
var _default = {
  data: function data() {
    return {
      codeUrl: ''
    };
  },
  methods: {
    download: function download() {
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'Anonymous');
      image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL('image/png'); // 得到图片的base64编码数据
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '店铺二维码';
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };

      image.crossOrigin = ''; // 切记一定要将这个放在src赋值前,要不然会在safari上报安全错误
      image.src = this.codeUrl;
    },
    getData: function getData() {
      var _this = this;
      (0, _shop.shopQrcode)().then(function (res) {
        _this.codeUrl = res.data.qr_code_url;
      });
    }
  },
  mounted: function mounted() {
    this.getData();
  }
};
exports.default = _default;