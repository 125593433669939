import { render, staticRenderFns } from "./checkRisk.vue?vue&type=template&id=9e51149a&scoped=true&"
import script from "./checkRisk.vue?vue&type=script&lang=js&"
export * from "./checkRisk.vue?vue&type=script&lang=js&"
import style0 from "./checkRisk.vue?vue&type=style&index=0&id=9e51149a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e51149a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\iot_vehicle\\iot_frontend\\iot_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9e51149a')) {
      api.createRecord('9e51149a', component.options)
    } else {
      api.reload('9e51149a', component.options)
    }
    module.hot.accept("./checkRisk.vue?vue&type=template&id=9e51149a&scoped=true&", function () {
      api.rerender('9e51149a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dialogs/checkRisk.vue"
export default component.exports