"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "选择合同",
      visible: _vm.dialogVisible,
      width: "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "info-box"
  }, [_c("div", {
    staticClass: "order-info flex flex-wrap"
  }, _vm._l(_vm.filesList, function (item) {
    return _c("div", {
      staticClass: "list-item"
    }, [item.status == 1 && item.tempId ? [item.fileName == "租赁服务协议" ? [_vm.editItem.order_type != 2 ? _c("el-checkbox", {
      attrs: {
        value: true,
        label: item.tempId,
        size: "medium",
        disabled: "",
        border: ""
      }
    }, [_vm._v(_vm._s(item.fileName))]) : _vm._e()] : _c("el-checkbox", {
      attrs: {
        label: item.tempId,
        size: "medium",
        border: ""
      },
      model: {
        value: _vm.selectArr,
        callback: function callback($$v) {
          _vm.selectArr = $$v;
        },
        expression: "selectArr"
      }
    }, [_vm._v(_vm._s(item.fileName))])] : _vm._e()], 2);
  }), 0)]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("提交")]), _c("el-button", {
    attrs: {
      type: ""
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;