"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merchant = require("@/api/merchant");
var _default = {
  data: function data() {
    return {
      loading: false,
      activeStep: 0,
      balance: 0,
      frozen: 0,
      // 提现费率
      withdrawFeeRate: '',
      form: {
        type: 1,
        // 提现方式
        user: '',
        amount: '',
        // 申请金额
        cost_amount: 0,
        // 手续费
        arrival_amount: 0 // 到账金额
      },

      infoData: {}
    };
  },
  props: ['withdrawId'],
  watch: {
    $route: function $route(val) {
      console.log(val);
      if (this.withdrawId) {
        this.getInfo();
      } else {
        this.getAccount();
      }
    },
    'form.amount': function formAmount(val) {
      // console.log('form.amount', val)
      var costAmount = 0;
      if (val && this.withdrawFeeRate) {
        costAmount = val * this.withdrawFeeRate;
        costAmount = costAmount.toFixed(2);
      }
      this.form.cost_amount = costAmount;
      this.form.arrival_amount = val - costAmount;
    }
  },
  mounted: function mounted() {
    this.getData();
    if (this.withdrawId) {
      this.getInfo();
    } else {
      this.getAccount();
    }
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.loading = true;
      (0, _merchant.myAccount)().then(function (res) {
        _this.loading = false;
        var balance = res.balance,
          frozen = res.frozen;
        _this.balance = res.balance;
        _this.frozen = res.frozen;
        _this.withdrawFeeRate = res.withdraw_fee_rate || 0;
      });
    },
    getAccount: function getAccount() {
      var _this2 = this;
      this.activeStep = 0;
      (0, _merchant.getPayeeInfo)().then(function (res) {
        var payeename = res.payeename,
          alipayaccount = res.alipayaccount;
        if (alipayaccount) {
          _this2.form.user = alipayaccount + '（' + payeename + '）';
        }
      });
    },
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _merchant.withdrawalInfo)(this.withdrawId).then(function (res) {
        _this3.infoData = res && res.withdrawal_info ? res.withdrawal_info : {};
        console.log(_this3.infoData);
        _this3.activeStep = _this3.infoData.status == 2 ? 2 : 1;
      });
    },
    changeMoneyInput: function changeMoneyInput() {
      var pattern = /^[0-9]+([.]{1}[0-9]{1,2})?$/; // 正数的正则表达式
      // 不符合正整数时
      if (!pattern.test(this.form.amount)) {
        // input 框绑定的内容为空
        this.form.amount = '';
      }
    },
    submit: function submit() {
      var _this4 = this;
      if (!this.form.user) {
        this.$func.isError('提现信息不存在');
        return;
      }
      if (this.form.amount <= 0) {
        this.$func.isError('提现金额有误');
        return;
      }
      (0, _merchant.withdrawalApply)(this.form).then(function (res) {
        _this4.$router.push({
          name: 'merchantMyWithdraw',
          params: {
            withdrawId: res.withdrawal_id
          }
        });
      });
    }
  }
};
exports.default = _default;