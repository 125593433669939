"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _goods = require("@/api/goods");
var _textEditor = _interopRequireDefault(require("@/components/textEditor"));
var _default2 = {
  components: {
    textEditor: _textEditor.default
  },
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    var checkNum = function checkNum(rule, value, callback) {
      var reg = /^[0-9.]*$/;
      if (!value && value !== 0) {
        callback(new Error("请输入数字"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正数"));
      } else {
        callback();
      }
    };
    return {
      id: 0,
      formData: {},
      formDefault: {
        id: 0,
        sort: 0,
        status: 1
      },
      dialogVisible: false,
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 10,
          message: '长度在 1 到 10 个字符',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入数字',
          trigger: 'blur'
        }, {
          validator: checkNum,
          trigger: 'blur'
        }],
        settle_percen: [{
          required: true,
          message: '请输入数字',
          trigger: 'blur'
        }, {
          validator: checkNum,
          trigger: 'blur'
        }],
        goods_ids: [{
          required: true,
          message: '请输入商品id',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return '增值服务 - ' + (this.id ? '编辑' : '添加');
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        if (this.item.id) {
          this.formData = this.$func.deepClone(this.item);
          this.formData.settle_percen *= 100;
        } else {
          this.formData = this.$func.deepClone(this.formDefault);
        }
      } else {
        this.$refs.form.clearValidate();
      }
    }
  },
  methods: {
    open: function open() {
      this.dialogVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    chkPrice: function chkPrice(key) {
      this.formData[key] = this.$func.filterFloat(this.formData[key]);
    },
    onEditorChange: function onEditorChange(val) {
      this.formData.content = val;
    },
    submitForm: function submitForm() {
      var _this = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _this.$func.deepClone(_this.formData);
          param.settle_percen /= 100;
          (0, _goods.editServiceInfo)((0, _objectSpread2.default)({}, param)).then(function (res) {
            // console.log('submitForm', res)
            _this.$emit('getList');
            _this.close();
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default2;