"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _default = {
  components: {
    customPage: _customPage.default
  },
  props: ['orderId'],
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      tableData: [],
      total: 0
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.tableData = [];
        this.$nextTick(function () {
          _this.getList();
        });
      }
    }
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      this.loading = true;
      (0, _order.getOrderRemark)({
        page: page,
        pageSize: pageSize,
        orderId: this.orderId
      }).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total || 0;
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    delRemark: function delRemark(id) {
      var _this3 = this;
      this.$confirm('您确定要删除这条备注吗', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _order.deleteRemarkOne)(id).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;