"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "查看备注",
      visible: _vm.dialogVisible,
      width: "1000px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.tableData,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "备注信息",
      prop: "remark"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作人员",
      prop: "username",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "时间",
      prop: "create_time"
    }
  })], 1), _c("custom-page", {
    ref: "customPage",
    attrs: {
      total: _vm.total
    },
    on: {
      getList: _vm.getList
    }
  }), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")]), _c("el-button", {
    attrs: {
      type: "warning"
    },
    on: {
      click: _vm.add
    }
  }, [_vm._v("添加备注")])], 1), _c("add-dialog", {
    ref: "addDialog",
    attrs: {
      "order-id": _vm.orderId,
      "which-period": _vm.whichPeriod
    },
    on: {
      getList: _vm.getList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;