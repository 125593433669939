"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mapBox = _interopRequireDefault(require("@/components/map/mapBox"));
var _battery = require("@/api/iot/battery");
var _default = {
  props: ['editItem'],
  components: {
    mapBox: _mapBox.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      mapLoading: false,
      form: {
        bindDate: ''
      },
      pathLine: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          if (_this.editItem) {
            // 编辑
            // this.form = {
            //     id: this.editItem.id,
            //     username: this.editItem.username,
            //     mobile: this.editItem.mobile,
            //     role_id: this.editItem.role_id,
            // }
            console.log('this.editItem', _this.editItem);
            _this.getHistory();
          }
        });
      } else {
        this.reset();
      }
    }
  },
  methods: {
    reset: function reset() {
      this.form = this.$options.data().form;
      this.pathLine = [];
    },
    getHistory: function getHistory() {
      var _this2 = this;
      this.mapLoading = true;
      (0, _battery.getHistoryMByMUtc)({
        id: this.editItem.id
      }).then(function (res) {
        _this2.mapLoading = false;
        console.log('getHistoryMByMUtc', res);
        _this2.pathLine = res;
      }).catch(function () {
        _this2.mapLoading = false;
      });
    }
  }
};
exports.default = _default;