"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "rate_info footer-page"
  }, [_vm._m(0), _c("div", {}, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.delAllRedis();
      }
    }
  }, [_vm._v("清除所有缓存")])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("清除缓存")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;