"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "goods_dialog",
    attrs: {
      width: "1000px",
      title: "添加商品",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchForm
    }
  }, [_c("el-form-item", [_c("el-input", {
    attrs: {
      placeholder: "请输入商品ID或名称"
    },
    model: {
      value: _vm.searchForm.goods_name,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "goods_name", $$v);
      },
      expression: "searchForm.goods_name"
    }
  })], 1), _c("el-form-item", [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: "请选择商品品类"
    },
    model: {
      value: _vm.searchForm.cate_id,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "cate_id", $$v);
      },
      expression: "searchForm.cate_id"
    }
  }, _vm._l(_vm.categoryList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", [_c("el-input", {
    attrs: {
      placeholder: "请输入供应商",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.shop_name,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "shop_name", $$v);
      },
      expression: "searchForm.shop_name"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.search();
      }
    }
  }, [_vm._v("查询")]), _c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.reset();
      }
    }
  }, [_vm._v("重置")])], 1)], 1), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "商品信息",
      width: "350"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticClass: "flex"
        }, [_c("el-image", {
          staticStyle: {
            width: "60px",
            height: "60px"
          },
          attrs: {
            src: scope.row.imgurl ? _vm.$globalObj.imgBaseUrl + scope.row.imgurl : ""
          }
        }, [_c("div", {
          staticClass: "image-slot",
          attrs: {
            slot: "error"
          },
          slot: "error"
        }, [_c("i", {
          staticClass: "el-icon-picture-outline"
        })])]), _c("div", {
          staticClass: "flex-1 text-left",
          staticStyle: {
            "padding-left": "15px"
          }
        }, [_c("p", {
          staticClass: "color-999"
        }, [_vm._v(_vm._s(scope.row.goods_id))]), _c("p", {
          staticClass: "color-666"
        }, [_vm._v(_vm._s(scope.row.title))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "pact_type",
      label: "租赁方式",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "cate_name",
      label: "品类",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "shopname",
      label: "供应商",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.is_choose == 2 ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.add(scope.row.goods_id);
            }
          }
        }, [_vm._v("参与活动")]) : _vm._e()];
      }
    }])
  })], 1), _c("div", {
    staticClass: "text-right",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("el-pagination", {
    attrs: {
      "current-page": _vm.searchForm.page,
      "page-size": _vm.pageSize,
      layout: "total, prev, pager, next",
      total: _vm.total,
      "prev-text": "上一页",
      "next-text": "下一页",
      background: ""
    },
    on: {
      "current-change": _vm.handleCurrentChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.searchForm, "page", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.searchForm, "page", $event);
      }
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关 闭")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;