"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSupplier = addSupplier;
exports.getAreas = getAreas;
exports.getOrderStatus = getOrderStatus;
exports.getParentCategory = getParentCategory;
exports.getRegion = getRegion;
exports.upload = upload;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 上传文件
function upload(params) {
  var sizeLimit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var riginalName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  params.append("sizeLimit", sizeLimit);
  params.append("riginalName", riginalName);
  return _fetch.default.upload("/Supplier/upload", params);
}

/**
 * 公共接口
 */
// 获取一级分类
function getParentCategory() {
  return _fetch.default.get("/Supplier/Currency/getParentCategory");
}

// 获取省市信息
function getAreas() {
  return _fetch.default.get("/Supplier/Currency/getAreas");
}

// 获取省市区信息
function getRegion() {
  return _fetch.default.get("/Supplier/Currency/getRegion");
}

// 添加供应商
function addSupplier(params) {
  return _fetch.default.post("/Supplier/SupplierApply/addSupplier", params);
}

// 获取所有订单状态
function getOrderStatus() {
  return _fetch.default.get("/Supplier/Currency/getOrderStatus");
}