"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCategory = addCategory;
exports.delCategory = delCategory;
exports.getCategoryInfo = getCategoryInfo;
exports.getCategoryList = getCategoryList;
exports.getSonCategoryList = getSonCategoryList;
exports.updateStatus = updateStatus;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 品类管理列表
function getCategoryList(params) {
  return _fetch.default.post("/Supplier/CategoryManagement/categoryUserList", params);
}
// 重新提交
function updateStatus(params) {
  return _fetch.default.post("/Supplier/CategoryManagement/editCategoryUserIscheck", params);
}
// 删除
function delCategory(id) {
  return _fetch.default.post("/Supplier/CategoryManagement/delCategoryUser", {
    id: id
  }, true);
}
// 获取该商户的品类信息
function getCategoryInfo() {
  return _fetch.default.get("/Supplier/CategoryManagement/categoryInfoByMemberid");
}
// 申请品类
function addCategory(params) {
  return _fetch.default.post("/Supplier/CategoryManagement/addCategoryUser", params, true);
}

// 获取所有二级分类
function getSonCategoryList(params) {
  return _fetch.default.post("/Supplier/CategoryManagement/getSonCategoryList", params);
}