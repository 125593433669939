"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.formData,
      "label-width": "140px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "任务ID",
      prop: "task_id"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.dialogTitle == "修改任务",
      placeholder: "任务ID"
    },
    model: {
      value: _vm.formData.task_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "task_id", $$v);
      },
      expression: "formData.task_id"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "任务名称",
      prop: "task_name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "任务名称"
    },
    model: {
      value: _vm.formData.task_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "task_name", $$v);
      },
      expression: "formData.task_name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "是否为每日任务",
      prop: "is_daily"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.is_daily,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "is_daily", $$v);
      },
      expression: "formData.is_daily"
    }
  }, _vm._l(_vm.statusList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _vm.formData.is_daily == 1 ? _c("el-form-item", {
    attrs: {
      label: "广告位ID",
      prop: "adunitId"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "广告位ID"
    },
    model: {
      value: _vm.formData.adunitId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "adunitId", $$v);
      },
      expression: "formData.adunitId"
    }
  })], 1) : _vm._e(), _vm.formData.is_daily == 1 ? _c("el-form-item", {
    attrs: {
      label: "每日观看上限",
      prop: "watch_limit"
    }
  }, [_c("el-input-number", {
    attrs: {
      placeholder: "每日观看上限",
      "controls-position": "right"
    },
    model: {
      value: _vm.formData.watch_limit,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "watch_limit", $$v);
      },
      expression: "formData.watch_limit"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "积分",
      prop: "point"
    }
  }, [_c("el-input-number", {
    attrs: {
      placeholder: "积分",
      "controls-position": "right"
    },
    model: {
      value: _vm.formData.point,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "point", $$v);
      },
      expression: "formData.point"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "备注"
    },
    model: {
      value: _vm.formData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remark", $$v);
      },
      expression: "formData.remark"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;