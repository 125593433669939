"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _add = _interopRequireDefault(require("./dialog/add"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _operate = require("@/api/operate.js");
var _default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      loading: true,
      refundStatusOptions: [{
        value: 0,
        label: '无状态'
      }, {
        value: 1,
        label: '申请中'
      }, {
        value: 2,
        label: '审核同意'
      }, {
        value: 3,
        label: '审核拒绝'
      }],
      depositStatusOptions: [{
        value: 0,
        label: '未支付'
      }, {
        value: 1,
        label: '已支付'
      }, {
        value: 2,
        label: '已退款'
      }],
      searchForm: {
        refund_status: null,
        deposit_status: null,
        mobile: '',
        status: null,
        order_no: '',
        portNum: '',
        //充电口
        orderState: '',
        //订单状态
        sumState: '',
        //结算类型
        userName: '',
        //用户名
        sumNo: '',
        //结算卡号
        closeRea: '',
        //关闭原因
        benifitState: '',
        //分润状态
        use: 2,
        //充电状态
        imei: '',
        //换电柜编号
        state: 1,
        //在线状态
        shareMerchantId: '2024012000002',
        //运营商
        serviceNetId: '',
        //网点
        deviceName: '' //设备名称
      },

      shareMerchantList: [{
        value: '2024012000002',
        label: '0656鹿峰台网络科技(自营)'
      }],
      serviceNetList: [{
        value: '6bd0e261339f32c39bd6ceb5856d8f8d',
        label: '鹿峰台网络科技'
      }],
      orderStateList: [{
        value: 1,
        label: '计费中'
      }, {
        value: 2,
        label: '已完成'
      }, {
        value: 3,
        label: '占用中'
      }, {
        value: 4,
        label: '创建成功'
      }],
      benifitList: [{
        value: 0,
        label: '未分'
      }, {
        value: 1,
        label: '已分'
      }],
      // 结算类型
      sumStateList: [{
        value: 1,
        label: '平台账户'
      }, {
        value: 2,
        label: '商家账户'
      }, {
        value: 3,
        label: '卡账户'
      }, {
        value: 4,
        label: '平台商账户'
      }],
      useList: [{
        value: 2,
        label: '全部'
      }, {
        value: 1,
        label: '充电中'
      }, {
        value: 0,
        label: '未充电'
      }],
      total: 0,
      tableData: [],
      editItem: null,
      roleList: [],
      currentMerchant: ''
    };
  },
  methods: {
    getDepositStatusType: function getDepositStatusType(status) {
      switch (status) {
        case 0:
          return 'danger';
        // Set the tag type for '未支付'
        case 1:
          return 'success';
        // Set the tag type for '已支付'
        case 2:
          return 'warning';
        // Set the tag type for '已退款'
        default:
          return '';
        // Return an empty string for other statuses
      }
    },
    getDepositStatusLabel: function getDepositStatusLabel(status) {
      switch (status) {
        case 0:
          return '未支付';
        // Set the label for '未支付'
        case 1:
          return '已支付';
        // Set the label for '已支付'
        case 2:
          return '已退款';
        // Set the label for '已退款'
        default:
          return '';
        // Return an empty string for other statuses
      }
    },
    getRefundStatusType: function getRefundStatusType(status) {
      switch (status) {
        case 0:
          return 'info';
        // Set the tag type for '无状态'
        case 1:
          return 'warning';
        // Set the tag type for '申请中'
        case 2:
          return 'success';
        // Set the tag type for '审核同意'
        case 3:
          return 'danger';
        // Set the tag type for '审核拒绝'
        default:
          return '';
        // Return an empty string for other statuses
      }
    },
    getRefundStatusLabel: function getRefundStatusLabel(status) {
      switch (status) {
        case 0:
          return '无状态';
        // Set the label for '无状态'
        case 1:
          return '申请中';
        // Set the label for '申请中'
        case 2:
          return '审核同意';
        // Set the label for '审核同意'
        case 3:
          return '审核拒绝';
        // Set the label for '审核拒绝'
        default:
          return '';
        // Return an empty string for other statuses
      }
    },
    exportFile: function exportFile() {
      console.log('xx，导出');
    },
    headerCellStyle: function headerCellStyle() {
      return {
        fontSize: '13px',
        color: '#515a6e',
        fontWeight: 700 // 添加加粗样式
      };
    },
    changeSelect: function changeSelect(val) {
      this.searchForm.refund_status = val;
    },
    // 删除
    removeItem: function removeItem(id) {
      var _this = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _system.delUser)(id).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    // 同意退押金
    openAddDialog: function openAddDialog(val) {
      var _this2 = this;
      this.editItem = val || null;
      this.$confirm('确定要执行退款操作码?', '退款', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        // 退款操作，传入ID即可,type:1同意 2拒绝
        (0, _operate.depositRefund)({
          id: _this2.editItem.id,
          type: 1
        }).then(function (res) {
          console.log('退款状态', res);
          _this2.getList();
        });
      }).catch(function () {});

      // this.$refs.addDialog.dialogVisible = true
    },
    // 批量操作
    handleCommand: function handleCommand(command) {
      var list = this.$refs.multipleTable.selection;
      if (list.length === 0) {
        this.$message({
          message: '没有勾选项',
          type: 'warning'
        });
        return;
      }
      var ids = list.map(function (item) {
        return item.id;
      }).join(',');
      switch (command) {
        case 'delete':
          this.removeItem(ids);
          break;
        case 'enable':
          this.changeStatus(ids, 0);
          break;
        case 'disable':
          this.changeStatus(ids, 1);
          break;
        default:
          break;
      }
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = {
        order_no: '',
        refund_status: '',
        create_time: ''
      };
      this.getList();
    },
    // 启用/禁用
    changeStatus: function changeStatus(ids, status) {
      var _this3 = this;
      var tip = '',
        title = '';
      if (status == 1) {
        tip = '您确定要禁用吗？';
        title = '禁用';
      } else {
        tip = '您确定要启用吗？';
        title = '启用';
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _system.setUser)({
          ids: ids,
          status: status == 1 ? 0 : 1
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    getList: function getList(flag) {
      var _this4 = this;
      console.log('搜索框状态', this.searchForm);
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      var _this$searchForm = this.searchForm,
        refund_status = _this$searchForm.refund_status,
        deposit_status = _this$searchForm.deposit_status,
        create_time = _this$searchForm.create_time;
      if (!flag) {
        this.tableData = [];
      }
      this.loading = true;
      (0, _operate.depositPayList)({
        page: page,
        pageSize: pageSize,
        refund_status: refund_status,
        deposit_status: deposit_status,
        create_time: create_time
      }).then(function (res) {
        _this4.loading = false;
        _this4.tableData = res.data || [];
        _this4.total = res.total;
      });
      // userList({
      //     page,
      //     pageSize,
      //     status: this.searchForm.status === null ? -100 : this.searchForm.status,
      //     mobile: this.searchForm.mobile,
      // }).then((res) => {
      //     this.loading = false
      //     this.tableData = res.data || []
      //     this.total = res.total
      // })
    },
    getRoleList: function getRoleList() {
      var _this5 = this;
      (0, _system.getGroupList)().then(function (res) {
        _this5.roleList = res || [];
      });
    }
  },
  mounted: function mounted() {
    this.getList();
    this.getRoleList();
  }
};
exports.default = _default;