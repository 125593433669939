"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOrEdiShopBanner = addOrEdiShopBanner;
exports.addOrEditLeaseAddress = addOrEditLeaseAddress;
exports.addOrEditReturnAddress = addOrEditReturnAddress;
exports.addShopCategory = addShopCategory;
exports.addShopGoods = addShopGoods;
exports.dailyNewUserCount = dailyNewUserCount;
exports.dailyPurchaseAmount = dailyPurchaseAmount;
exports.dailyRefundUserCount = dailyRefundUserCount;
exports.delLeaseAddress = delLeaseAddress;
exports.delReturnAddress = delReturnAddress;
exports.delShopBanner = delShopBanner;
exports.delShopCategory = delShopCategory;
exports.delShopGoods = delShopGoods;
exports.editShopBaseInfoById = editShopBaseInfoById;
exports.editShopConfig = editShopConfig;
exports.editSort = editSort;
exports.getBatteryAddChartData = getBatteryAddChartData;
exports.getBatteryChartData = getBatteryChartData;
exports.getBatteryExchangeChartData = getBatteryExchangeChartData;
exports.getDeviceInfoChartData = getDeviceInfoChartData;
exports.getGoods = getGoods;
exports.getShopTemplateId = getShopTemplateId;
exports.getShopTemplateList = getShopTemplateList;
exports.getStatisticsByMonth = getStatisticsByMonth;
exports.goodsSortList = goodsSortList;
exports.iotUserStatistics = iotUserStatistics;
exports.leaseAddressById = leaseAddressById;
exports.leaseAddressList = leaseAddressList;
exports.rentalUserCount = rentalUserCount;
exports.returnAddressById = returnAddressById;
exports.returnAddressList = returnAddressList;
exports.saveShopTemplateId = saveShopTemplateId;
exports.shopBannerById = shopBannerById;
exports.shopBannerList = shopBannerList;
exports.shopBaseInfo = shopBaseInfo;
exports.shopCategoryList = shopCategoryList;
exports.shopGoodsList = shopGoodsList;
exports.shopMainInfo = shopMainInfo;
exports.shopQrcode = shopQrcode;
exports.totalUserCount = totalUserCount;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 归还地址列表
function returnAddressList(params) {
  return _fetch.default.post('/Supplier/ReturnAddress/returnAddressList', params);
}
// 添加或编辑归还地址
function addOrEditReturnAddress(params) {
  return _fetch.default.post('/Supplier/ReturnAddress/addOrEditReturnAddress', params, true);
}
// 根据id查询归还地址
function returnAddressById(id) {
  return _fetch.default.get('/Supplier/ReturnAddress/returnAddressById', {
    id: id
  });
}
// 根据id删除归还地址
function delReturnAddress(id) {
  return _fetch.default.post('/Supplier/ReturnAddress/delReturnAddress', {
    id: id
  }, true);
}
// 租赁地址列表
function leaseAddressList(params) {
  return _fetch.default.post('/Supplier/LeaseAddress/leaseAddressList', params);
}
// 添加或编辑租赁地址
function addOrEditLeaseAddress(params) {
  return _fetch.default.post('/Supplier/LeaseAddress/addOrEditLeaseAddress', params, true);
}
// 根据id查询租赁地址
function leaseAddressById(id) {
  return _fetch.default.get('/Supplier/LeaseAddress/leaseAddressById', {
    id: id
  });
}
// 根据id删除租赁地址
function delLeaseAddress(id) {
  return _fetch.default.post('/Supplier/LeaseAddress/delLeaseAddress', {
    id: id
  }, true);
}
// 店铺基础信息
function shopBaseInfo() {
  return _fetch.default.get('/Supplier/ShopInfo/shopBaseInfo');
}
// 根据id编辑店铺基础信息
function editShopBaseInfoById(params) {
  return _fetch.default.post('/Supplier/ShopInfo/editShopBaseInfoById', params, true);
}
// 编辑店铺配置设置
function editShopConfig(params) {
  return _fetch.default.post('/Supplier/ShopInfo/editShopConfig', params, true);
}
// 店铺主体信息
function shopMainInfo() {
  return _fetch.default.get('/Supplier/ShopInfo/shopMainInfo');
}
// 店铺二维码
function shopQrcode() {
  return _fetch.default.get('/Supplier/ShopInfo/shopQrcode');
}
// 获取供应商页面模板列表
function getShopTemplateList() {
  return _fetch.default.get('/Supplier/ShopInfo/getShopTemplateList');
}
// 获取供应商当前页面模板id
function getShopTemplateId() {
  return _fetch.default.get('/Supplier/ShopInfo/getShopTemplateId');
}
// 保存供应商页面模板列表
function saveShopTemplateId(params) {
  return _fetch.default.post('/Supplier/ShopInfo/saveShopTemplateId', params, true);
}
// 商品排序列表
function goodsSortList(params) {
  return _fetch.default.post('/Supplier/HotShop/goodsSortList', params);
}
// 根据id编辑商品、店铺分类、店铺关联商品的排序
function editSort(params) {
  return _fetch.default.post('/Supplier/HotShop/editSort', params, true);
}
// 店铺轮播图设置列表
function shopBannerList(params) {
  return _fetch.default.post('/Supplier/HotShop/shopBannerList', params);
}
// 根据id删除店铺轮播图
function delShopBanner(id) {
  return _fetch.default.post('/Supplier/HotShop/delShopBanner', {
    id: id
  }, true);
}
// 添加或编辑店铺轮播图
function addOrEdiShopBanner(params) {
  return _fetch.default.post('/Supplier/HotShop/addOrEdiShopBanner', params, true);
}
// 根据id查询店铺轮播图
function shopBannerById(id) {
  return _fetch.default.get('/Supplier/HotShop/shopBannerById', {
    id: id
  });
}
// 获取商品信息
function getGoods(params) {
  return _fetch.default.post('/Supplier/HotShop/getGoods', params);
}
// 店铺分类列表
function shopCategoryList(params) {
  return _fetch.default.post('/Supplier/HotShop/shopCategoryList', params);
}
// 根据id删除店铺分类
function delShopCategory(id) {
  return _fetch.default.post('/Supplier/HotShop/delShopCategory', {
    id: id
  }, true);
}
// 添加店铺分类
function addShopCategory(cat_name) {
  return _fetch.default.post('/Supplier/HotShop/addShopCategory', {
    cat_name: cat_name
  }, true);
}
// 店铺关联商品列表
function shopGoodsList(params) {
  return _fetch.default.post('/Supplier/HotShop/shopGoodsList', params);
}
// 根据id删除店铺关联商品
function delShopGoods(id) {
  return _fetch.default.post('/Supplier/HotShop/delShopGoods', {
    id: id
  }, true);
}
// 添加店铺关联商品
function addShopGoods(params) {
  return _fetch.default.post('/Supplier/HotShop/addShopGoods', params, true);
}
/**
 * 新增图表展示接口
 **/
/**
 * 总用户数:
 * 租用中用户数:
 * 今日新增用户数:
 * 今日购买金额:
 * 今日退电用户数:
 **/

// 统计内容
function iotUserStatistics(params) {
  return _fetch.default.post('/Supplier/Statistics/iotUserStatistics', params);
}
// 总用户数
function totalUserCount() {
  return _fetch.default.post('/Supplier/Statistics/totalUserCount');
}

// 租用中用户数
function rentalUserCount() {
  return _fetch.default.post('/Supplier/Statistics/rentalUserCount');
}

// 今日新增用户数
function dailyNewUserCount() {
  return _fetch.default.post('/Supplier/Statistics/dailyNewUserCount');
}

// 今日购买金额
function dailyPurchaseAmount() {
  return _fetch.default.post('/Supplier/Statistics/dailyPurchaseAmount');
}

// 今日退电用户数
function dailyRefundUserCount() {
  return _fetch.default.post('/Supplier/Statistics/dailyRefundUserCount');
}
// 机柜统计
function getDeviceInfoChartData(params) {
  return _fetch.default.post('/Supplier/Statistics/getDeviceInfoChartData', params);
}
// 电池统计
function getBatteryChartData(params) {
  return _fetch.default.post('/Supplier/Statistics/getBatteryChartData', params);
}
// 最近10天电池新增记录
function getBatteryAddChartData(params) {
  return _fetch.default.post('/Supplier/Statistics/getBatteryAddChartData', params);
}
// 获取换电数据
function getBatteryExchangeChartData(params) {
  return _fetch.default.post('/Supplier/Statistics/getBatteryExchangeChartData', params);
}
// 获取月统计数据
function getStatisticsByMonth(params) {
  return _fetch.default.post('/Supplier/Statistics/getStatisticsByMonth', params);
}