"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "500px",
      title: "订单发货",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "warn-box",
    staticStyle: {
      padding: "10px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _c("p", {
    staticStyle: {
      color: "#000000",
      "line-height": "23px"
    }
  }, [_vm._v("发货凭证图片可做法律依据，添加后不可进行删除，请谨慎操作。点击查看"), _c("a", {
    staticClass: "blue-font",
    attrs: {
      href: "https://image-xxz.oss-cn-beijing.aliyuncs.com/download/%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A1%B9.doc"
    }
  }, [_vm._v("注意事项")])])], 1), _c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "订单编号"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.orderId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "orderId", $$v);
      },
      expression: "form.orderId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "发货类型",
      prop: "delivery_type"
    }
  }, [_c("el-radio-group", {
    on: {
      change: _vm.handleTypeChange
    },
    model: {
      value: _vm.form.delivery_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "delivery_type", $$v);
      },
      expression: "form.delivery_type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("物流发货")]), _c("el-radio", {
    attrs: {
      label: 2
    }
  }, [_vm._v("上门自取")]), _c("el-radio", {
    attrs: {
      label: 3
    }
  }, [_vm._v("上门安装")])], 1)], 1), _vm.form.delivery_type == 1 ? _c("el-form-item", {
    attrs: {
      label: "物流公司",
      prop: "logistics_name"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.logistics_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "logistics_name", $$v);
      },
      expression: "form.logistics_name"
    }
  }, _vm._l(_vm.logisticsNames, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e(), _vm.form.delivery_type == 1 ? _c("el-form-item", {
    attrs: {
      label: "物流单号",
      prop: "logistics_no"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    on: {
      input: function input(val) {
        return _vm.changeInput(val);
      }
    },
    model: {
      value: _vm.form.logistics_no,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "logistics_no", $$v);
      },
      expression: "form.logistics_no"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "供应商价格",
      prop: "cost_price"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "价格(元)"
    },
    model: {
      value: _vm.form.cost_price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cost_price", $$v);
      },
      expression: "form.cost_price"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "发货凭证",
      prop: "images"
    }
  }, [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "init-val": _vm.form.images,
      "wrapper-width": "100px",
      "wrapper-height": "100px",
      "photo-width": 100,
      "photo-height": 100
    },
    on: {
      setVal: _vm.imgChange
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;