"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ['editItem'],
  data: function data() {
    return {
      dialog: {
        title: '关闭订单',
        message: '确定要关闭订单吗？'
      },
      dialogVisible: false,
      formData: {
        // image: '',
        memo: ''
      },
      rules: {
        // image: [
        // 	{ required: true, message: '请上传图片', trigger: 'change' }
        // ],
        memo: [{
          required: true,
          message: '请上填写备注',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        // console.log('item', this.editItem)
      } else {
        this.$refs['formData'].resetFields();
        this.formData = this.$options.data().formData;
      }
    }
  },
  methods: {
    setUrl: function setUrl(val) {
      this.formData.image = val;
    },
    submit: function submit() {
      var _this = this;
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          _this.$confirm(_this.dialog.message, _this.dialog.title, {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(function () {
            (0, _order.orderCloseMerchant)((0, _objectSpread2.default)({
              order_id: _this.editItem.id,
              apply_type: 0
            }, _this.formData)).then(function (res) {
              _this.$emit('getList');
              _this.close();
            });
          }).catch(function (err) {});
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;