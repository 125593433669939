"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "before-close": _vm.close,
      "close-on-click-modal": false,
      width: "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "应用名：",
      "label-width": "120px",
      prop: "app_name"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.app_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "app_name", $$v);
      },
      expression: "formData.app_name"
    }
  }, _vm._l(_vm.appList, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "渠道key：",
      "label-width": "120px",
      prop: "key"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.key,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "key", $$v);
      },
      expression: "formData.key"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "渠道名称：",
      "label-width": "120px",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "是否禁用：",
      "label-width": "120px",
      prop: "status"
    },
    on: {
      change: _vm.changeRadio
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    },
    model: {
      value: _vm.formData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, [_vm._v("正常")]), _c("el-radio", {
    attrs: {
      label: 2
    },
    model: {
      value: _vm.formData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, [_vm._v("禁用")])], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("确 定")]), _c("el-button", {
    on: {
      click: _vm.close
    }
  }, [_vm._v("取 消")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;