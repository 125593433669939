"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "合同列表",
      visible: _vm.dialogVisible,
      width: "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "info-box"
  }, [_c("div", {
    staticClass: "order-info"
  }, _vm._l(_vm.filesList, function (item, index) {
    return _c("el-button", {
      key: index,
      attrs: {
        type: "primary",
        size: "mini",
        plain: ""
      },
      on: {
        click: function click($event) {
          return _vm.openFile(item);
        }
      }
    }, [_vm._v(_vm._s(item.fileName))]);
  }), 1)]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;