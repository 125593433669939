"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "banner_form"
  }, [_c("div", {
    staticClass: "flex flex-between-center header"
  }, [_vm.type === "bannerCol" ? _c("span", {
    staticClass: "color-666"
  }, [_vm._v("轮播图" + _vm._s(_vm.itemIndex + 1))]) : _vm.type === "categoryForm" ? _c("span", {
    staticClass: "color-666"
  }, [_vm._v("\n            一级推荐商品\n        ")]) : _c("span", {
    staticClass: "color-666"
  }, [_vm._v("图片" + _vm._s(_vm.itemIndex + 1))]), _vm.showTool ? _c("p", {
    staticClass: "tools"
  }, [_c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("upSortItem");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-top"
  })]), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("downSortItem");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-bottom"
  })]), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("deleteItem");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-delete"
  })])]) : _vm._e()]), _c("crop-upload", {
    staticClass: "w-100 banner_img",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.img,
      "img-width": _vm.imgLimit.width,
      "photo-width": 359,
      "photo-height": 88
    },
    on: {
      setVal: function setVal(val) {
        _vm.formData.img = val;
      }
    }
  }), _vm.formData.jumpType && _vm.formData.jumpUrl ? _c("div", {
    staticClass: "flex align-center link_val"
  }, [_c("span", {
    staticClass: "type"
  }, [_vm._v(_vm._s(_vm.formData.jumpType == 1 ? "文本链接" : "商品链接"))]), _vm.type === "bannerCol" || _vm.linkVal.length > 20 ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm.linkVal,
      placement: "top-start"
    }
  }, [_c("p", {
    staticClass: "flex-1 text-ellipsis"
  }, [_vm._v(_vm._s(_vm.linkVal))])]) : _c("p", {
    staticClass: "flex-1 text-ellipsis"
  }, [_vm._v(_vm._s(_vm.linkVal))]), _c("span", {
    staticClass: "color-999 pointer",
    on: {
      click: _vm.deleteLink
    }
  }, [_c("i", {
    staticClass: "el-icon-error"
  })])], 1) : _c("div", {
    staticClass: "add-div",
    on: {
      click: _vm.addLink
    }
  }, [_c("i", {
    staticClass: "el-icon-link"
  }), _vm._v("添加链接 ")]), _c("link-dialog", {
    ref: "linkDialog",
    on: {
      setVal: _vm.setVal
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;