"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog"));
var _validate = require("@/utils/validate");
var _default = {
  components: {
    goodsDialog: _goodsDialog.default
  },
  props: ['editItem'],
  data: function data() {
    var _this = this;
    var validateUrl = function validateUrl(rule, value, callback) {
      // if (this.form.jumpType == 1 && !checkUrl(value)) {
      if (_this.form.jumpType == 1 && !value) {
        callback(new Error('文本链接不能为空'));
      } else {
        callback();
      }
    };
    return {
      form: {
        jumpType: 1,
        jumpUrl: "",
        goods: {}
      },
      rules: {
        jumpType: [{
          required: true,
          message: '请选择链接类型',
          trigger: 'change'
        }],
        jumpUrl: [{
          required: true,
          message: '链接不能为空',
          trigger: 'change'
        }, {
          validator: validateUrl,
          trigger: 'change'
        }]
      },
      dialogVisible: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;
      if (val) {
        this.$nextTick(function () {
          _this2.$refs['ruleForm'].resetFields();
          if (_this2.editItem) {
            _this2.form = (0, _objectSpread2.default)({}, _this2.editItem);
          }
        });
      }
    }
  },
  methods: {
    resetUrl: function resetUrl() {
      this.form.jumpUrl = '';
      this.form.goods = {};
    },
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
    },
    // 设置选中的商品
    setSelect: function setSelect(goodsList) {
      this.form.goods = goodsList[0];
      this.form.jumpUrl = goodsList[0].goods_id;
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this3.$emit('setVal', _this3.form);
          _this3.dialogVisible = false;
        } else {
          _this3.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};
exports.default = _default;