"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _wangEditor = _interopRequireDefault(require("@/components/wangEditor"));
var _goods = require("@/api/goods");
var _default = {
  components: {
    cropUpload: _cropUpload.default,
    textEditor: _wangEditor.default
  },
  data: function data() {
    return {
      submitForm: {
        rent_content: "",
        supervise_content: ""
      }
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.getData();
    },
    getData: function getData() {
      var _this = this;
      (0, _goods.getGoodsExplain)().then(function (res) {
        if (res) {
          _this.submitForm = (0, _objectSpread2.default)({}, res);
        }
      });
    },
    save: function save() {
      (0, _goods.saveGoodsExplain)((0, _objectSpread2.default)({}, this.submitForm)).then(function (res) {});
    },
    setUrl: function setUrl(val) {
      this.submitForm.imgurl = val;
    }
  }
};
exports.default = _default;