"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "800px",
      title: "修改代理信息",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "form-container"
  }, [_c("el-form", {
    ref: "formRef",
    staticClass: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.submitForm,
      "label-width": "140px"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "proxyed_name",
      label: "代理人姓名"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: true
    },
    model: {
      value: _vm.submitForm.proxyed_name,
      callback: function callback($$v) {
        _vm.$set(_vm.submitForm, "proxyed_name", $$v);
      },
      expression: "submitForm.proxyed_name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "cycle",
      label: "分佣周期（月）"
    }
  }, [_c("el-input-number", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      min: 1,
      placeholder: "请输入分佣周期（月份数）"
    },
    model: {
      value: _vm.submitForm.cycle,
      callback: function callback($$v) {
        _vm.$set(_vm.submitForm, "cycle", _vm._n($$v));
      },
      expression: "submitForm.cycle"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "amount",
      label: "分佣金额"
    }
  }, [_c("el-input-number", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      min: 1,
      placeholder: "请输入分佣金额"
    },
    model: {
      value: _vm.submitForm.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.submitForm, "amount", $$v);
      },
      expression: "submitForm.amount"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.closeDialog("formRef");
      }
    }
  }, [_vm._v("关闭")]), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: function click($event) {
        return _vm.handleSubmit("formRef");
      }
    }
  }, [_vm._v("保存")])], 1), _vm.isShow ? _c("el-image-viewer", {
    attrs: {
      "on-close": _vm.close,
      "url-list": _vm.image,
      "z-index": 9000
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;