"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: "查看风控详情",
      visible: _vm.dialogVisible,
      size: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%",
      height: "400px"
    }
  }), _c("el-row", [_c("el-button", {
    attrs: {
      type: "danger",
      round: ""
    },
    on: {
      click: function click($event) {
        return _vm.clearData();
      }
    }
  }, [_vm._v("清除数据")])], 1), !_vm.loading && _vm.tableData.url ? _c("div", {
    staticStyle: {
      position: "relative",
      height: "100%"
    }
  }, [_c("el-image", {
    attrs: {
      src: _vm.tableData.url,
      height: "100%"
    }
  })], 1) : _vm._e(), !_vm.loading && !_vm.tableData.url ? _c("div", {
    staticStyle: {
      padding: "10px"
    }
  }, [_c("div", {
    staticClass: "flex idcard_wrap"
  }, [_c("div", {
    staticClass: "flex-1"
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_vm._v("基本信息")]), _c("div", {
    staticClass: "ant-descriptions ant-descriptions-bordered"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("姓名")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.base_info.name) + "\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("身份证号")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.base_info.ident_number) + "\n\t\t\t\t\t\t\t\t\t")])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("手机号")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.base_info.phone) + "\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("年龄")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.base_info.age) + "\n\t\t\t\t\t\t\t\t\t")])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("户籍")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.base_info.ident_number_address) + "\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("号码归属地")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.base_info.phone_address) + "\n\t\t\t\t\t\t\t\t\t")])])])])])])])]), _vm.merchantId == 0 || 1 ? [_c("div", {
    staticClass: "flex-2"
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_vm._v("风险建议")]), _c("div", {
    staticClass: "ant-descriptions ant-descriptions-bordered"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("综合评分\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("审核建议\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "3"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t分值标注说明\n\t\t\t\t\t\t\t\t\t")])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    class: {
      success: _vm.tableData.score_norm_explain > 530,
      warning: _vm.tableData.score_norm_explain <= 530 && _vm.tableData.score_norm_explain > 450,
      danger: _vm.tableData.score_norm_explain <= 450
    },
    staticStyle: {
      "font-size": "30px",
      "font-weight": "bold"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.score_norm_explain))])]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    class: {
      success: _vm.tableData.score_norm_explain > 530,
      warning: _vm.tableData.score_norm_explain <= 530 && _vm.tableData.score_norm_explain > 450,
      danger: _vm.tableData.score_norm_explain <= 450
    },
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v(_vm._s(_vm.tableData.verify_recomment))])]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "3"
    }
  }, [_c("div", [_c("span", [_vm._v("分值在[0,650]之间，得分越低，风险越高:")]), _c("span", [_vm._v("(530-650]，建议通过；")]), _c("span", [_vm._v("(450-530]，建议审核；")]), _c("span", [_vm._v("[0-450]，建议拒绝；")])])])])])])])])]), _c("div", {
    staticClass: "flex-2"
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_vm._v("风险标注")]), _c("div", {
    staticClass: "ant-descriptions ant-descriptions-bordered"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("风险点\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("详解\n\t\t\t\t\t\t\t\t\t")])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, _vm._l(_vm.tableData.hit_risk_tagging, function (parentIndex, parentItem) {
    return _c("span", {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t" + _vm._s(parentIndex) + " "), parentItem < _vm.tableData.hit_risk_tagging.length - 1 ? _c("span", [_vm._v(" , ")]) : _vm._e()]);
  }), 0), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "16px"
    }
  })])])])])])])]), _c("div", {
    staticClass: "flex-2"
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_vm._v("身份信息核验")]), _c("div", {
    staticClass: "ant-descriptions ant-descriptions-bordered"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("实名核验\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t运营商核验\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t运营商在网时长\n\t\t\t\t\t\t\t\t\t")])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.ident_info_dict.identity_two_elements == "01" ? "不一致" : _vm.tableData.ident_info_dict.identity_two_elements == "02" ? "一致" : "不一致") + "\n\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.ident_info_dict.network_triple_elements == "01" ? "不一致" : _vm.tableData.ident_info_dict.network_triple_elements == "02" ? "一致" : "不一致") + "\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.ident_info_dict.time_online) + "\n\t\t\t\t\t\t\t\t\t")])])])])])]), _c("span", [_vm._v("说明：运营商在⽹时⻓ 1:[0,3） 2:[3,6） 3:[6,12） 4:[12,24） 5:[24,+) 0：查⽆此号或已注销 -1：不⽀持该运营商 999: ⼿机状态异常（注:1、销号6⽉\n\t\t\t\t\t以上；2、携号转⽹；3、未放出去的 号码）；⾮本⽹⼿机号码")]), _c("p")]), _c("div", {
    staticClass: "flex-2",
    staticStyle: {
      "border-inline": "inherit"
    }
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_vm._v("命中⻛险标注")]), _c("div", {
    staticClass: "ant-descriptions"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("特殊关注名单")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v(_vm._s(_vm.tableData.risk_list_check.result_xd == 1 ? "命中" : "未命中"))])]), _c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("归属地位于高风险集中地区")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v(_vm._s(_vm.tableData.risk_list_check.census_register_high_risk_area == 1 ? "命中" : "未命中") + "\n\t\t\t\t\t\t\t\t\t\t")])])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("信用租赁风险名单")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v(_vm._s(_vm.tableData.risk_list_check.credit_rent == 1 ? "命中" : "未命中"))])]), _c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("犯罪通缉名单")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v(_vm._s(_vm.tableData.risk_list_check.idcard_hit_fztj == 1 ? "命中" : "未命中"))])])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("车辆租赁违约名单")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v(_vm._s(_vm.tableData.risk_list_check.idcard_hit_clzlwy == 1 ? "命中" : "未命中"))])]), _c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t故意违章乘车名单")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v(_vm._s(_vm.tableData.risk_list_check.idcard_hit_gywzcc == 1 ? "命中" : "未命中"))])])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("虚假号码库")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.risk_list_check.phone_hit_xjhm == 1 ? "命中" : "未命中"))])]), _c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("通信⼩号库")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.risk_list_check.phone_hit_txxh == 1 ? "命中" : "未命中"))])])])])])])])]), _c("div", {
    staticStyle: {
      width: "0px",
      height: "0px",
      overflow: "hidden",
      position: "absolute"
    },
    attrs: {
      tabindex: "0",
      role: "presentation"
    }
  }), _c("div", {
    staticClass: "flex-2",
    staticStyle: {
      "border-inline": "inherit"
    }
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_c("span", [_vm._v("机构查询次数")])]), _c("div", {
    staticClass: "ant-descriptions"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("tbody", {
    staticStyle: {
      border: "1px solid rgb(238, 238, 238)",
      width: "99%",
      height: "350px",
      "text-align": "center",
      "margin-top": "220px"
    }
  }, [_c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("近7天\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("近30天\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("近90天\n\t\t\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t机构查询总数\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.d7_apply_setup_time) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m1_apply_setup_time) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m3_apply_setup_time) + "\n\t\t\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("信用卡\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.d7_apply_setup_time_xyk) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m1_apply_setup_time_xyk) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m3_apply_setup_time_xyk) + "\n\t\t\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("P2P平台\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.d7_apply_setup_time_p2pwd) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m1_apply_setup_time_p2pwd) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m3_apply_setup_time_p2pwd) + "\n\t\t\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t一般消费分期平台\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.d7_apply_setup_time_ybxffq) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m1_apply_setup_time_ybxffq) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m3_apply_setup_time_ybxffq) + "\n\t\t\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t大型消费金融公司\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.d7_apply_setup_time_dxxfjr) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m1_apply_setup_time_dxxfjr) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m3_apply_setup_time_dxxfjr) + "\n\t\t\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t小额贷款公司\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.d7_apply_setup_time_xedkgs) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m1_apply_setup_time_xedkgs) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m3_apply_setup_time_xedkgs) + "\n\t\t\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t其他类型公司\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.d7_apply_setup_time_other) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m1_apply_setup_time_other) + "\n\t\t\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_demand.m3_apply_setup_time_other) + "\n\t\t\t\t\t\t\t\t")])])])])])])] : _vm._e(), _c("div", {
    staticClass: "flex-2",
    staticStyle: {
      "border-inline": "inherit"
    }
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_c("span", [_vm._v("历史借贷行为")])])]), _vm.merchantId == 0 || 1 ? [_c("div", {
    staticStyle: {
      display: "flex",
      margin: "20px 0px"
    }
  }, [_c("div", {
    staticStyle: {
      height: "22px",
      width: "6px",
      "background-color": "rgb(68, 154, 231)",
      "margin-right": "10px"
    }
  }), _c("div", [_vm._v(" 近12个月申请情况")])]), _c("div", [_c("div", {
    staticClass: "ant-descriptions ant-descriptions-bordered"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t申请机构总数\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t最近一次申请时间\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t距离最近一次申请已有（天）")])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_s.apply_mechanism_number) + "\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_s.last_apply_time) + "\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_s.last_apply_time_distance) + "\n\t\t\t\t\t\t\t\t\t")])])])])])])])] : _vm._e(), _c("div", {
    staticStyle: {
      display: "flex",
      margin: "20px 0px"
    }
  }, [_c("div", {
    staticStyle: {
      height: "22px",
      width: "6px",
      "background-color": "rgb(68, 154, 231)",
      "margin-right": "10px"
    }
  }), _c("div", [_vm._v(" 放款情况")])]), _c("div", [_c("div", {
    staticClass: "antd-pro-pages-order-home-page-public-card-index-fiveWrap",
    staticStyle: {
      "margin-top": "22px"
    }
  }, [_c("div", {
    staticClass: "ant-descriptions ant-descriptions-bordered"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t近24个月放款机构数")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t分期类放款机构数 (个)")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t网络贷款类放款机构数 (个)")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t最近一次放款日期")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t距离最近一次放款日期已有 (天)")])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lenders) + "\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.cflenders) + "\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.nllenders) + "\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_time) + "\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_time_distance) + "\n\t\t\t\t\t\t\t\t\t")])])])])])])]), _c("div", {
    staticClass: "antd-pro-pages-order-home-page-public-card-index-threeWrap",
    staticStyle: {
      "margin-top": "22px"
    }
  }, [_c("tbody", {
    staticStyle: {
      border: "1px solid rgb(238, 238, 238)",
      width: "99%",
      height: "110px",
      "text-align": "center",
      "margin-top": "220px"
    }
  }, [_c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近1个月")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近3个月")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近6个月")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近12个月")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近24个月")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t放款次数")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_number1) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_number3) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_number6) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_number12) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_number24) + "\n\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t放款金额")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_money1) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_money3) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_money6) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_money12) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_f.lend_money24) + "\n\t\t\t\t\t\t")])])])])]), _c("div", {
    staticStyle: {
      display: "flex",
      margin: "20px 0px"
    }
  }, [_c("div", {
    staticStyle: {
      height: "22px",
      width: "6px",
      "background-color": "rgb(68, 154, 231)",
      "margin-right": "10px"
    }
  }), _c("div", [_vm._v(" 履约情况")])]), _c("div", [_c("div", {
    staticClass: "antd-pro-pages-order-home-page-public-card-index-threeWrap",
    staticStyle: {
      "margin-top": "22px"
    }
  }, [_c("tbody", {
    staticStyle: {
      border: "1px solid rgb(238, 238, 238)",
      width: "99%",
      height: "130px",
      "text-align": "center",
      "margin-top": "220px"
    }
  }, [_c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近1个月")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近3个月")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近6个月")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近12个月")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t近24个月")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t履约次数 ")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_succ1) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_succ3) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_succ6) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_succ12) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_succ24) + "\n\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t履约金额 ")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_money1) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_money3) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_money6) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_money12) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_money24) + "\n\t\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t还款异常次数")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_fail1) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_fail3) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_fail6) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_fail12) + "\n\t\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_loan_h.repay_fail24) + "\n\t\t\t\t\t\t")])])])])]), _c("div", {
    staticClass: "flex-2"
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_vm._v("历史逾期记录")]), _c("div", {
    staticClass: "ant-descriptions ant-descriptions-bordered"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("逾期机构总数\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t逾期总次数\n\t\t\t\t\t\t\t\t\t")]), _c("th", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t逾期总金额 (元)\n\t\t\t\t\t\t\t\t\t")])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_overdue_history.overdue_mechanism_number) + "\n\t\t\t\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "1"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.tableData.personal_overdue_history.counts) + "\n\t\t\t\t\t\t\t\t\t")]), _c("td", {
    staticClass: "ant-descriptions-item-content",
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\t\t\t" + _vm._s(_vm.getoverdue_money(_vm.tableData.personal_overdue_history.overdue_money)) + "\n\t\t\t\t\t\t\t\t\t")])])])])])])]), _c("div", {
    staticClass: "flex-2",
    staticStyle: {
      "border-inline": "inherit"
    }
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_c("span", [_vm._v("租赁行为")])])]), _c("div", {
    staticClass: "antd-pro-pages-order-home-page-public-card-index-threeWrap",
    staticStyle: {
      "margin-top": "22px"
    }
  }, [_c("tbody", {
    staticStyle: {
      border: "1px solid rgb(238, 238, 238)",
      width: "99%",
      height: "110px",
      "text-align": "center",
      "margin-top": "220px"
    }
  }, [_c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t近7天\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t近1个月\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t近3个月\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t近6个月\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t近12个月\n\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t租赁申请机构数(次)")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.d7_apply_agency_time) + "\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.m1_apply_agency_time) + "\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.m3_apply_agency_time) + "\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.m6_apply_agency_time) + "\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.m12_apply_agency_time) + "\n\t\t\t\t\t")])]), _c("tr", [_c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)",
      "font-weight": "bold"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t租赁申请次数(次)")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.d7_apply_time) + "\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.m1_apply_time) + "\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.m3_apply_time) + "\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.m6_apply_time) + "\n\t\t\t\t\t")]), _c("td", {
    staticStyle: {
      width: "400px",
      border: "1px solid rgb(238, 238, 238)"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.tableData.rent_history.m12_apply_time) + "\n\t\t\t\t\t")])])])]), _c("div", {
    staticClass: "flex-2",
    staticStyle: {
      "border-inline": "inherit"
    }
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_c("span", [_vm._v("关联风险检测")])])]), _c("div", {
    staticClass: "ant-descriptions"
  }, [_c("div", {
    staticClass: "ant-descriptions-view"
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("3个月身份证关联手机号次数")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v(_vm._s(_vm.tableData.relevance_risk_check.m3_idcard_to_phone_time))])])]), _c("tr", {
    staticClass: "ant-descriptions-row"
  }, [_c("td", {
    staticClass: "ant-descriptions-item",
    attrs: {
      colspan: "1"
    }
  }, [_c("span", {
    staticClass: "ant-descriptions-item-label ant-descriptions-item-colon"
  }, [_vm._v("3个月手机号关联身份证次数")]), _c("span", {
    staticClass: "ant-descriptions-item-content"
  }, [_vm._v(_vm._s(_vm.tableData.relevance_risk_check.m3_phone_to_idcard_time))])])])])])])]), _c("div", {
    staticClass: "flex-2",
    staticStyle: {
      "border-inline": "inherit"
    }
  }, [_c("p", {
    staticClass: "text-center img_header"
  }, [_c("span", [_vm._v("法院风险信息")])])]), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData.court_risk_info_list,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n\t\t\t\t\t" + _vm._s(scope.$index + 1) + "\n\t\t\t\t")];
      }
    }], null, false, 1966914161)
  }), _c("el-table-column", {
    attrs: {
      prop: "sort_time_string",
      label: "审结日期",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "类型"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n\t\t\t\t\t" + _vm._s(scope.row.data_type == "cpws" ? "裁判⽂书" : scope.row.data_type == "ktgg" ? "开庭公告" : scope.row.data_type == "ajlc" ? "案件流程信息" : scope.row.data_type == "fygg" ? "法院公告" : scope.row.data_type == "shixin" ? "失信公告" : scope.row.data_type == "zxgg" ? "执⾏公告" : scope.row.data_type == "bgt" ? "曝光台" : scope.row.data_type) + "\n\t\t\t\t")];
      }
    }], null, false, 3851323210)
  }), _c("el-table-column", {
    attrs: {
      prop: "summary",
      label: "摘要说明"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "compatibility",
      label: "匹配度"
    }
  })], 1), _c("div", {
    staticClass: "antd-pro-pages-order-home-page-historyoverdue-index-text"
  }, [_vm._v("\n\t\t\t说明：匹配度是⼀个概率（范围为0~1），匹配度为0，则完全不匹配；匹配度为1，则完全匹配。通常，匹配度达到0.8及以上，相似度已经很⾼，基本上可以确定是这个⼈；匹配度在0.5及以下，说明可以关联到部分信息，但是相似度较低，⼤概率不是这个⼈；匹配度在0.5-0.8时，需根据⽤户的⻛控策略，做取舍。\n\t\t")])], 2) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;