"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "订单信息",
      visible: _vm.dialogVisible,
      width: "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "info-box"
  }, [_c("div", {
    staticClass: "order-info"
  }, [_vm._v("\n            " + _vm._s(_vm.editItem.cert_name) + " " + _vm._s(_vm.editItem.address_mobile) + " "), _c("br"), _vm._v("\n            " + _vm._s(_vm.editItem.prov) + _vm._s(_vm.editItem.city) + _vm._s(_vm.editItem.area) + _vm._s(_vm.editItem.address) + " "), _c("br"), _vm._v("\n            托寄物内容：" + _vm._s(_vm.editItem.goods_title)), _c("br"), _vm._v("\n            身份证：" + _vm._s(_vm.editItem.cert_no)), _c("br"), _vm._v("\n            订单编号：" + _vm._s(_vm.editItem.trade_no)), _c("br"), _vm._v("\n            租赁机型：" + _vm._s(_vm.editItem.goods_title)), _c("br"), _vm._v("\n            IMEI/SN："), _c("br"), _vm._v("\n            租赁时长：" + _vm._s(_vm.editItem.begin_time) + " 至 " + _vm._s(_vm.editItem.end_time)), _c("br"), _vm._v("\n            每月租金（元）：" + _vm._s(_vm.leaseList && _vm.leaseList[1] ? _vm.leaseList[1].rent : "")), _c("br"), _vm._v("\n            《租赁服务协议》签约时间：" + _vm._s(_vm.contractInfo.update_time)), _c("br"), _vm._v("\n            套餐方案：" + _vm._s(_vm.editItem.setmeal_title)), _c("br"), _vm._v("\n            出租方：" + _vm._s(_vm.editItem.merchantInfo ? _vm.editItem.merchantInfo.company : "")), _c("br")])]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;