"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-card", [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("h5", [_vm._v("换电-今日换电")])]), _c("div", {
    staticClass: "sec-line"
  }, [_c(_setup.VChart, {
    staticClass: "chart2",
    staticStyle: {
      height: "300px",
      width: "100%"
    },
    attrs: {
      option: _setup.chart2Option
    },
    on: {
      change: _setup.reset,
      reset: _setup.reset
    }
  }), _c("div", {
    staticClass: "text"
  }, [_vm._v("换电总量 " + _vm._s(_setup.totalNum) + "次")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;