"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.dialogTitle,
      visible: _vm.childVisible,
      "before-close": _vm.closeDialog,
      "close-on-click-modal": false,
      width: "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.childVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "childForm",
    attrs: {
      model: _vm.childForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "一级品类：",
      "label-width": "140px",
      prop: "parentid"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      disabled: ""
    },
    model: {
      value: _vm.childForm.parentid,
      callback: function callback($$v) {
        _vm.$set(_vm.childForm, "parentid", $$v);
      },
      expression: "childForm.parentid"
    }
  }, _vm._l(_vm.firstLevel, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "品类名称：",
      "label-width": "140px",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.childForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.childForm, "name", $$v);
      },
      expression: "childForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "上传封面：",
      "label-width": "140px",
      prop: "image"
    }
  }, [_c("crop-upload", {
    attrs: {
      "show-type": "photo",
      "init-val": _vm.childForm.image,
      "file-size": "100K",
      "wrapper-width": "95px",
      "wrapper-height": "95px"
    },
    on: {
      setVal: function setVal(val) {
        return _vm.setUrl("image", val);
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "分类banner：",
      "label-width": "140px",
      prop: "banner_image"
    }
  }, [_c("crop-upload", {
    attrs: {
      "show-type": "photo",
      "init-val": _vm.childForm.banner_image,
      "file-size": "300K",
      "wrapper-width": "300px",
      "wrapper-height": "70px"
    },
    on: {
      setVal: function setVal(val) {
        return _vm.setUrl("banner_image", val);
      }
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.childForm.banner_image,
      expression: "!childForm.banner_image"
    }],
    staticClass: "banner-tips"
  }, [_vm._v("banner尺寸: 700 x 150")])], 1), _c("el-form-item", {
    attrs: {
      label: "banner链接：",
      "label-width": "140px",
      prop: "banner_link"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.childForm.banner_link,
      callback: function callback($$v) {
        _vm.$set(_vm.childForm, "banner_link", $$v);
      },
      expression: "childForm.banner_link"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "状态:",
      "label-width": "130px",
      prop: "enabled"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    },
    model: {
      value: _vm.childForm.enabled,
      callback: function callback($$v) {
        _vm.$set(_vm.childForm, "enabled", $$v);
      },
      expression: "childForm.enabled"
    }
  }, [_vm._v("显示")]), _c("el-radio", {
    attrs: {
      label: 0
    },
    model: {
      value: _vm.childForm.enabled,
      callback: function callback($$v) {
        _vm.$set(_vm.childForm, "enabled", $$v);
      },
      expression: "childForm.enabled"
    }
  }, [_vm._v("隐藏")])], 1), _c("el-form-item", {
    attrs: {
      label: "排序：",
      "label-width": "140px",
      prop: "displayorder"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.childForm.displayorder,
      callback: function callback($$v) {
        _vm.$set(_vm.childForm, "displayorder", $$v);
      },
      expression: "childForm.displayorder"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "租赁类目：",
      "label-width": "140px",
      prop: "rent_category"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.childForm.rent_category,
      callback: function callback($$v) {
        _vm.$set(_vm.childForm, "rent_category", $$v);
      },
      expression: "childForm.rent_category"
    }
  }, _vm._l(_vm.rent_list, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "租赁宝类目：",
      "label-width": "140px",
      prop: "zlb_class"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.childForm.zlb_class,
      callback: function callback($$v) {
        _vm.$set(_vm.childForm, "zlb_class", $$v);
      },
      expression: "childForm.zlb_class"
    }
  }, _vm._l(_vm.zlb_list, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "保险类目：",
      "label-width": "140px",
      prop: "insurance_category"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.childForm.insurance_category,
      callback: function callback($$v) {
        _vm.$set(_vm.childForm, "insurance_category", $$v);
      },
      expression: "childForm.insurance_category"
    }
  }, _vm._l(_vm.insure_list, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("childForm");
      }
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;