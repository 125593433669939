import { render, staticRenderFns } from "./num.vue?vue&type=template&id=208fa61b&scoped=true&"
import script from "./num.vue?vue&type=script&lang=js&"
export * from "./num.vue?vue&type=script&lang=js&"
import style0 from "./num.vue?vue&type=style&index=0&id=208fa61b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "208fa61b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\iot_vehicle\\iot_frontend\\iot_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('208fa61b')) {
      api.createRecord('208fa61b', component.options)
    } else {
      api.reload('208fa61b', component.options)
    }
    module.hot.accept("./num.vue?vue&type=template&id=208fa61b&scoped=true&", function () {
      api.rerender('208fa61b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/num.vue"
export default component.exports