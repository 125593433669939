"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "text-center success_page"
  }, [_vm._m(0), _c("h2", {
    staticClass: "green-font"
  }, [_vm._v(_vm._s(_vm.isEdit ? "编辑成功" : "发布成功"))]), _c("p", [_vm._v("商品处于下架状态,请手动上架")]), _c("p", {
    staticClass: "color-999"
  }, [_vm._v("商品编号：" + _vm._s(_vm.goodsId))]), _c("p", {
    staticClass: "btns"
  }, [!_vm.isEdit ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "goodsAdd"
        });
      }
    }
  }, [_vm._v("继续发布")]) : _vm._e(), _c("el-button", {
    staticClass: "blue_border",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "goodsIndex"
        });
      }
    }
  }, [_vm._v("查看商品列表")])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "green-font"
  }, [_c("i", {
    staticClass: "el-icon-circle-check",
    staticStyle: {
      "font-size": "88px"
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;