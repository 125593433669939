"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.func = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
var _elementUi = require("element-ui");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var func = {
  /**
   * 节流原理：在一定时间内，只能触发一次
   *
   * @param {Function} func 要执行的回调函数
   * @param {Number} wait 延时的时间
   * @param {Boolean} immediate 是否立即执行
   * @return null
   */
  throttleTimer: 0,
  throttleFlag: false,
  throttle: function throttle(func) {
    var _this = this;
    var wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
    var immediate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    if (immediate) {
      if (!this.throttleFlag) {
        this.throttleFlag = true;
        // 如果是立即执行，则在wait毫秒内开始时执行
        typeof func === 'function' && func();
        this.throttleTimer = setTimeout(function () {
          _this.throttleFlag = false;
        }, wait);
      }
    } else {
      if (!this.throttleFlag) {
        this.throttleFlag = true;
        // 如果是非立即执行，则在wait毫秒内的结束处执行
        this.throttleTimer = setTimeout(function () {
          _this.throttleFlag = false;
          typeof func === 'function' && func();
        }, wait);
      }
    }
  },
  /**
   * 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
   *
   * @param {Function} func 要执行的回调函数
   * @param {Number} wait 延时的时间(毫秒)
   * @param {Boolean} immediate 是否立即执行
   * @return null
   */
  debounceTimeout: null,
  debounce: function debounce(func) {
    var wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
    var immediate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    // 清除定时器
    if (this.debounceTimeout !== null) clearTimeout(this.debounceTimeout);
    // 立即执行，此类情况一般用不到
    if (immediate) {
      var callNow = !this.debounceTimeout;
      this.debounceTimeout = setTimeout(function () {
        this.debounceTimeout = null;
      }, wait);
      if (callNow) typeof func === 'function' && func();
    } else {
      // 设置定时器，当最后一次操作后，this.debounceTimeout不会再被清除，所以在延时wait毫秒后执行func回调方法
      this.debounceTimeout = setTimeout(function () {
        typeof func === 'function' && func();
      }, wait);
    }
  },
  chkLoginIdentity: function chkLoginIdentity() {
    var flag = 0;
    var host = window.location.host;
    if (host.indexOf('supplier') != -1) {
      // 供应商
      flag = 1;
    } else if (host.indexOf('shop') != -1) {
      // 商户
      flag = 2;
    }
    return flag;
  },
  // 修改页面标题
  setTitle: function setTitle(title) {
    document.title = title;
  },
  // 根据host修改页面标题
  setTitleByHost: function setTitleByHost() {
    var title = '后台管理系统';
    var identity = this.chkLoginIdentity();
    if (identity == 1) {
      title = '供应商后台管理系统';
    } else if (identity == 2) {
      title = '商户后台管理系统';
    }
    this.setTitle("\u6362\u7535\u67DC-".concat(title));
  },
  openWindow: function openWindow(url) {
    window.open(url, '_blank');
  },
  // 深度克隆
  deepClone: function deepClone(source) {
    return JSON.parse(JSON.stringify(source));
  },
  // 整数过滤
  filterNum: function filterNum(val) {
    return val.replace(/[^[0-9-]*/g, '');
  },
  // 浮点数过滤
  filterFloat: function filterFloat(val) {
    return val.replace(/[^[0-9.-]*/g, '');
  }
};
exports.func = func;
func.setCookie = function (key, val) {
  return _jsCookie.default.set(key, val);
};
func.getCookie = function (key) {
  return _jsCookie.default.get(key);
};
func.isError = function (msg, callback) {
  if (msg) {
    (0, _elementUi.Message)({
      message: msg,
      type: 'error',
      duration: 1500,
      onClose: function onClose() {
        typeof callback == 'function' && callback();
      }
    });
  } else {
    typeof callback == 'function' && callback();
  }
};
func.isSuccess = function (msg, callback) {
  if (msg) {
    (0, _elementUi.Message)({
      message: msg,
      type: 'success',
      duration: 1500,
      onClose: function onClose() {
        typeof callback == 'function' && callback();
      }
    });
  } else {
    typeof callback == 'function' && callback();
  }
};

/** 格式 YYYY/yyyy/YY/yy 表示年份
 * MM/mm/M/m 月份
 * W/w 星期
 * dd/DD/d/D 日期
 * hh/HH/h/H 时间
 * II/ii/I/i 分钟
 * ss/SS/s/S 秒
 **/
func.dateFormat = function () {
  var formatStr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'yyyy-mm-dd hh:ii:ss';
  var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date.now();
  timestamp = timestamp.toString().length == 10 ? timestamp * 1000 : timestamp;
  var str = formatStr;
  var Week = ['日', '一', '二', '三', '四', '五', '六'];
  var date = new Date(timestamp);
  str = str.replace(/yyyy|YYYY/, date.getFullYear());
  str = str.replace(/yy|YY/, date.getYear() % 100 > 9 ? (date.getYear() % 100).toString() : '0' + date.getYear() % 100);
  str = str.replace(/mm|MM/, date.getMonth() > 8 ? (date.getMonth() + 1).toString() : '0' + (date.getMonth() + 1));
  str = str.replace(/m|M/g, date.getMonth() + 1);
  str = str.replace(/w|W/g, Week[date.getDay()]);
  str = str.replace(/dd|DD/, date.getDate() > 9 ? date.getDate().toString() : '0' + date.getDate());
  str = str.replace(/d|D/g, date.getDate());
  str = str.replace(/hh|HH/, date.getHours() > 9 ? date.getHours().toString() : '0' + date.getHours());
  str = str.replace(/h|H/g, date.getHours());
  str = str.replace(/ii|II/, date.getMinutes() > 9 ? date.getMinutes().toString() : '0' + date.getMinutes());
  str = str.replace(/i|I/g, date.getMinutes());
  str = str.replace(/ss|SS/, date.getSeconds() > 9 ? date.getSeconds().toString() : '0' + date.getSeconds());
  str = str.replace(/s|S/g, date.getSeconds());
  return str;
};