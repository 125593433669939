"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "1200px",
      title: "电池轨迹",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      inline: true
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "时间范围"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      align: "right",
      "unlink-panels": "",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "picker-options": _vm.pickerOptions
    },
    model: {
      value: _vm.form.bindDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bindDate", $$v);
      },
      expression: "form.bindDate"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "small",
      type: "success"
    },
    on: {
      click: function click($event) {}
    }
  }, [_vm._v("刷新")])], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "small",
      type: "success"
    },
    on: {
      click: function click($event) {}
    }
  }, [_vm._v("lbs定位")])], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "small",
      type: "success"
    },
    on: {
      click: function click($event) {}
    }
  }, [_vm._v("加载轨迹")])], 1), _c("el-button-group", [_c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("开始")]), _c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("暂停")]), _c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("继续")]), _c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("停止")])], 1)], 1), _c("div", {
    staticClass: "info"
  }, [_vm._v(" 电池编号：BT307204012LMCC240120002,gps编号：2409100001，最后定位时间:2024-02-08 19:30:07 ")]), _c("div", {
    staticClass: "info"
  }, [_vm._v(" lbs定位时间:2024-02-11 07:05:20,lbs位置：福建省 龙岩市 新罗区 双龙路 靠近龙岩富力万达嘉华酒店 ")]), _c("div", {
    staticClass: "map"
  }, [_vm._v(" 地图模块 ")]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;