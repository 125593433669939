"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categoryList = categoryList;
exports.delCategory = delCategory;
exports.delForm = delForm;
exports.editAliAppLink = editAliAppLink;
exports.formList = formList;
exports.getAliApplinkList = getAliApplinkList;
exports.getAlipayCategory = getAlipayCategory;
exports.getCategoryInfo = getCategoryInfo;
exports.sourceList = sourceList;
exports.updateCategory = updateCategory;
exports.updateForm = updateForm;
exports.updateSource = updateSource;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 信息
 */
// 品类列表
function categoryList(params) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/categoryList", params);
}
// 获取品类信息
function getCategoryInfo(params) {
  return _fetch.default.get("/Supplier/BasicInfoManagement/categoryInfo", params);
}
// 添加或编辑品类
function updateCategory(params) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/addOrEditCategory", params, true);
}
// 根据id删除品类
function delCategory(params) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/delCategory", params, true);
}
// 定义表单项列表
function formList(params) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/defineFormList", params);
}
// 添加或编辑定义表单项
function updateForm(params) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/addOrEditDefineForm", params, true);
}
// 根据id删除定义表单项
function delForm(id) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/delDefineForm", {
    id: id
  }, true);
}
// 订单来源列表
function sourceList(params) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/sourceList", params);
}
// 添加或编辑订单来源
function updateSource(params) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/addOrEditSource", params, true);
}
// 品类列表获取下拉选项
function getAlipayCategory() {
  return _fetch.default.get("/Supplier/BasicInfoManagement/getAlipayCategory");
}

// 小程序链接列表
function getAliApplinkList(params) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/getAliApplinkList", params);
}
// 编辑小程序链接
function editAliAppLink(params) {
  return _fetch.default.post("/Supplier/BasicInfoManagement/editAliAppLink", params, true);
}