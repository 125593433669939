"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.terminalTypeList = terminalTypeList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 终端类型
 */
// 角色列表
function terminalTypeList(params) {
  return _fetch.default.get("/Supplier/terminalType/getList", params);
}