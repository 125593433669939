"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        bindDate: ''
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          if (_this.editItem) {
            // 编辑
            // this.form = {
            //     id: this.editItem.id,
            //     username: this.editItem.username,
            //     mobile: this.editItem.mobile,
            //     role_id: this.editItem.role_id,
            // }
          }
        });
      } else {
        this.reset();
      }
    }
  },
  methods: {
    reset: function reset() {
      this.form = this.$options.data().form;
    },
    submit: function submit() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;