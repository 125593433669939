"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var baseUrl = 'http://127.0.0.1:4523/m1/4046785-0-default';
/**
 * @type {import('axios').AxiosInstance}
 */
var mockService = _axios.default.create({
  baseURL: baseUrl,
  timeout: 5000 // 请求超时时间
});

mockService.interceptors.request.use(function (config) {
  // 这里可以做请求拦截，比如统一添加请求头等操作
  return config;
}, function (error) {
  console.error('Request error:', error);
  return Promise.reject(error);
});
mockService.interceptors.response.use(function (response) {
  // 这里可以对响应数据进行处理
  return response.data;
}, function (error) {
  console.error('Response error:', error);
  return Promise.reject(error);
});
var _default = mockService;
exports.default = _default;