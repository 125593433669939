"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.sort");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "footer-page"
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "160px"
    }
  }, [_vm.parentId ? _c("el-form-item", {
    attrs: {
      label: "上级分类:"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择上级分类"
    },
    model: {
      value: _vm.form.parentid,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "parentid", $$v);
      },
      expression: "form.parentid"
    }
  }, _vm._l(_vm.typeList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.type_name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "信息配置名称:",
      prop: "type_name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.type_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type_name", $$v);
      },
      expression: "form.type_name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "排序:"
    }
  }, [_c("el-input-number", {
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", $$v);
      },
      expression: "form.sort"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "状态:"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, [_vm._v("显示")]), _c("el-radio", {
    attrs: {
      label: 2
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, [_vm._v("隐藏")])], 1)], 1), _c("div", {
    staticClass: "page-footer-btns"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v("重置")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("保存")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;