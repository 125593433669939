"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
var _default = {
  props: {
    dialogTitle: {
      type: String
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        baseline: '',
        recharge_num: '',
        recharge_type: 1,
        sms_remind: 1
      },
      infoData: {},
      showError: false,
      checked: false
    };
  },
  methods: {
    goToRecharge: function goToRecharge() {
      var routeData = this.$router.resolve({
        name: "recharge"
      });
      window.open(routeData.href, '_blank');
    },
    submit: function submit() {
      var _this = this;
      if (!this.formData.baseline) {
        if (this.type != 3) {
          this.$message({
            message: "请输入余量底线",
            type: "warning"
          });
          return;
        } else {
          this.$message({
            message: "请输入最低次数",
            type: "warning"
          });
          return;
        }
      }
      if (!this.formData.recharge_num) {
        this.$message({
          message: "请输入自动充值条数",
          type: "warning"
        });
        return;
      }
      if (this.infoData.pay_total > parseFloat(this.infoData.shop_balance)) {
        this.$message({
          message: "余额不足，请先充值",
          type: "warning"
        });
        return;
      }
      if (!this.checked) {
        this.$message({
          message: "请勾选开启并同意自动充值协议",
          type: "warning"
        });
        this.showError = true;
        return;
      }
      this.formData.recharge_type = this.type;
      if (this.type != 3) {
        (0, _marketing.autoRecharge)(this.formData).then(function (res) {
          _this.dialogVisible = false;
          _this.$emit('getList');
        });
      } else {
        (0, _marketing.riskCreditAutoRecharge)(this.formData).then(function (res) {
          _this.dialogVisible = false;
          _this.$emit('getList');
        });
      }
    },
    calculate: function calculate() {
      var num = this.formData.recharge_num || 0;
      var price = 0;
      if (this.type == 1) {
        price = parseFloat(this.infoData.contract);
      } else if (this.type == 2) {
        price = parseFloat(this.infoData.sms_amount);
      } else {
        price = parseFloat(this.infoData.risk_credit_price);
      }
      this.$set(this.infoData, 'pay_total', (price * parseInt(num)).toFixed(2));
    },
    getData: function getData() {
      var _this2 = this;
      (0, _marketing.autoRechargeInfo)(this.type).then(function (res) {
        _this2.infoData = (0, _objectSpread2.default)({}, res) || {};
        _this2.calculate();
      });
    },
    changeNumberInput: function changeNumberInput(key, value) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(value)) {
        // input 框绑定的内容为空
        this.formData[key] = '';
      }
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getData();
      }
    }
  }
};
exports.default = _default;