"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _default = {
  props: ['item'],
  data: function data() {
    return {
      form: {
        leaseId: "",
        whichPeriod: "",
        tradeNo: "",
        rent: "",
        spliteRent: ""
      },
      rules: {
        spliteRent: [{
          required: true,
          message: '金额不能为空'
        }]
      },
      dialogVisible: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      if (this.form.spliteRent <= 0 || this.form.rent - this.form.spliteRent <= 0) {
        this.$message({
          message: "拆分金额不对",
          type: "error"
        });
        return false;
      }
      (0, _order.setLeaseSplite)((0, _objectSpread2.default)({}, this.form)).then(function (res) {
        _this.dialogVisible = false;
        _this.$emit('success', true);
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.form.leaseId = this.item.id;
        this.form.whichPeriod = this.item.which_period;
        this.form.tradeNo = this.item.trade_no;
        this.form.rent = this.item.rent;
      } else {
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};
exports.default = _default;