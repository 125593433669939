"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGroup = addGroup;
exports.addMerchant = addMerchant;
exports.addUser = addUser;
exports.clearBlockIp = clearBlockIp;
exports.delAppVersion = delAppVersion;
exports.delGroup = delGroup;
exports.delMerchant = delMerchant;
exports.delPayMent = delPayMent;
exports.delUser = delUser;
exports.delWechatPay = delWechatPay;
exports.editAppVersion = editAppVersion;
exports.editGroup = editGroup;
exports.editMerchant = editMerchant;
exports.editPayMent = editPayMent;
exports.editPayMentAll = editPayMentAll;
exports.editUser = editUser;
exports.editWechatPay = editWechatPay;
exports.getAlipayAppid = getAlipayAppid;
exports.getAllWhiteListInfo = getAllWhiteListInfo;
exports.getAppVersionList = getAppVersionList;
exports.getBlockIpConfig = getBlockIpConfig;
exports.getBlockIpList = getBlockIpList;
exports.getGroupList = getGroupList;
exports.getPayMentList = getPayMentList;
exports.getWechatPayList = getWechatPayList;
exports.getWhiteIpList = getWhiteIpList;
exports.groupList = groupList;
exports.saveBlockIpConfig = saveBlockIpConfig;
exports.saveWhiteIpList = saveWhiteIpList;
exports.setGroup = setGroup;
exports.setMerchant = setMerchant;
exports.setUser = setUser;
exports.userList = userList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 权限管理
 */
// 角色列表
function groupList(params) {
  return _fetch.default.post("/Supplier/Auth/groupList", params);
}
// 添加角色
function addGroup(params) {
  return _fetch.default.post("/Supplier/Auth/addGroup", params, true);
}
// 启用/禁用角色
function setGroup(params) {
  return _fetch.default.post("/Supplier/Auth/setGroup", params, true);
}
// 删除角色
function delGroup(id) {
  return _fetch.default.post("/Supplier/Auth/delGroup", {
    id: id
  }, true);
}
// 编辑角色
function editGroup(params) {
  return _fetch.default.post("/Supplier/Auth/editGroup", params, true);
}

/**
 * 账号管理
 */
// 获取用户列表
function userList(params) {
  return _fetch.default.post("/Supplier/Auth/userList", params);
}
// 获取选择角色列表
function getGroupList() {
  return _fetch.default.post("/Supplier/Auth/getGroupList");
}
// 批量操作设置状态
function setUser(params) {
  return _fetch.default.post("/Supplier/Auth/setUser", params, true);
}
// 批量删除
function delUser(ids) {
  return _fetch.default.post("/Supplier/Auth/delUser", {
    ids: ids
  }, true);
}
// 添加账户
function addUser(params) {
  return _fetch.default.post("/Supplier/Auth/addUser", params, true);
}
// 编辑账户
function editUser(params) {
  return _fetch.default.post("/Supplier/Auth/editUser", params, true);
}

/**
 * 商户管理
 */
// 批量操作设置状态
function setMerchant(params) {
  return _fetch.default.post("/Supplier/Auth/setMerchant", params, true);
}
// 批量删除
function delMerchant(ids) {
  return _fetch.default.post("/Supplier/Auth/delMerchant", {
    ids: ids
  }, true);
}
// 添加账户
function addMerchant(params) {
  return _fetch.default.post("/Supplier/Auth/addMerchant", params, true);
}
// 编辑账户
function editMerchant(params) {
  return _fetch.default.post("/Supplier/Auth/editMerchant", params, true);
}

// 获取支付宝小程序appid
function getAlipayAppid() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _fetch.default.post("/Supplier/Auth/getAlipayAppid", params);
}
/**
 * 安全管理
 */
// 获取IP封禁配置
function getBlockIpConfig() {
  return _fetch.default.post("/Supplier/Safe/getBlockIpConfig", {});
}
// 保存IP封禁配置
function saveBlockIpConfig(params) {
  return _fetch.default.post("/Supplier/Safe/saveBlockIpConfig", params, true);
}
// 获取封禁IP列表
function getBlockIpList() {
  return _fetch.default.post("/Supplier/Safe/getBlockIpList", {});
}
// 清除封禁IP
function clearBlockIp() {
  return _fetch.default.post("/Supplier/Safe/clearBlockIp", {}, true);
}
// 获取IP白名单列表
function getWhiteIpList(member_id) {
  return _fetch.default.post("/Supplier/Safe/getWhiteIpList", member_id);
}
// 获取所有IP白名单列表
function getAllWhiteListInfo() {
  return _fetch.default.get("/Supplier/Safe/getAllWhiteListInfo");
}
// 保存IP白名单配置
function saveWhiteIpList(params) {
  return _fetch.default.post("/Supplier/Safe/saveWhiteIpList", params, true);
}
/**
 * 支付通道管理
 */
// 获取支付通道列表
function getPayMentList(params) {
  return _fetch.default.post("/Supplier/Auth/getPayMentList", params);
}
// 编辑支付通道列表
function editPayMent(params) {
  return _fetch.default.post("/Supplier/Auth/editPayMent", params, true);
}
// 同步总后台支付通道
function editPayMentAll(params) {
  return _fetch.default.post("/Supplier/Auth/editPayMentAll", params, true);
}
// 删除支付通道列表
function delPayMent(id) {
  return _fetch.default.post("/Supplier/Auth/delPayMent", {
    id: id
  }, true);
}
/**
 * app管理
 */
// 获取app版本列表
function getAppVersionList(params) {
  return _fetch.default.get("/Supplier/App/getAppVersionList", params);
}
// 编辑app版本列表
function editAppVersion(params) {
  return _fetch.default.post("/Supplier/App/editAppVersion", params, true);
}
// 删除app版本列表
function delAppVersion(id) {
  return _fetch.default.post("/Supplier/App/delAppVersion", {
    id: id
  }, true);
}

/**
 * 微信支付商户渠道
 */
// 列表
function getWechatPayList(params) {
  return _fetch.default.post("/Supplier/Auth/getWechatPayList", params);
}
// 编辑
function editWechatPay(params) {
  return _fetch.default.post("/Supplier/Auth/editWechatPay", params, true);
}

// 删除
function delWechatPay(id) {
  return _fetch.default.post("/Supplier/Auth/delWechatPay", {
    id: id
  }, true);
}