"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "500px",
      "append-to-body": "",
      title: "添加备注",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "是否上锁",
      prop: "status"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("解锁")]), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("上锁")])], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 4,
      placeholder: "备注"
    },
    model: {
      value: _vm.form.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;