"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ['item'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        console.log('item', this.item);
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      if (this.formData.newNum == this.item.which_period) {
        this.$message({
          message: "租期不能置换相同的",
          type: "error"
        });
        return false;
      }
      var param = (0, _objectSpread2.default)({
        order_id: this.item.order_id,
        lease_id: this.item.id
      }, this.formData);
      (0, _order.changeLease)(param).then(function (res) {
        _this.$emit('success');
        _this.close();
      }).catch(function () {});
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;