"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tab-title-page"
  }, [_c("div", {
    staticClass: "nav_tabs"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "基础信息",
      name: "base"
    }
  }), _c("el-tab-pane", {
    attrs: {
      label: "主体信息",
      name: "main"
    }
  }), _c("el-tab-pane", {
    attrs: {
      label: "扣费设置",
      name: "rate"
    }
  }), _c("el-tab-pane", {
    attrs: {
      label: "清除缓存",
      name: "cache"
    }
  })], 1)], 1), _c("el-divider", {
    staticClass: "middle_divider"
  }), _c("div", {
    staticClass: "child_page"
  }, [_vm.activeName === "base" ? _c("base-info", {
    ref: "baseInfo"
  }) : _vm._e(), _vm.activeName === "main" ? _c("main-info", {
    ref: "mainInfo"
  }) : _vm._e(), _vm.activeName === "code" ? _c("code-info", {
    ref: "codeInfo"
  }) : _vm._e(), _vm.activeName === "rate" ? _c("rateInfo", {
    ref: "rateInfo"
  }) : _vm._e(), _vm.activeName === "config" ? _c("configInfo", {
    ref: "configInfo"
  }) : _vm._e(), _vm.activeName === "concern" ? _c("concernInfo", {
    ref: "concernInfo"
  }) : _vm._e(), _vm.activeName === "cache" ? _c("cacheInfo", {
    ref: "cacheInfo"
  }) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;