"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addArticle = addArticle;
exports.copyArticle = copyArticle;
exports.delArticle = delArticle;
exports.editHome = editHome;
exports.editHot = editHot;
exports.editSort = editSort;
exports.editStatus = editStatus;
exports.getArticleList = getArticleList;
exports.searchArticle = searchArticle;
exports.searchSecondCategory = searchSecondCategory;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 平台文章列表
function getArticleList(params) {
  return _fetch.default.post("/Supplier/PlatformArticle/platformArticleList", params);
}
// 添加或编辑平台文章
function addArticle(params) {
  return _fetch.default.post("/Supplier/PlatformArticle/addOrEditPlatformArticle", params, true);
}
// 根据id查询平台文章
function searchArticle(id) {
  return _fetch.default.get("/Supplier/PlatformArticle/platformArticleById", {
    id: id
  });
}
// 获取二级分类
function searchSecondCategory(params) {
  return _fetch.default.get("/Supplier/PlatformArticle/secondCategory", params);
}
// 根据id删除平台文章
function delArticle(id) {
  return _fetch.default.post("/Supplier/PlatformArticle/delPlatformArticle", {
    id: id
  }, true);
}
// 根据id编辑首页推荐
function editHome(params) {
  return _fetch.default.post("/Supplier/PlatformArticle/editHomeRecommend", params, true);
}
// 根据id编辑热门推荐
function editHot(params) {
  return _fetch.default.post("/Supplier/PlatformArticle/editHotRecommend", params, true);
}
// 根据id编辑文章信息状态
function editStatus(params) {
  return _fetch.default.post("/Supplier/PlatformArticle/editArticleStatus", params, true);
}
// 根据id编辑文章sort
function editSort(params) {
  return _fetch.default.post("/Supplier/PlatformArticle/editArticleSort", params, true);
}

// 根据id编辑文章信息状态
function copyArticle(params) {
  return _fetch.default.post("/Supplier/PlatformArticle/copyArticle", params, true);
}