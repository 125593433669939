"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.dialogTitle,
      visible: _vm.handleDialog,
      "before-close": _vm.closeDialog,
      "close-on-click-modal": false,
      width: "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.handleDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "sourceForm",
    attrs: {
      model: _vm.sourceForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "订单来源名称：",
      "label-width": "140px",
      prop: "source_name"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.sourceForm.source_name,
      callback: function callback($$v) {
        _vm.$set(_vm.sourceForm, "source_name", $$v);
      },
      expression: "sourceForm.source_name"
    }
  })], 1), _vm.isEdit ? [_c("el-form-item", {
    attrs: {
      label: "来源标识：",
      "label-width": "140px",
      prop: "source_code"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.sourceForm.source_code,
      callback: function callback($$v) {
        _vm.$set(_vm.sourceForm, "source_code", $$v);
      },
      expression: "sourceForm.source_code"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "状态:",
      "label-width": "130px",
      prop: "status"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.sourceForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.sourceForm, "status", $$v);
      },
      expression: "sourceForm.status"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("显示")]), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("隐藏")])], 1)], 1)] : _vm._e()], 2), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("sourceForm");
      }
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;