"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: _vm.drewTip,
      visible: _vm.dialogVisible,
      size: "60%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_vm.loading ? _c("div", {
    staticStyle: {
      padding: "10px"
    }
  }, [_c("el-skeleton", {
    attrs: {
      rows: 6,
      animated: ""
    }
  })], 1) : _c("div", {
    staticStyle: {
      padding: "10px"
    }
  }, [_c("el-descriptions", {
    attrs: {
      contentStyle: _vm.headerCellStyle,
      labelStyle: _vm.headerCellStyle,
      title: _vm.titleTip,
      direction: "vertical",
      column: 4,
      border: ""
    }
  }, _vm._l(_vm.tableData, function (item, index) {
    return _c("el-descriptions-item", {
      key: index,
      attrs: {
        label: item.cn_name
      }
    }, [_vm._v("\n                " + _vm._s(item.value) + "\n            ")]);
  }), 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;