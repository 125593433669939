"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _order = require("@/api/order");
var _default = {
  props: ["orderId", "merchantId"],
  components: {},
  data: function data() {
    return {
      loading: true,
      dialogVisible: false,
      tableData: [],
      selectedItem: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.loading = true;
        this.getOrderRiskControl();
      }
    }
  },
  methods: {
    getOrderRiskControl: function getOrderRiskControl() {
      var _this = this;
      (0, _order.getOrderRiskControl)(this.orderId).then(function (res) {
        _this.tableData = res || [];
        _this.tableData.base_info.name = _this.orderId.name;
        _this.tableData.base_info.phone = _this.orderId.phone;
        _this.loading = false;
      });
    },
    clearData: function clearData() {
      var _this2 = this;
      this.$confirm('确定要删除数据吗？重新加载要花钱？', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _order.clearRateTypeData)({
          orderId: _this2.orderId,
          type: 1
        }).then(function (res) {
          _this2.tableData = res || [];
        });
      }).catch(function () {});
    },
    getoverdue_money: function getoverdue_money(str) {
      switch (str) {
        case "":
          return "0";
        case "0":
          return "0";
        case "1":
          return "（0-500）";
        case "2":
          return "（500-1000）";
        case "3":
          return "（1000-2000）";
        case "4":
          return "（2000-3000）";
        case "5":
          return "（3000-5000）";
        case "6":
          return "（5000-10000）";
        case "7":
          return "（10000-20000）";
        case "8":
          return "（20000-30000）";
        case "9":
          return "（30000-50000）";
        case "10":
          return "（50000-100000）";
        case "11":
          return "（100000-200000）";
        case "12":
          return "（200000-300000）";
        case "13":
          return "（300000-500000）";
        case "14":
          return "（500000-1000000）";
        case "15":
          return "（1000000-∞）";
      }
    }
  }
};
exports.default = _default;