"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "category"
  }, [_c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _vm._m(0)], 1), _vm._l(_vm.dataList, function (item, index) {
    return [_c("category-form", {
      key: index,
      attrs: {
        "form-data": item,
        title: "\u63A8\u8350\u4F4D".concat(index + 1)
      },
      on: {
        upSortItem: function upSortItem($event) {
          return _vm.upSortItem(index);
        },
        downSortItem: function downSortItem($event) {
          return _vm.downSortItem(index);
        },
        deleteItem: function deleteItem($event) {
          return _vm.deleteItem(index);
        }
      }
    })];
  }), _c("div", {
    staticClass: "add-div",
    on: {
      click: _vm.addItem
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v("添加品类商品推荐位\n  ")])], 2);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", [_vm._v("图片要求！")]), _c("br"), _vm._v("图片宽度650px，高度220px。")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;