"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "500px",
      title: "确认收货",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "warn-box",
    staticStyle: {
      padding: "10px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _c("p", {
    staticStyle: {
      color: "#000000",
      "line-height": "23px"
    }
  }, [_vm._v("收货凭证图片可做法律依据，添加后不可进行删除，请谨慎操作。点击查看"), _c("a", {
    staticClass: "blue-font",
    attrs: {
      href: "https://image-xxz.oss-cn-beijing.aliyuncs.com/download/%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A1%B9.doc"
    }
  }, [_vm._v("注意事项")]), _vm._v("，点击下载"), _c("a", {
    staticClass: "blue-font",
    attrs: {
      href: "https://image-xxz.oss-cn-beijing.aliyuncs.com/download/%E6%9C%AC%E4%BA%BA%E7%AD%BE%E6%94%B6%E5%87%AD%E8%AF%81%E6%A0%B7%E5%BC%8F%E8%A1%A8.doc"
    }
  }, [_vm._v("用户签收凭证")]), _vm._v("样式")])], 1), _c("el-form", {
    ref: "ruleForm",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "100px"
    }
  }, [_c("el-form-item", [_c("p", [_vm._v("您确定要确认用户收货吗？")])]), _c("el-form-item", {
    attrs: {
      label: "订单编号"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.orderId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "orderId", $$v);
      },
      expression: "form.orderId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "用户收货凭证",
      prop: "images"
    }
  }, [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "init-val": _vm.form.images,
      "wrapper-width": "100px",
      "wrapper-height": "100px",
      "photo-width": 100,
      "photo-height": 100
    },
    on: {
      setVal: _vm.imgChange
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;