"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      loading: true,
      searchForm: {
        order_no: '',
        status: null
      },
      total: 0,
      tableData: []
    };
  },
  filters: {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      this.loading = true;
      (0, _order.getApplyWithholdList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data || [];
        _this.total = res.total;
      });
    },
    // 发起扣款
    // withholdItem(row){
    //   console.log('row2',row)
    //   this.$confirm("您确定要发起扣款吗？", "发起扣款", {
    // 	confirmButtonText: '确定',
    // 	cancelButtonText: '取消'
    //   }).then(() => {
    // 	manualWithholding({
    // 	  orderId: row.order_id,
    // 	  leaseId: row.id
    // 	}).then(res => {
    // 	  this.getLease()
    // 	  setTimeout(() => {
    // 		this.$parent.getList(true);
    // 	  }, 1000);
    // 	})
    //   }).catch(() => { });
    // },
    // 通过审核
    passAudit: function passAudit(row) {
      var _this2 = this;
      console.log(row);
      this.$confirm('通过审核将给商户扣款权限，是否通过?', '通过审核', {
        confirmButtonText: '通过',
        cancelButtonText: '取消'
      }).then(function () {
        // manualWithholding({
        // 	orderId: row.order_id,
        // 	leaseId: row.lease_id
        // }).then(res => {
        // 	setTimeout(() => {
        // 		this.getList(true)
        // 	}, 1000)
        // })
        (0, _order.applyWithhold)({
          id: row.id,
          status: 1
        }).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    // 拒绝审核
    rejectAudit: function rejectAudit(id) {
      var _this3 = this;
      this.$confirm('您确定要拒绝审核吗?', '拒绝审核', {
        confirmButtonText: '拒绝',
        cancelButtonText: '取消',
        confirmButtonClass: 'el-button--danger'
      }).then(function () {
        (0, _order.applyWithhold)({
          id: id,
          status: 2
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    },
    jumpOrderList: function jumpOrderList(trade_no) {
      this.$router.push({
        path: '/order/list?orderId=' + trade_no
      });
    }
  }
};
exports.default = _default;