"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.regexp.search");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xcx_home_config"
  }, [_c("div", {
    staticClass: "flex main"
  }, [_c("div", {
    staticClass: "flex-1 left"
  }, [_c("div", {
    staticClass: "m-auto model_wrap"
  }, [_vm._l(_vm.sectionList, function (item, index) {
    return [_c("div", {
      key: index,
      staticClass: "section_wrap",
      class: {
        active: index + 1 == _vm.activeNum
      },
      on: {
        click: function click($event) {
          _vm.activeNum = index + 1;
        }
      }
    }, [_c("img", {
      staticClass: "block w-100",
      attrs: {
        src: require("../../../../assets/images/xcx/" + item.imgName),
        alt: item.title
      }
    }), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.show,
        expression: "!item.show"
      }],
      staticClass: "flex flex-center w-100 h-100 hide_div"
    }, [_vm._v("已隐藏")])])];
  })], 2)]), _c("div", {
    staticClass: "right"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      inline: true
    },
    nativeOn: {
      submit: function submit($event) {
        $event.preventDefault();
      },
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search();
      }
    }
  }, [_vm.sMerchantId == 0 ? [_c("el-form-item", {
    attrs: {
      label: "商户名称"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择商户名称",
      clearable: ""
    },
    on: {
      change: _vm.changeMerchant
    },
    model: {
      value: _vm.merchant_id,
      callback: function callback($$v) {
        _vm.merchant_id = $$v;
      },
      expression: "merchant_id"
    }
  }, _vm._l(_vm.merchantList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.username,
        value: item.id
      }
    });
  }), 1)], 1)] : _vm._e()], 2), _c("div", {
    staticClass: "flex flex-between-center header"
  }, [_c("h5", {
    staticClass: "color-666 normal-font"
  }, [_vm._v(_vm._s(_vm.sectionList[_vm.activeNum - 1].title))]), _c("div", [_c("el-switch", {
    attrs: {
      "active-color": "#4ccb86",
      "inactive-color": "#ff4146"
    },
    model: {
      value: _vm.sectionList[_vm.activeNum - 1].show,
      callback: function callback($$v) {
        _vm.$set(_vm.sectionList[_vm.activeNum - 1], "show", $$v);
      },
      expression: "sectionList[activeNum - 1].show"
    }
  }), _c("span", {
    staticClass: "v-middle",
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(_vm._s(_vm.sectionList[_vm.activeNum - 1].show ? "开启" : "隐藏"))])], 1)]), _c("div", {
    staticClass: "main_form"
  }, [_vm.activeNum == 1 ? _c("top-banner", {
    ref: "topBanner"
  }) : _vm._e(), _vm.activeNum == 2 ? _c("nine-grid", {
    ref: "nineGrid"
  }) : _vm._e(), _vm.activeNum == 3 ? _c("sec-banner", {
    ref: "capsuleBanner"
  }) : _vm._e(), _vm.activeNum == 4 ? _c("third-hot", {
    ref: "thirdHot"
  }) : _vm._e(), _vm.activeNum == 5 ? _c("banner-col", {
    ref: "bannerCol"
  }) : _vm._e(), _vm.activeNum == 6 ? _c("two-col-goods", {
    ref: "twoColGoods"
  }) : _vm._e(), _vm.activeNum == 7 ? _c("sec-banner", {
    ref: "secBanner"
  }) : _vm._e(), _vm.activeNum == 8 ? _c("two-activity", {
    ref: "twoActivity"
  }) : _vm._e(), _vm.activeNum == 9 ? _c("category", {
    ref: "category"
  }) : _vm._e(), _vm.activeNum == 11 ? _c("bannerColThree", {
    ref: "bannerColThree"
  }) : _vm._e(), _vm.activeNum == 10 ? _c("topBgColor", {
    ref: "backgroundImg"
  }) : _vm._e()], 1), _c("div", {
    staticClass: "text-center footer"
  }, [_c("el-button", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")])], 1)], 1)]), _c("div", {
    staticClass: "fix-footer-btns"
  }, [_vm.$hasMethod("#preview") ? _c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.preview
    }
  }, [_vm._v("预览")]) : _vm._e(), _vm.$hasMethod("#publish") ? _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.release
    }
  }, [_vm._v("发布")]) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;