"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
var _xcx = require("@/api/xcx");
var _content = _interopRequireDefault(require("./components/content"));
var _default = {
  components: {
    pageContent: _content.default
  },
  data: function data() {
    return {
      pageData: []
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _xcx.previewIndex)().then(function (res) {
        var _iterator = (0, _createForOfIteratorHelper2.default)(res),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            var obj = JSON.parse(item.params);
            _this.pageData.push({
              type: item.type,
              data: obj
            });
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      });
    }
  }
};
exports.default = _default;