"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "editor_container"
  }, [_c("quill-editor", {
    ref: "myTextEditor",
    staticStyle: {
      height: "200px"
    },
    attrs: {
      content: _vm.content,
      options: _vm.editorOption
    },
    on: {
      change: function change($event) {
        return _vm.onEditorChange($event);
      }
    }
  }), _c("span", {
    staticClass: "text_length"
  }, [_vm._v(_vm._s(_vm.textLength) + "字")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "ql-editor",
    domProps: {
      innerHTML: _vm._s(_vm.str)
    }
  }, [_vm._v("\n    " + _vm._s(_vm.str) + "\n  ")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;