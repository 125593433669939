"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _merchant = require("@/api/merchant");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _add = _interopRequireDefault(require("./add"));
var _vuex = require("vuex");
var _default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default
  },
  data: function data() {
    return {
      searchForm: {
        title: "",
        status: null
      },
      total: 0,
      tableData: [],
      editItem: null,
      menuData: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['rule'])),
  mounted: function mounted() {
    this.getList();
    this.menuData = this.rule || [];
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      // const { page, pageSize } = this.$refs.customPage;
      // if (!flag) {
      // 	this.tableData = [];
      // }
      (0, _merchant.getMerchantAdminGruopList)().then(function (res) {
        _this.tableData = res || [];
        // this.total = res.total;
      });
    },
    // 添加/编辑账号
    openAddDialog: function openAddDialog(val) {
      this.editItem = val || null;
      this.$refs.addDialog.dialogVisible = true;
    }
  }
};
exports.default = _default;