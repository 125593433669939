"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _main = _interopRequireDefault(require("./child/main"));
var _rate = _interopRequireDefault(require("./child/rate"));
var _config = _interopRequireDefault(require("./child/config"));
var _default = {
  components: {
    mianInfo: _main.default,
    rateInfo: _rate.default,
    configInfo: _config.default
  },
  data: function data() {
    return {
      activeName: 'main',
      id: 0
    };
  },
  created: function created() {
    this.id = this.$route.params.viewId;
  },
  methods: {
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
    }
  }
};
exports.default = _default;