"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "m_content"
  }, [_vm._l(_vm.pageData, function (section) {
    return [section.type == 1 ? _c("div", {
      key: section.type,
      staticClass: "top_banner"
    }, [_c("el-carousel", {
      attrs: {
        trigger: "click",
        height: "276px",
        interval: 4000
      }
    }, _vm._l(section.data, function (item, index) {
      return _c("el-carousel-item", {
        key: index
      }, [_c("img", {
        staticClass: "w-100 h-100 top_banner_img",
        attrs: {
          src: item.img ? _vm.$globalObj.imgBaseUrl + item.img : "",
          alt: ""
        }
      })]);
    }), 1), _c("div", {
      staticClass: "mask"
    }, [_vm.type == 1 ? _c("img", {
      staticClass: "w-100 h-100",
      attrs: {
        src: require("../../../../../assets/images/iphoneX-top.png"),
        alt: ""
      }
    }) : _c("img", {
      staticClass: "w-100 h-100",
      attrs: {
        src: require("../../../../../assets/images/iphone7-top.png"),
        alt: ""
      }
    })])], 1) : _vm._e(), section.type == 2 ? _c("div", {
      key: section.type,
      staticClass: "inner flex flex-wrap nine_grid"
    }, [_vm._l(section.data, function (item, index) {
      return [_c("div", {
        key: index,
        staticClass: "flex-1 grid_item"
      }, [_c("el-image", {
        staticStyle: {
          width: "44px",
          height: "45px",
          "border-radius": "50%"
        },
        attrs: {
          src: item.img ? _vm.$globalObj.imgBaseUrl + item.img : ""
        }
      }, [_c("div", {
        staticClass: "image-slot",
        attrs: {
          slot: "error"
        },
        slot: "error"
      }, [_c("i", {
        staticClass: "el-icon-picture-outline"
      })])]), _c("p", {
        staticClass: "text-ellipsis title"
      }, [_vm._v(_vm._s(item.title))])], 1)];
    })], 2) : _vm._e(), section.type == 3 ? _c("div", {
      key: section.type,
      staticClass: "inner capsule_banner"
    }, [_c("img", {
      staticClass: "w-100",
      staticStyle: {
        "border-radius": "10px"
      },
      attrs: {
        src: section.data && section.data[0].img ? _vm.$globalObj.imgBaseUrl + section.data[0].img : "",
        alt: ""
      }
    })]) : _vm._e(), section.type == 4 ? _c("div", {
      key: section.type,
      staticClass: "inner third_hot"
    }, [_c("div", {
      staticStyle: {
        height: "207px"
      },
      style: {
        "background-image": section.data.main.backgroundImg ? "url(".concat(_vm.$globalObj.imgBaseUrl + section.data.main.backgroundImg, ")") : "none"
      }
    }, [_c("h5", {
      staticClass: "text-ellipsis"
    }, [_c("img", {
      staticClass: "hot_icon",
      attrs: {
        src: require("../../../../../assets/images/hot-icon.png"),
        alt: ""
      }
    }), _vm._v("\n          " + _vm._s(section.data.main.title))]), _c("p", {
      staticClass: "text-ellipsis",
      staticStyle: {
        margin: "9px 0",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(section.data.main.subtitle))]), _c("div", {
      staticClass: "flex"
    }, [_vm._l(section.data.goods, function (thItem, index) {
      return [_c("div", {
        key: thItem.goods_id,
        staticClass: "flex-1 text-center th_item"
      }, [_c("span", {
        staticClass: "goods_icon"
      }, [index == 0 ? _c("img", {
        attrs: {
          src: require("../../../../../assets/images/hot-Grade1.png"),
          alt: ""
        }
      }) : _vm._e(), index == 1 ? _c("img", {
        attrs: {
          src: require("../../../../../assets/images/hot-Grade2.png"),
          alt: ""
        }
      }) : _vm._e(), index == 2 ? _c("img", {
        attrs: {
          src: require("../../../../../assets/images/hot-Grade3.png"),
          alt: ""
        }
      }) : _vm._e()]), _c("img", {
        staticStyle: {
          width: "77px",
          height: "77px"
        },
        attrs: {
          src: thItem.imgurl ? _vm.$globalObj.imgBaseUrl + thItem.imgurl : "",
          alt: ""
        }
      }), _c("p", {
        staticClass: "text-ellipsis",
        staticStyle: {
          "font-size": "12px",
          "margin-top": "5px",
          "margin-bottom": "5px"
        }
      }, [_vm._v(_vm._s(thItem.title))]), _c("p", {
        staticClass: "color-999 text-ellipsis",
        staticStyle: {
          "font-size": "12px"
        }
      }, [_c("span", {
        staticClass: "red-font",
        staticStyle: {
          "font-size": "14px"
        }
      }, [_vm._v("￥" + _vm._s(thItem.price))]), _vm._v("/天起")])])];
    })], 2)])]) : _vm._e(), section.type == 5 ? _c("div", {
      key: section.type,
      staticClass: "inner flex banner_col"
    }, [_c("div", {
      staticClass: "banner_wrap"
    }, [_c("el-carousel", {
      attrs: {
        trigger: "click",
        height: "250px",
        interval: 4000
      }
    }, _vm._l(section.data.carousel, function (cItem, cIndex) {
      return _c("el-carousel-item", {
        key: cIndex
      }, [_c("img", {
        staticClass: "w-100 h-100",
        staticStyle: {
          "border-radius": "10px"
        },
        attrs: {
          src: cItem.img ? _vm.$globalObj.imgBaseUrl + cItem.img : "",
          alt: ""
        }
      })]);
    }), 1)], 1), _c("div", {
      staticClass: "flex flex-1 flex-column flex-between col_wrap"
    }, [_c("div", {
      staticClass: "col_item",
      style: {
        "background-image": section.data.twoBar.one.main.backgroundImg ? "url(".concat(_vm.$globalObj.imgBaseUrl + section.data.twoBar.one.main.backgroundImg, ")") : "none"
      }
    }, [_c("h5", {
      staticClass: "text-ellipsis",
      staticStyle: {
        "max-width": "145px"
      }
    }, [_vm._v(_vm._s(section.data.twoBar.one.main.title))]), _c("p", {
      staticClass: "text-ellipsis",
      staticStyle: {
        "max-width": "145px",
        "font-size": "13px",
        margin: "5px 0 6px"
      }
    }, [_vm._v(_vm._s(section.data.twoBar.one.main.subtitle))]), _c("p", {
      staticClass: "flex flex-between"
    }, _vm._l(section.data.twoBar.one.goods, function (cg, cgIndex) {
      return _c("img", {
        key: cgIndex,
        staticStyle: {
          width: "65px",
          height: "65px"
        },
        attrs: {
          src: cg.imgurl ? _vm.$globalObj.imgBaseUrl + cg.imgurl : "",
          alt: ""
        }
      });
    }), 0)]), _c("div", {
      staticClass: "col_item",
      style: {
        "background-image": section.data.twoBar.two.main.backgroundImg ? "url(".concat(_vm.$globalObj.imgBaseUrl + section.data.twoBar.two.main.backgroundImg, ")") : "none"
      }
    }, [_c("h5", {
      staticClass: "text-ellipsis",
      staticStyle: {
        "max-width": "145px"
      }
    }, [_vm._v(_vm._s(section.data.twoBar.two.main.title))]), _c("p", {
      staticClass: "text-ellipsis",
      staticStyle: {
        "max-width": "145px",
        "font-size": "13px",
        margin: "5px 0 6px"
      }
    }, [_vm._v(_vm._s(section.data.twoBar.two.main.subtitle))]), _c("p", {
      staticClass: "flex flex-between"
    }, _vm._l(section.data.twoBar.two.goods, function (cg, cgIndex) {
      return _c("img", {
        key: cgIndex,
        staticStyle: {
          width: "65px",
          height: "65px"
        },
        attrs: {
          src: cg.imgurl ? _vm.$globalObj.imgBaseUrl + cg.imgurl : "",
          alt: ""
        }
      });
    }), 0)])])]) : _vm._e(), section.type == 6 ? _c("div", {
      key: section.type,
      staticClass: "flex inner two_col_goods"
    }, [_c("div", {
      staticClass: "flex-1 col_item",
      style: {
        "background-image": section.data.one.main.backgroundImg ? "url(".concat(_vm.$globalObj.imgBaseUrl + section.data.one.main.backgroundImg, ")") : "none"
      }
    }, [_c("h5", {
      staticClass: "text-ellipsis"
    }, [_vm._v(_vm._s(section.data.one.main.title))]), _c("p", {
      staticClass: "text-ellipsis",
      staticStyle: {
        margin: "6px 0 11px"
      }
    }, [_vm._v(_vm._s(section.data.one.main.subtitle))]), _c("div", {
      staticClass: "flex flex-between img_wrap"
    }, _vm._l(section.data.one.goods, function (gItem, gIndex) {
      return _c("div", {
        key: gIndex,
        staticClass: "flex-1 text-center"
      }, [_c("img", {
        staticStyle: {
          width: "65px",
          height: "65px"
        },
        attrs: {
          src: gItem.imgurl ? _vm.$globalObj.imgBaseUrl + gItem.imgurl : "",
          alt: ""
        }
      }), _c("p", {
        staticClass: "text-ellipsis",
        staticStyle: {
          "font-size": "12px"
        }
      }, [_vm._v("￥" + _vm._s(gItem.price) + "/天")])]);
    }), 0)]), _c("div", {
      staticClass: "flex-1 col_item",
      style: {
        "background-image": section.data.two.main.backgroundImg ? "url(".concat(_vm.$globalObj.imgBaseUrl + section.data.two.main.backgroundImg, ")") : "none"
      }
    }, [_c("h5", {
      staticClass: "text-ellipsis"
    }, [_vm._v(_vm._s(section.data.two.main.title))]), _c("p", {
      staticClass: "text-ellipsis",
      staticStyle: {
        margin: "6px 0 11px"
      }
    }, [_vm._v(_vm._s(section.data.two.main.subtitle))]), _c("div", {
      staticClass: "flex flex-between img_wrap"
    }, _vm._l(section.data.two.goods, function (gItem, gIndex) {
      return _c("div", {
        key: gIndex,
        staticClass: "flex-1 text-center"
      }, [_c("img", {
        staticStyle: {
          width: "65px",
          height: "65px"
        },
        attrs: {
          src: gItem.imgurl ? _vm.$globalObj.imgBaseUrl + gItem.imgurl : "",
          alt: ""
        }
      }), _c("p", {
        staticClass: "text-ellipsis",
        staticStyle: {
          "font-size": "12px"
        }
      }, [_vm._v("￥" + _vm._s(gItem.price) + "/天")])]);
    }), 0)])]) : _vm._e(), section.type == 7 ? _c("div", {
      key: section.type,
      staticClass: "inner sec_banner"
    }, [_c("el-carousel", {
      attrs: {
        trigger: "click",
        height: "85px",
        interval: 4000
      }
    }, _vm._l(section.data, function (sItem, sIndex) {
      return _c("el-carousel-item", {
        key: sIndex
      }, [_c("img", {
        staticClass: "w-100 h-100",
        staticStyle: {
          "border-radius": "10px"
        },
        attrs: {
          src: sItem.img ? _vm.$globalObj.imgBaseUrl + sItem.img : "",
          alt: ""
        }
      })]);
    }), 1)], 1) : _vm._e(), section.type == 8 ? _c("div", {
      key: section.type,
      staticClass: "flex inner two_activity"
    }, _vm._l(section.data, function (aItem, aIndex) {
      return _c("div", {
        key: aIndex,
        staticClass: "flex-1 img_item",
        style: {
          "background-image": aItem.img ? "url(".concat(_vm.$globalObj.imgBaseUrl + aItem.img, ")") : "none"
        }
      });
    }), 0) : _vm._e(), section.type == 9 ? _c("div", {
      key: section.type,
      staticClass: "inner cate_list"
    }, [_vm._l(section.data, function (cateItem, cateIndex) {
      return [_c("div", {
        key: cateIndex,
        staticClass: "cate_item"
      }, [_c("p", {
        staticClass: "header text-ellipsis text-center"
      }, [_vm._v(_vm._s(cateItem.main.title))]), _c("img", {
        staticClass: "w-100 m_img",
        staticStyle: {
          height: "110px"
        },
        attrs: {
          src: cateItem.main.img ? _vm.$globalObj.imgBaseUrl + cateItem.main.img : "",
          alt: ""
        }
      }), _c("div", {
        staticClass: "flex"
      }, _vm._l(cateItem.goods, function (cgItem, cgIndex) {
        return _c("div", {
          key: cgIndex,
          staticClass: "flex-1 text-center cg_item"
        }, [_c("p", [_c("img", {
          staticStyle: {
            width: "85px",
            height: "85px"
          },
          attrs: {
            src: cgItem.imgurl ? _vm.$globalObj.imgBaseUrl + cgItem.imgurl : "",
            alt: ""
          }
        })]), _c("p", {
          staticClass: "text-ellipsis",
          staticStyle: {
            "font-size": "12px",
            margin: "4px 0 10px"
          }
        }, [_vm._v(_vm._s(cgItem.title))]), _c("p", {
          staticClass: "red-font text-ellipsis",
          staticStyle: {
            "font-size": "12px"
          }
        }, [_vm._v("￥"), _c("span", {
          staticStyle: {
            "font-size": "14px"
          }
        }, [_vm._v(_vm._s(cgItem.price))]), _vm._v("/天")])]);
      }), 0)])];
    })], 2) : _vm._e()];
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;