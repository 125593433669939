"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batteryWareDel = batteryWareDel;
exports.batteryWareList = batteryWareList;
exports.batteryWareOpen = batteryWareOpen;
exports.batteryWareSave = batteryWareSave;
exports.cabinetQueryInfo = cabinetQueryInfo;
exports.getChargeStatusList = getChargeStatusList;
exports.setCharge = setCharge;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 电池仓管理
 */
//
function batteryWareList(params) {
  return _fetch.default.get('/Supplier/batteryWare/getList', params);
}
function batteryWareSave(params) {
  return _fetch.default.post('/Supplier/batteryWare/save', params, true);
}
function batteryWareDel(params) {
  return _fetch.default.post('/Supplier/batteryWare/del', params, true);
}
function batteryWareOpen(params) {
  return _fetch.default.post('/Supplier/batteryWare/open', params, true);
}
function getChargeStatusList(params) {
  return _fetch.default.get('/Supplier/batteryWare/getChargeStatusList', params);
}
function setCharge(params) {
  return _fetch.default.get('/Supplier/batteryWare/setCharge', params, true);
}
// 下发电柜状态查询指令
function cabinetQueryInfo(params) {
  return _fetch.default.get('/Supplier/batteryWare/cabinetQueryInfo', params, true);
}