"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "col_form office_first"
  }, [_vm.levelName == "first_level" ? _c("crop-upload", {
    staticClass: "w-100 img_bg",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.background_image,
      "wrapper-width": _vm.photoWidth + "px",
      "wrapper-height": _vm.photoHeight + "px",
      "img-width": _vm.imgWidth,
      "img-height": _vm.imgHeight,
      "photo-width": _vm.photoWidth,
      "photo-height": _vm.photoHeight
    },
    on: {
      setVal: function setVal(val) {
        _vm.formData.background_image = val;
      }
    }
  }) : _vm._e(), _c("div", {
    staticClass: "input_wrapper"
  }, [_c("div", {
    staticClass: "flex name"
  }, [_c("label", {
    staticClass: "flex align-center justify-center"
  }, [_vm._v(" 主标题\n\t\t\t")]), _c("el-input", {
    attrs: {
      placeholder: "请输入主标题",
      clearable: ""
    },
    model: {
      value: _vm.formData.main_title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "main_title", $$v);
      },
      expression: "formData.main_title"
    }
  })], 1), _vm.levelName == "first_level" ? _c("div", {
    staticClass: "flex name"
  }, [_c("label", {
    staticClass: "flex align-center justify-center"
  }, [_vm._v(" 背景色\n\t\t\t")]), _c("el-color-picker", {
    model: {
      value: _vm.formData.background_color,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "background_color", $$v);
      },
      expression: "formData.background_color"
    }
  })], 1) : _vm._e()]), _c("goods-add", {
    attrs: {
      maxNum: _vm.goodsNum,
      "goods-list": _vm.formData.goods
    },
    on: {
      setGoods: _vm.setGoods
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;