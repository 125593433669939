"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user.js");
var _imageViewer = _interopRequireDefault(require("element-ui/packages/image/src/image-viewer"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    ElImageViewer: _imageViewer.default,
    cropUpload: _cropUpload.default
  },
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      isShow: false,
      image: [],
      idcard_back: '',
      loading: true,
      sb: 1,
      idcard_front: '',
      options: [],
      rules: {
        // 加一个userIds的校验
        userIds: [{
          required: false,
          message: '请选择邀请的用户',
          trigger: 'change'
        }],
        cycle: [{
          required: false,
          message: '请选择分佣周期',
          trigger: 'change'
        }],
        proxy_percent: [{
          required: false,
          message: '请输入分佣比例',
          trigger: 'change'
        }],
        amount: [{
          required: true,
          message: '请输入分佣金额',
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: '请输入正确的分佣金额',
          trigger: 'blur'
        }]
      },
      proxyInfo: {},
      cycleList: [{
        value: 6,
        label: '6个月'
      }, {
        value: 12,
        label: '12个月'
      }],
      submitForm: {
        is_proxy: 0
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        var id = this.editItem.id;
        this.current_user_id = id;
        console.log('xx', id);
        this.handelProxyInfo(id);
      } else {
        this.idcard_back = '';
        this.idcard_front = '';
        this.resetForm('formRef');
      }
    }
  },
  methods: {
    handelProxyInfo: function handelProxyInfo(id) {
      var _this = this;
      (0, _user.getProxyMemberConfig)({
        id: id
      }).then(function (res) {
        console.log('handelProxyInfo', res);
        if (res) {
          _this.proxyInfo = res;
          if (res.proxy_config) {
            Object.assign(_this.submitForm, res.proxy_config);
          }
          // if (res.shareUserList) this.handleOptions(res.shareUserList)
        }
      }).finally(function () {
        _this.loading = false;
      });
    },
    // 处理shareUserList；得到[{label:'xxx',value:'xxx'}]格式
    handleOptions: function handleOptions() {
      this.options = this.proxyInfo.shareUserList.map(function (item) {
        return {
          label: item.cert_name,
          value: item.id,
          disabled: item.is_proxy == 1 ? true : false
        };
      });
    },
    closeDialog: function closeDialog(formName) {
      this.resetForm(formName);
      this.dialogVisible = false;
    },
    validate: function validate(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var params = {};
          if (!_this2.submitForm.is_proxy) {
            params.id = _this2.submitForm.id;
            params.is_proxy = _this2.submitForm.is_proxy;
          } else {
            params = _this2.submitForm;
          }
          params.id = _this2.current_user_id;
          (0, _user.setProxyMemberConfig)(params).then(function (res) {
            console.log('setProxyMemberConfig', res);
            _this2.closeDialog('formRef');
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 注意后端拿到数据的时候，优先处理是否为代理人设置，如果关闭，则不处理其他字段
    handleSubmit: function handleSubmit(formName) {
      console.log('submitForm', this.submitForm);
      this.validate(formName);
      // this.dialogVisible = false
    },
    handleChangeUser: function handleChangeUser(val) {
      this.submitForm.userIds = val;
      console.log('handleChangeUser', val);
    },
    showImg: function showImg(img) {
      this.image = [this.$globalObj.imgBaseUrl + img];
      // console.log('showImg', this.image)
      this.isShow = true;
    },
    // 关闭预览
    close: function close() {
      this.isShow = false;
      this.image = [];
    },
    imgChange: function imgChange(val, type) {
      var _this3 = this;
      if (val) {
        var param = {
          uid: this.editItem.uid
        };
        param[type] = val;
        editMemberInfo(param).then(function (res) {
          _this3[type] = val;
          _this3.$emit('getList', true);
        });
      } else {
        this[type] = val;
      }
    }
  }
};
exports.default = _default;