"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("../store"));
var _auth = require("./auth");
var _jsMd = _interopRequireDefault(require("js-md5"));
var _index = require("./index");
//获取到token

function isNumber(obj) {
  return typeof obj === "number" && !isNaN(obj);
}
function isString(str) {
  return typeof str == "string" && str.constructor == String;
}

//创建一个axios实例
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 5000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    appVersion: process.env.VUE_APP_VERSION
  }
});

// 请求拦截器
service.interceptors.request.use(function (config) {
  var secretKey = "sdksdksdcfsdiot";
  var timeStamp = new Date().getTime() + "";
  var crypt = "";
  if (config.method === "get") {
    config.params = config.params ? config.params : {
      timeStamp: timeStamp
    };
    for (var key in config.params) {
      config.params[key] = config.params[key] || config.params[key] === 0 ? isNumber(config.params[key]) ? config.params[key] + "" : config.params[key] : "";
      if (isString(config.params[key])) {
        config.params[key] = config.params[key].trim();
      }
    }
    crypt = (0, _jsMd.default)(JSON.stringify(config.params) + secretKey);
  } else {
    if (config.url === "/Supplier/upload") {
      config.data.append("timeStamp", timeStamp);
      var params = {
        sizeLimit: config.data.get("sizeLimit"),
        riginalName: config.data.get("riginalName"),
        timeStamp: timeStamp
      };
      crypt = (0, _jsMd.default)(JSON.stringify((0, _objectSpread2.default)({}, params)) + secretKey);
    } else {
      config.data = config.data && JSON.stringify(config.data) !== "{}" ? config.data : {
        timeStamp: timeStamp
      };
      for (var _key in config.data) {
        config.data[_key] = config.data[_key] || config.data[_key] === 0 ? isNumber(config.data[_key]) ? config.data[_key] + "" : config.data[_key] : "";
        if (isString(config.data[_key])) {
          config.data[_key] = config.data[_key].trim();
        }
      }
      crypt = (0, _jsMd.default)(JSON.stringify(config.data) + secretKey);
    }
  }
  var memeberId = (0, _auth.getMemberId)();
  var merchantId = (0, _auth.getMerchantId)();
  if (memeberId) {
    config.headers["memberId"] = memeberId;
    config.headers["merchantId"] = merchantId || 0; //没有merchantid的就是供应商，有就是商户登陆，merchantid可以根据登录页面选择来选取
  }

  config.headers["supplierCrypt"] = crypt;
  var token = (0, _auth.getToken)(); //获取token
  if (token) {
    //每次请求都需要带上token去请求接口
    config.headers["SupplierAuth"] = token;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

//响应拦截器
service.interceptors.response.use(function (response, flag) {
  var noMsg = response.config.url === "/Supplier/Account/rechargeQuery";
  if (response.status === 200) {
    if (response.data.status === 200) {
      return Promise.resolve(response.data);
    } else {
      !noMsg && _elementUi.Message.error(response.data.msg);
      if (response.data.status === 300) {
        _store.default.dispatch("user/logout");
      }
      return Promise.reject(response);
    }
  } else {
    return Promise.reject(response);
  }
}, function (error) {
  switch (error.response.status) {
    case 405:
      _elementUi.Message.error(error.response.info);
      break;
    default:
      break;
  }
  return Promise.reject(error);
});
var _default = {
  /**
   * get方法，对应get请求
   * @param {String} url [请求的url地址]
   * @param {Object} params [请求时携带的参数]
   * @param {Boolean} flag [是否弹出提示]
   */
  get: function get(url, params, flag) {
    return new Promise(function (resolve, reject) {
      service.get(url, {
        params: params
      }).then(function (res) {
        if (flag && res.status === 200) {
          _elementUi.Message.success(res.msg);
        }
        resolve(res.data);
      }).catch(function (err) {
        reject(err.data);
      });
    });
  },
  /**
   * post方法，对应post请求
   * @param {String} url [请求的url地址]
   * @param {Object} params [请求时携带的参数]
   * @param {Boolean} flag [是否弹出提示]
   */
  post: function post(url, params, flag) {
    return new Promise(function (resolve, reject) {
      service.post(url, params).then(function (res) {
        if (flag && res.status === 200) {
          _elementUi.Message.success(res.msg);
        }
        resolve(res.data);
      }).catch(function (err) {
        reject(err.data);
      });
    });
  },
  /**
   * upload方法，对应upload post请求
   * @param {String} url [请求的url地址]
   * @param {Object} params [请求时携带的参数]
   * @param {Boolean} flag [是否弹出提示]
   */
  upload: function upload(url, params, flag) {
    return new Promise(function (resolve, reject) {
      service.post(url, params, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(function (res) {
        if (flag && res.status === 200) {
          _elementUi.Message.success(res.msg);
        }
        resolve(res.data);
      }).catch(function (err) {
        reject(err.data);
      });
    });
  },
  /**
   * export方法
   * @param {String} url [请求的url地址]
   * @param {Object} params [请求时携带的参数]
   */
  export: function _export(url, params, name) {
    var loading = _elementUi.Loading.service({
      lock: true,
      text: "导出中。。。。。。",
      spinner: "el-icon-loading"
    });
    return new Promise(function (resolve, reject) {
      var secretKey = "sdksdksdcfsdiot";
      var timeStamp = new Date().getTime() + "";
      var data = params && JSON.stringify(params) !== "{}" ? params : {
        timeStamp: timeStamp
      };
      for (var key in data) {
        data[key] = data[key] || data[key] === 0 ? isNumber(data[key]) ? data[key] + "" : data[key] : "";
        if (isString(data[key])) {
          data[key] = data[key].trim();
        }
      }
      var crypt = (0, _jsMd.default)(JSON.stringify(data) + secretKey);
      _axios.default.post(process.env.VUE_APP_BASE_API + url, params, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          SupplierAuth: (0, _auth.getToken)(),
          supplierCrypt: crypt,
          appVersion: process.env.VUE_APP_VERSION
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.download = name + (0, _index.dateFormat)(new Date()) + ".xls";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        loading.close();
        resolve(true);
      }).catch(function (err) {
        loading.close();
        reject(err);
      });
    });
  }
};
exports.default = _default;