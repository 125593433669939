"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _imageViewer = _interopRequireDefault(require("element-ui/packages/image/src/image-viewer"));
var _default = {
  components: {
    ElImageViewer: _imageViewer.default
  },
  props: {
    width: {
      type: Number,
      default: 95
    },
    height: {
      type: Number,
      default: 95
    },
    imgUrl: {
      type: String
    }
  },
  data: function data() {
    return {
      showViewer: false
    };
  },
  methods: {
    // 关闭预览
    closeViewer: function closeViewer() {
      this.showViewer = false;
    }
  }
};
exports.default = _default;