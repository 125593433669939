"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _web = require("@/api/web");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _article = require("@/api/article");
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      articleType: 0,
      articleFrom: {
        article_title: "",
        status: "",
        parentid: "",
        publish_time: ""
      },
      topTypeList: [],
      tableData: [],
      total: 0,
      handleId: "",
      searchObj: {}
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.getArticleType();
      this.getTopType();
      this.getTableLists();
    },
    copyFile: function copyFile(type) {
      var _this = this;
      (0, _article.copyArticle)({
        type: type
      }).then(function (res) {
        _this.getTableLists();
      });
    },
    getArticleType: function getArticleType() {
      if (this.$route.name == 'articleHelp') {
        // 帮助中心
        this.articleType = 1;
      } else if (this.$route.name == 'articleAgree') {
        // 协议中心
        this.articleType = 2;
      } else if (this.$route.name == 'articleShare') {
        // 分享教程
        this.articleType = 3;
      } else if (this.$route.name == 'articleEssay') {
        // 分享教程
        this.articleType = 4;
      }
    },
    getTopType: function getTopType() {
      var _this2 = this;
      (0, _web.getInfoTypeList)().then(function (res) {
        _this2.topTypeList = res;
      });
    },
    getTableLists: function getTableLists(articleFrom) {
      var _this3 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      var obj = (0, _objectSpread2.default)({
        type: this.articleType,
        page: page,
        pageSize: pageSize
      }, articleFrom);
      (0, _article.getArticleList)(obj).then(function (res) {
        _this3.tableData = res.data;
        _this3.total = res.total;
      });
    },
    changeRecommend: function changeRecommend(type, id, recommend) {
      var _this4 = this;
      if (type == "home") {
        (0, _article.editHome)({
          id: id,
          home_recommend: recommend == 1 ? "2" : "1"
        }).then(function (res) {
          _this4.getTableLists();
        });
      } else if (type == "hot") {
        (0, _article.editHot)({
          id: id,
          hot_recommend: recommend == 1 ? "2" : "1"
        }).then(function (res) {
          _this4.getTableLists();
        });
      } else {
        (0, _article.editStatus)({
          id: id,
          status: recommend == 1 ? "2" : "1"
        }).then(function (res) {
          _this4.getTableLists();
        });
      }
    },
    handleBtn: function handleBtn(id) {
      var _this5 = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _article.delArticle)(id).then(function (res) {
          _this5.getTableLists();
        });
      }).catch(function () {});
    },
    onSubmit: function onSubmit() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.articleFrom.publish_time = pickerVal;
        this.searchObj = (0, _objectSpread2.default)({}, this.articleFrom);
        this.$refs.customPage.page = 1;
        this.getTableLists(this.searchObj);
      }
    },
    onReset: function onReset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.articleFrom = Object.assign({});
      this.getTableLists();
    },
    // 修改排序
    editSort: function editSort(id, val) {
      var _this6 = this;
      console.log('editSort');
      (0, _article.editSort)({
        id: id,
        sort: val
      }).then(function (res) {
        _this6.getTableLists();
      });
    }
  }
};
exports.default = _default;