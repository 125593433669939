"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _info = require("@/api/info");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _addCustom = _interopRequireDefault(require("../custom/addCustom"));
var _default = {
  components: {
    customPage: _customPage.default,
    addCustom: _addCustom.default
  },
  data: function data() {
    return {
      tableData: [],
      dialogTitle: "",
      handleDialog: false,
      isEdit: true,
      total: 0,
      cateList: [],
      searchForm: {
        name: "",
        field_type: ""
      },
      customForm: {
        category_id: "",
        field_order: "",
        field_title: "",
        field_type: "",
        field_val: "",
        must_is: 1
      },
      tagsList: [],
      inputVisible: false
    };
  },
  methods: {
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = Object.assign({});
      this.getList();
    },
    addSources: function addSources() {
      if (this.$refs.saveTagInput.$refs.customForm) {
        this.$refs.saveTagInput.$refs.customForm.resetFields();
      }
      this.dialogTitle = "添加表单项";
      this.handleDialog = true;
      this.isEdit = true;
      this.customForm = {
        category_id: "",
        field_order: "",
        field_title: "",
        field_type: "",
        field_val: "",
        must_is: 1
      };
      this.tagsList = [];
    },
    submitForm: function submitForm() {
      var _this = this;
      if (this.tagsList.length == 0) {
        this.$message.error("字段值不能为空");
        return false;
      } else {
        this.customForm.field_val = this.tagsList.join(',');
        (0, _info.updateForm)(this.customForm).then(function (res) {
          _this.handleDialog = false;
          _this.getList();
        });
      }
    },
    deleteItem: function deleteItem(id) {
      var _this2 = this;
      this.$confirm("您确定要删除吗？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _info.delForm)(id).then(function (res) {
          _this2.getList();
        });
      });
    },
    editItem: function editItem(row) {
      if (this.$refs.saveTagInput.$refs.customForm) {
        this.$refs.saveTagInput.$refs.customForm.resetFields();
      }
      this.dialogTitle = "编辑表单项";
      this.handleDialog = true;
      this.isEdit = false;
      var form = (0, _objectSpread2.default)({}, row);
      delete form.name;
      delete form.create_time;
      this.tagsList = form.field_val ? form.field_val.split(',') : [];
      form.must_is = form.must_is == '是' ? 1 : 0;
      form.field_type = form.field_type == '文本框' ? 1 : form.field_type == '单选' ? 2 : 3;
      this.customForm = form;
    },
    closeDialog: function closeDialog() {
      this.customForm = Object.assign({});
      this.handleDialog = false;
    },
    closeTag: function closeTag(tag) {
      var _this3 = this;
      this.tagsList.forEach(function (item, index) {
        if (tag && tag == item) {
          _this3.tagsList.splice(index, 1);
        }
      });
    },
    tagInputConfirm: function tagInputConfirm(val) {
      this.tagsList = val;
    },
    showInput: function showInput() {
      this.inputVisible = true;
    },
    changeCascader: function changeCascader(val) {
      this.customForm.category_id = val;
    },
    getCateList: function getCateList() {
      var _this4 = this;
      (0, _info.getCategoryInfo)().then(function (res) {
        _this4.cateList = res || [];
      });
    },
    // 获取列表
    getList: function getList() {
      var _this5 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _info.formList)({
        page: page,
        pageSize: pageSize,
        name: this.searchForm.name,
        field_type: this.searchForm.field_type
      }).then(function (res) {
        // console.log('res',res)
        _this5.tableData = res.data;
        _this5.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
    this.getCateList();
  }
};
exports.default = _default;