"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _imageViewer = _interopRequireDefault(require("element-ui/packages/image/src/image-viewer"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    ElImageViewer: _imageViewer.default,
    cropUpload: _cropUpload.default
  },
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      isShow: false,
      image: [],
      idcard_back: '',
      idcard_front: ''
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.idcard_back = this.editItem.idcard_back;
        this.idcard_front = this.editItem.idcard_front;
      } else {
        this.idcard_back = '';
        this.idcard_front = '';
      }
    }
  },
  methods: {
    showImg: function showImg(img) {
      this.image = [this.$globalObj.imgBaseUrl + img];
      // console.log('showImg', this.image)
      this.isShow = true;
    },
    // 关闭预览
    close: function close() {
      this.isShow = false;
      this.image = [];
    },
    imgChange: function imgChange(val, type) {
      var _this = this;
      if (val) {
        var param = {
          uid: this.editItem.uid
        };
        param[type] = val;
        (0, _order.editMemberInfo)(param).then(function (res) {
          _this[type] = val;
          _this.$emit('getList', true);
        });
      } else {
        this[type] = val;
      }
    }
  }
};
exports.default = _default;