"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "更换租期",
      visible: _vm.dialogVisible,
      width: "500px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "更换期数：",
      "label-width": "120px"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      placeholder: "请输入期数"
    },
    model: {
      value: _vm.formData.newNum,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "newNum", $$v);
      },
      expression: "formData.newNum"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("提交")]), _c("el-button", {
    attrs: {
      type: "primary",
      plain: ""
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;