"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _h = require("@/api/h5");
var _default = {
  data: function data() {
    return {
      loading: true,
      isAuth: true,
      imgSuccess: 'img/success-icon.png',
      imgFail: 'img/close.png',
      urlPrefix: 'ssz://',
      url: 'ssz://',
      type: '',
      formData: {},
      res: {}
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.$func.setTitle('支付宝授权登录');
      this.formData = this.$route.query;
      this.type = this.$route.query.type;
      this.getAuth();
    },
    getAuth: function getAuth() {
      var _this = this;
      this.loading = true;
      (0, _h.appAlipayLogin)(this.formData).then(function (res) {
        _this.loading = false;
        _this.isAuth = true;
        console.log('appAlipayLogin', res);
        _this.res = res;
        if (res) {
          var param = "?alipay_user_id=".concat(res.alipayUid, "&token=").concat(res.token, "&avatar=").concat(res.avatar, "&nickName=").concat(res.nickName, "&mobile=").concat(res.mobile);
          if (_this.type && _this.type.toLowerCase() == 'ios') {
            _this.url = "".concat(_this.$globalObj.imgApiUrl, "ulink/").concat(param);
          } else {
            if (_this.formData.dev == '1') {
              _this.urlPrefix = 'hbuilder://';
            }
            _this.url = "".concat(_this.urlPrefix).concat(param);
          }
          // this.url = `hbuilder://?alipay_user_id=${res.alipayUid}&token=${res.token}&avatar=${res.avatar}&nickName=${res.nickName}`
        }
      }).catch(function (err) {
        _this.loading = false;
        _this.isAuth = false;
      });
    }
  }
};
exports.default = _default;