import { render, staticRenderFns } from "./throughput.vue?vue&type=template&id=42e4d65b&"
import script from "./throughput.vue?vue&type=script&lang=js&"
export * from "./throughput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\iot_vehicle\\iot_frontend\\iot_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42e4d65b')) {
      api.createRecord('42e4d65b', component.options)
    } else {
      api.reload('42e4d65b', component.options)
    }
    module.hot.accept("./throughput.vue?vue&type=template&id=42e4d65b&", function () {
      api.rerender('42e4d65b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/guest/throughput.vue"
export default component.exports