"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: [],
  data: function data() {
    return {
      loading: true,
      dialogVisible: false,
      detailTable: [{
        label: '经度',
        value: '2409100001'
      }, {
        label: '纬度',
        value: '2409100001'
      }, {
        label: '海拔',
        value: '2409100001'
      }, {
        label: '速度',
        value: '2409100001'
      }, {
        label: '方向',
        value: '2409100001'
      }, {
        label: '时间',
        value: '2409100001'
      }],
      form: {
        username: '',
        pwd: '',
        confirmPwd: '',
        role_id: null,
        mobile: '',
        bindDate: ''
      },
      terminalStateList: [{
        id: 1,
        label: '运营中'
      }, {
        id: 2,
        label: '库存'
      }, {
        id: 3,
        label: '测试中'
      }, {
        id: 4,
        label: '维护中'
      }]
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.$nextTick(function () {});
      } else {}
    }
  },
  methods: {}
};
exports.default = _default;