"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.sMerchantId === 0 ? _c("el-form-item", {
    attrs: {
      label: "商户名称"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择商户名称",
      clearable: ""
    },
    model: {
      value: _setup.merchantId,
      callback: function callback($$v) {
        _setup.merchantId = $$v;
      },
      expression: "merchantId"
    }
  }, _vm._l(_setup.merchantList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.username,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;