"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商户名称",
      prop: "role_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.role_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role_id", $$v);
      },
      expression: "form.role_id"
    }
  }, _vm._l(_vm.terminalStateList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "网点名称",
      prop: "role_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.role_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role_id", $$v);
      },
      expression: "form.role_id"
    }
  }, _vm._l(_vm.terminalStateList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "换电柜编号",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "电池编号",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "终端类型",
      prop: "role_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.role_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role_id", $$v);
      },
      expression: "form.role_id"
    }
  }, _vm._l(_vm.terminalStateList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "确认状态",
      prop: "role_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.role_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role_id", $$v);
      },
      expression: "form.role_id"
    }
  }, _vm._l(_vm.terminalStateList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "电池类型",
      prop: "role_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.role_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role_id", $$v);
      },
      expression: "form.role_id"
    }
  }, _vm._l(_vm.terminalStateList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "电池状态",
      prop: "role_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.role_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role_id", $$v);
      },
      expression: "form.role_id"
    }
  }, _vm._l(_vm.terminalStateList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "满电电压(V)"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "充电电流(A)"
    }
  }, [_c("el-input-number", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "剩余电量",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "bms账号",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "bms密码",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;