"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tab-title-page p_list"
  }, [_c("el-tabs", {
    staticClass: "flex-1 nav_tabs",
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabList, function (item) {
    return _c("el-tab-pane", {
      key: item.id,
      attrs: {
        name: item.id + ""
      }
    }, [_c("p", {
      staticClass: "text-center",
      staticStyle: {
        "line-height": "20px"
      },
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_c("span", [_vm._v(_vm._s(item.name))]), _c("br"), _c("span", [_vm._v("(" + _vm._s(item.title) + ")")])])]);
  }), 1), _c("el-divider", {
    staticClass: "middle_divider"
  }), _c("div", {
    staticClass: "child_page"
  }, [_c("div", {
    staticClass: "common_form_search"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.searchForm
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商品名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入商品名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.goodsName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "goodsName", $$v);
      },
      expression: "searchForm.goodsName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "供应商"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入供应商",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.shopName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "shopName", $$v);
      },
      expression: "searchForm.shopName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "商品品类"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择商品品类",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.categoryId,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "categoryId", $$v);
      },
      expression: "searchForm.categoryId"
    }
  }, _vm._l(_vm.categoryList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "全部",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "status", $$v);
      },
      expression: "searchForm.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "上架推广",
      value: "2"
    }
  }), _c("el-option", {
    attrs: {
      label: "下架推广",
      value: "1"
    }
  })], 1)], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.search();
      }
    }
  }, [_vm._v("查询")]), _c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.reset();
      }
    }
  }, [_vm._v("重置")])], 1)], 1)], 1), _c("el-divider"), _c("div", {
    staticStyle: {
      padding: "0 30px"
    }
  }, [_c("div", {
    staticClass: "flex align-center table-top-btns"
  }, [_c("h5", {
    staticClass: "flex align-center"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      "icon-class": "list",
      size: 15
    }
  }), _vm._v("数据列表\n          ")], 1), _vm.$hasMethod("#add") ? _c("el-button", {
    staticClass: "add-btn",
    attrs: {
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.openAdd();
      }
    }
  }, [_vm._v("添加商品")]) : _vm._e()], 1), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      label: "排序",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!_vm.$hasMethod("#sort") ? _c("span", [_vm._v(_vm._s(scope.row.sort))]) : _c("el-input", {
          attrs: {
            oninput: "value=value.replace(/[^\\d]/g,'')",
            maxLength: "9"
          },
          on: {
            change: function change($event) {
              return _vm.changeSort(scope.row.id, scope.row.sort);
            }
          },
          model: {
            value: scope.row.sort,
            callback: function callback($$v) {
              _vm.$set(scope.row, "sort", $$v);
            },
            expression: "scope.row.sort"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "商品信息",
      width: "500"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticClass: "flex"
        }, [_c("el-image", {
          staticStyle: {
            width: "60px",
            height: "60px"
          },
          attrs: {
            src: scope.row.imgurl ? _vm.$globalObj.imgBaseUrl + scope.row.imgurl : ""
          }
        }, [_c("div", {
          staticClass: "image-slot",
          attrs: {
            slot: "error"
          },
          slot: "error"
        }, [_c("i", {
          staticClass: "el-icon-picture-outline"
        })])]), _c("div", {
          staticClass: "flex-1 text-left",
          staticStyle: {
            "padding-left": "15px"
          }
        }, [_c("p", {
          staticClass: "color-999"
        }, [_vm._v(_vm._s(scope.row.goods_id))]), _c("p", {
          staticClass: "blue-font"
        }, [_vm._v(_vm._s(scope.row.title))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "category_name",
      label: "品类",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "shelves_time",
      label: "上架时间",
      align: "center",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "shopname",
      label: "供应商",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.$hasMethod("#enable") ? _c("el-button", {
          attrs: {
            type: "warning",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.changeStatus(scope.row.id, scope.row.status);
            }
          }
        }, [_vm._v(_vm._s(scope.row.status == 2 ? "下架推广" : "上架推广"))]) : _vm._e(), _vm.$hasMethod("#enable") ? _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.delItem(scope.row.id);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }])
  })], 1), _c("custom-page", {
    ref: "customPage",
    attrs: {
      total: _vm.total
    },
    on: {
      getList: _vm.getList
    }
  })], 1)], 1), _c("goods-dialog", {
    ref: "goodsDialog",
    attrs: {
      "cate-id": _vm.activeName
    },
    on: {
      getList: _vm.getList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;