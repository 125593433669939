"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _goods = require("@/api/goods");
var _default = {
  components: {
    customPage: _customPage.default
  },
  props: ['goodsId'],
  data: function data() {
    return {
      tableData: [],
      goodsName: "",
      pact_type: '',
      specsColorIndex: 0,
      specsColorList: ['#409EFF', '#67C23A', '#E6A23C', '#F56C6C', '#909399', '#414dff', '#3bc256', '#e6b539', '#f58968', '#8d7f99', '#7e3eff', '#3ac299', '#cae63b', '#f59162', '#8c7289'],
      specsTypeList: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      (0, _goods.getGoodsSetmealList)(this.goodsId).then(function (res) {
        _this.goodsName = res.goods_name;
        _this.pact_type = res.pact_type;
        _this.tableData = res.setmeal || [];
        _this.$route.meta.title = _this.goodsName + '的套餐信息';
      });
    },
    editSort: function editSort(item, e) {
      var params = {
        setmeal_id: item.id,
        sort: e
      };
      this.editGoodsSetmealStatus(params);
    },
    editStatus: function editStatus(item) {
      var status = item.meal_status == 0 ? 1 : 0;
      var params = {
        setmeal_id: item.id,
        meal_status: status
      };
      this.editGoodsSetmealStatus(params);
    },
    editGoodsSetmealStatus: function editGoodsSetmealStatus(params) {
      var _this2 = this;
      (0, _goods.editGoodsSetmealStatus)(params).then(function (res) {
        _this2.getList();
      }).catch();
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this3 = this;
      this.$confirm('您确定要删除该套餐信息吗？', '删除套餐信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _goods.deleteGoodsSetmeal)({
          goods_id: _this3.goodsId,
          setmeal_id: id
        }).then(function (res) {
          _this3.getList();
        });
      });
    },
    setSpecsColor: function setSpecsColor(val) {
      var color;
      var index = this.specsTypeList.indexOf(val);
      if (index == -1) {
        color = this.specsColorList[this.specsColorIndex++];
        this.specsTypeList.push(val);
        this.specsColorIndex = this.specsColorIndex % this.specsColorList.length;
      } else {
        index = index % this.specsColorList.length;
        color = this.specsColorList[index];
      }
      return color;
    }
  }
};
exports.default = _default;