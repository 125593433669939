"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _marketing.buyLog)({
        page: page,
        pageSize: pageSize,
        service_type: 2
      }).then(function (res) {
        var data = res.data,
          total = res.total;
        _this.tableData = data || [];
        _this.total = total || 0;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;