"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.sort");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "footer-page"
  }, [_c("el-form", {
    ref: "articleForm",
    staticClass: "demo-articleForm",
    attrs: {
      model: _vm.articleForm,
      rules: _vm.rules,
      "label-width": "130px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "排序："
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "600px"
    },
    attrs: {
      maxlength: "2"
    },
    model: {
      value: _vm.articleForm.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "sort", $$v);
      },
      expression: "articleForm.sort"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "文章标题：",
      prop: "article_title"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "600px"
    },
    attrs: {
      maxlength: "50",
      "show-word-limit": "",
      clearable: ""
    },
    model: {
      value: _vm.articleForm.article_title,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "article_title", $$v);
      },
      expression: "articleForm.article_title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "文章关键字：",
      prop: "keword"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "600px"
    },
    attrs: {
      maxlength: "20",
      "show-word-limit": "",
      clearable: ""
    },
    model: {
      value: _vm.articleForm.keword,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "keword", $$v);
      },
      expression: "articleForm.keword"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "文章栏目",
      required: ""
    }
  }, [_c("el-form-item", {
    staticStyle: {
      float: "left",
      "margin-right": "10px"
    },
    attrs: {
      prop: "parentid"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "一级分类"
    },
    on: {
      change: _vm.changeLevel1
    },
    model: {
      value: _vm.articleForm.parentid,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "parentid", $$v);
      },
      expression: "articleForm.parentid"
    }
  }, _vm._l(_vm.list_Level1, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.type_name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      float: "left"
    },
    attrs: {
      prop: "type_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "二级分类"
    },
    model: {
      value: _vm.articleForm.type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "type_id", $$v);
      },
      expression: "articleForm.type_id"
    }
  }, _vm._l(_vm.list_Level2, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.type_name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "文章缩略图：",
      prop: "article_img"
    }
  }, [_c("crop-upload", {
    attrs: {
      "init-val": _vm.articleForm.article_img,
      "always-crop": true,
      fixedBox: false,
      "wrapper-width": "200px",
      "wrapper-height": "150px",
      cropperWidth: "600px",
      cropperHeight: "450px",
      autoCropWidth: 200,
      autoCropHeight: 150,
      dialogWidth: "640px",
      tip: "上传图片将进行裁剪，建议图片尺寸200*150px以上。"
    },
    on: {
      setVal: function setVal(val) {
        _vm.articleForm.article_img = val;
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "文章摘要：",
      prop: "abstract"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "600px"
    },
    attrs: {
      type: "textarea",
      placeholder: "请输入文章摘要",
      maxlength: "100",
      "show-word-limit": "",
      resize: "none"
    },
    model: {
      value: _vm.articleForm.abstract,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "abstract", $$v);
      },
      expression: "articleForm.abstract"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      height: "280px"
    },
    attrs: {
      label: "文章内容：",
      prop: "content"
    }
  }, [_c("text-editor", {
    staticStyle: {
      width: "800px"
    },
    attrs: {
      content: _vm.articleForm.content
    },
    on: {
      onEditorChange: _vm.onEditorChange
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "状态：",
      prop: "status"
    }
  }, [_c("el-radio-group", {
    on: {
      change: _vm.changeRadio
    },
    model: {
      value: _vm.articleForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "status", $$v);
      },
      expression: "articleForm.status"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    },
    model: {
      value: _vm.articleForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "status", $$v);
      },
      expression: "articleForm.status"
    }
  }, [_vm._v("已发布")]), _c("el-radio", {
    attrs: {
      label: 2
    },
    model: {
      value: _vm.articleForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "status", $$v);
      },
      expression: "articleForm.status"
    }
  }, [_vm._v("未发布")])], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "推荐：",
      prop: "recommend"
    }
  }, [_c("el-checkbox-group", {
    on: {
      change: _vm.changeCheckBox
    },
    model: {
      value: _vm.articleForm.recommend,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "recommend", $$v);
      },
      expression: "articleForm.recommend"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: "home"
    }
  }, [_vm._v("首页推荐")]), _c("el-checkbox", {
    attrs: {
      label: "hot"
    }
  }, [_vm._v("热门资讯")])], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "展示平台：",
      prop: "platform"
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.articleForm.platform,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "platform", $$v);
      },
      expression: "articleForm.platform"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: "pc"
    }
  }, [_vm._v("PC商城")]), _c("el-checkbox", {
    attrs: {
      label: "h5"
    }
  }, [_vm._v("H5商城")]), _c("el-checkbox", {
    attrs: {
      label: "xcx"
    }
  }, [_vm._v("支付宝小程序")])], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "作者：",
      prop: "author"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      maxlength: "20"
    },
    model: {
      value: _vm.articleForm.author,
      callback: function callback($$v) {
        _vm.$set(_vm.articleForm, "author", $$v);
      },
      expression: "articleForm.author"
    }
  })], 1)], 1), _c("div", {
    staticClass: "page-footer-btns"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.resetForm("articleForm");
      }
    }
  }, [_vm._v("重置")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("articleForm");
      }
    }
  }, [_vm._v("发布")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;