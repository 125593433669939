"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "icon_form"
  }, [_c("div", {
    staticClass: "flex flex-between-center header"
  }, [_c("span", {
    staticStyle: {
      color: "#666666"
    }
  }, [_vm._v("图标" + _vm._s(_vm.itemIndex + 1))]), _c("p", {
    staticClass: "tools"
  }, [_c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("upSortItem");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-top"
  })]), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("downSortItem");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-bottom"
  })]), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("handleDelete");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-delete"
  })]), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("handleAdd");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })])])]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("crop-upload", {
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.img,
      "wrapper-width": "88px",
      "wrapper-height": "88px",
      "photo-width": 88,
      "photo-height": 88,
      "img-width": _vm.imgWidth,
      "img-height": _vm.imgHeight
    },
    on: {
      setVal: function setVal(val) {
        _vm.formData.img = val;
      }
    }
  }), _c("div", {
    staticClass: "flex-1 input_wrapper"
  }, [_c("div", {
    staticClass: "flex name"
  }, [_vm._m(0), _c("el-input", {
    attrs: {
      placeholder: "请输入图标名称",
      maxlength: "6",
      clearable: ""
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1)])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("label", {
    staticClass: "flex align-center justify-center"
  }, [_c("span", {
    staticClass: "required-icon"
  }, [_vm._v("*")]), _vm._v("名称")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;