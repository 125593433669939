"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog"));
var _shop = require("@/api/shop");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    goodsDialog: _goodsDialog.default,
    cropUpload: _cropUpload.default
  },
  props: ["editId"],
  data: function data() {
    return {
      dialogVisible: false,
      urlName: "",
      imgurl: '',
      form: {
        banner_img: "",
        goods_id: ""
      },
      rules: {
        banner_img: [{
          required: true,
          message: '请上传轮播图',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    setUrl: function setUrl(val) {
      this.form.banner_img = val;
    },
    // 提交
    submit: function submit() {
      var _this = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var params = (0, _objectSpread2.default)({}, _this.form);
          if (_this.editId) {
            params.id = _this.editId;
          }
          (0, _shop.addOrEdiShopBanner)(params).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit("getList");
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    },
    // 设置选中的商品
    setSelect: function setSelect(goodsList) {
      var obj = goodsList[0];
      this.form.goods_id = obj.id;
      this.urlName = obj.title;
      this.imgurl = obj.imgurl;
    },
    // 打开商品选择对话框
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
      this.$refs.goodsDialog.selectItems = this.form.goods_id ? [{
        id: this.form.goods_id,
        title: this.urlName,
        imgurl: this.imgurl
      }] : [];
    },
    reset: function reset() {
      var _this2 = this;
      this.urlName = "";
      this.imgurl = "";
      this.form = {
        banner_img: "",
        goods_id: ""
      };
      this.$nextTick(function () {
        _this2.$refs['form'].resetFields();
      });
    },
    // 获取轮播图数据
    getData: function getData() {
      var _this3 = this;
      (0, _shop.shopBannerById)(this.editId).then(function (res) {
        _this3.form.banner_img = res.banner_img;
        _this3.form.goods_id = res.goods_id;
        _this3.urlName = res.title;
        _this3.imgurl = res.imgurl;
      });
    }
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editId ? "编辑轮播图" : "添加轮播图";
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.reset();
        if (this.editId) {
          this.getData();
        }
      }
    }
  }
};
exports.default = _default;