"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tab-title-page"
  }, [_c("div", {
    staticClass: "nav_tabs"
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        label: item.label,
        name: item.name
      }
    });
  }), 1)], 1), _c("el-divider", {
    staticClass: "middle_divider"
  }), _c("div", {
    staticClass: "child_page"
  }, [_c(_vm.activeName, {
    tag: "component"
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;