"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
var _default = {
  props: ['editItem'],
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (value && !(0, _validate.checkEmail)(value)) {
        callback(new Error('邮箱格式错误'));
      } else {
        callback();
      }
    };
    return {
      form: {
        id: '',
        email: '',
        real_addr: '',
        goods_colour: ''
      },
      rules: {
        email: [{
          required: true,
          message: '电子邮件不能为空',
          trigger: 'blur'
        }, {
          validator: validateEmail,
          trigger: 'blur'
        }],
        real_addr: [{
          required: true,
          message: '真实地址不能为空',
          trigger: 'blur',
          type: 'string'
        }],
        goods_colour: [{
          required: true,
          message: '商品颜色不能为空',
          trigger: 'blur',
          type: 'string'
        }]
      },
      dialogVisible: false,
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      }
    }
  },
  methods: {
    init: function init() {
      this.form = this.editItem;
    },
    submit: function submit() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          console.log('当前内容', _this.form);
          var params = _this.form;
          (0, _user.editMemberInfo)(params).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};
exports.default = _default;