"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "查看物流信息",
      visible: _vm.dialogVisible,
      width: "1100px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "商家发货物流",
      name: "supplier"
    }
  }, [_c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _c("div", {
    staticClass: "flex flex-1 tip"
  }, [_c("p", {
    staticClass: "flex-1"
  }, [_vm._v("\n                        1、可上传PDF、JPG、PNG等格式文件，单个文件最大限制10M；\n                        "), _c("br"), _vm._v("2、用户发生租赁违约时，物流发货凭证和用户签收凭证可做法律依据。添加后不可进行删除，请谨慎操作。\n                    ")]), _c("p", [_vm._v(" 点击下载"), _c("a", {
    staticClass: "blue-font",
    attrs: {
      href: "https://image-xxz.oss-cn-beijing.aliyuncs.com/download/%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A1%B9.doc"
    }
  }, [_vm._v("发货凭证")]), _vm._v("示例 ")]), _c("p", [_vm._v(" 点击下载"), _c("a", {
    staticClass: "blue-font",
    attrs: {
      href: "https://image-xxz.oss-cn-beijing.aliyuncs.com/download/%E6%9C%AC%E4%BA%BA%E7%AD%BE%E6%94%B6%E5%87%AD%E8%AF%81%E6%A0%B7%E5%BC%8F%E8%A1%A8.doc"
    }
  }, [_vm._v("用户签收凭证")]), _vm._v("示例 ")])])], 1)]), _c("el-tab-pane", {
    attrs: {
      label: "用户寄回物流",
      name: "user"
    }
  })], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "content"
  }, [_c("p", [_vm._v("物流信息")]), _c("div", {
    staticClass: "flex info"
  }, [_c("p", {
    staticClass: "flex-1"
  }, [_c("label", [_vm._v("物流名称：")]), _vm._v(_vm._s(_vm.infoData.delivery_type == 1 ? _vm.infoData.logistics_name || "无" : _vm.infoData.delivery_type == 2 ? "上门自取" : "") + "\n                "), _vm.infoData.logistics_name && _vm.$hasMethod("#editLogistics") ? _c("el-button", {
    staticClass: "editBtn",
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.editFlow("name");
      }
    }
  }, [_vm._v("修改")]) : _vm._e()], 1), _c("p", {
    staticClass: "flex-1"
  }, [_c("label", [_vm._v("物流单号:")]), _vm._v(_vm._s(_vm.infoData.logistics_no ? _vm.infoData.logistics_no : "无") + "\n                "), _vm.infoData.logistics_no && _vm.$hasMethod("#editLogistics") ? _c("el-button", {
    staticClass: "editBtn",
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.editFlow("no");
      }
    }
  }, [_vm._v("修改")]) : _vm._e()], 1), _c("p", {
    staticClass: "flex-1"
  }, [_c("label", [_vm._v("供应商价格:")]), _vm.$hasMethod("#editLogistics") ? _c("el-button", {
    staticClass: "editBtn",
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.editCostPrice();
      }
    }
  }, [_vm.infoData.cost_price > 0 ? _c("span", [_vm._v("查看")]) : _c("samp", [_vm._v("修改")])]) : _vm._e()], 1), _vm.activeName === "supplier" ? _c("p", {
    staticClass: "flex-1"
  }, [_c("label", [_vm._v("收货类型：")]), _vm._v(_vm._s(_vm.infoData.delivery_type == 1 ? "商家确认收货" : "无") + " ")]) : _vm._e()]), _vm.activeName === "supplier" ? _c("div", {
    staticClass: "flex certify"
  }, [_c("div", {
    staticClass: "flex-1 flex flex-center"
  }, [_c("label", [_vm._v("发货凭证：")]), _c("div", {
    staticClass: "flex-1"
  }, [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "hide-delete": true,
      "init-val": _vm.infoData.images,
      "wrapper-width": "100px",
      "wrapper-height": "100px",
      "photo-width": 100,
      "photo-height": 100
    },
    on: {
      setVal: _vm.imgChange
    }
  })], 1)]), _c("div", {
    staticClass: "flex-1 flex flex-center"
  }, [_c("label", [_vm._v("用户签收凭证：")]), _c("div", {
    staticClass: "flex-1"
  }, [_c("crop-upload", {
    ref: "cropUpload1",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "hide-delete": true,
      "init-val": _vm.infoData.sign_proof,
      "wrapper-width": "100px",
      "wrapper-height": "100px",
      "photo-width": 100,
      "photo-height": 100
    },
    on: {
      setVal: _vm.proofChange
    }
  })], 1)])]) : _vm._e(), _vm.activeName === "supplier" ? _c("div", {
    staticClass: "flex certify"
  }, [_c("div", {
    staticClass: "flex-1 flex flex-center"
  }, [_c("label", [_vm._v("物权证明：")]), _c("div", {
    staticClass: "flex-1"
  }, [_c("crop-upload", {
    ref: "pti",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "hide-delete": true,
      "init-val": _vm.infoData.proof_title_img,
      "wrapper-width": "100px",
      "wrapper-height": "100px",
      "photo-width": 100,
      "photo-height": 100
    },
    on: {
      setVal: _vm.handleProofTitleImg
    }
  })], 1)]), _c("div", {
    staticClass: "flex-1 flex flex-center"
  }, [_c("label", [_vm._v("催收短信截图：")]), _c("div", {
    staticClass: "flex-1"
  }, [_c("crop-upload", {
    ref: "sci",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "hide-delete": true,
      "init-val": _vm.infoData.sms_comp_img,
      "wrapper-width": "100px",
      "wrapper-height": "100px",
      "photo-width": 100,
      "photo-height": 100
    },
    on: {
      setVal: _vm.handleSMSCompImg
    }
  })], 1)])]) : _vm._e(), _vm.activeName === "supplier" ? _c("div", {
    staticClass: "flex certify"
  }, [_c("div", {
    staticClass: "flex-1 flex flex-center"
  }, [_c("label", [_vm._v("代偿通知书：")]), _c("div", {
    staticClass: "flex-1"
  }, [_c("crop-upload", {
    ref: "nci",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "hide-delete": true,
      "init-val": _vm.infoData.notice_comp_img,
      "wrapper-width": "100px",
      "wrapper-height": "100px",
      "photo-width": 100,
      "photo-height": 100
    },
    on: {
      setVal: _vm.handleNoticeCompImg
    }
  })], 1)]), _c("div", {
    staticClass: "flex-1 flex flex-center"
  }, [_c("label", [_vm._v("代偿证明：")]), _c("div", {
    staticClass: "flex-1"
  }, [_c("crop-upload", {
    ref: "pfi",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "hide-delete": true,
      "init-val": _vm.infoData.proof_com_img,
      "wrapper-width": "100px",
      "wrapper-height": "100px",
      "photo-width": 100,
      "photo-height": 100
    },
    on: {
      setVal: _vm.handleProofComImg
    }
  })], 1)])]) : _vm._e(), _c("p", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("物流记录")]), _c("div", {
    staticClass: "flow_table"
  }, [_c("div", {
    staticClass: "flex header"
  }, [_c("p", {
    staticClass: "text-center",
    staticStyle: {
      width: "250px"
    }
  }, [_vm._v("时间")]), _c("p", {
    staticClass: "text-center flex-1"
  }, [_vm._v("物流信息")])]), _c("div", {
    staticClass: "flow_info"
  }, _vm._l(_vm.dataList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "flex"
    }, [_c("p", {
      staticStyle: {
        width: "185px"
      }
    }, [_vm._v(_vm._s(item.time))]), _c("span", {
      staticClass: "circle"
    }), _c("p", {
      staticClass: "flex-1"
    }, [_vm._v(_vm._s(item.context))])]);
  }), 0)])]), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1), _c("el-dialog", {
    attrs: {
      width: "500px",
      title: _vm.innerTitle,
      visible: _vm.innerVisible,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.innerVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.folwForm
    }
  }, [_vm.editCode == "logistics_name" ? _c("el-form-item", {
    attrs: {
      label: "物流名称：",
      "label-width": "120px"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.folwForm.logistics_name,
      callback: function callback($$v) {
        _vm.$set(_vm.folwForm, "logistics_name", $$v);
      },
      expression: "folwForm.logistics_name"
    }
  }, _vm._l(_vm.logisticsNames, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e(), _vm.editCode == "logistics_no" ? _c("el-form-item", {
    attrs: {
      label: "物流单号：",
      "label-width": "120px"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "20"
    },
    model: {
      value: _vm.folwForm.logistics_no,
      callback: function callback($$v) {
        _vm.$set(_vm.folwForm, "logistics_no", $$v);
      },
      expression: "folwForm.logistics_no"
    }
  })], 1) : _vm._e(), _vm.editCode == "cost_price" ? _c("el-form-item", {
    attrs: {
      label: "供应商价格：",
      "label-width": "120px"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "20"
    },
    model: {
      value: _vm.folwForm.cost_price,
      callback: function callback($$v) {
        _vm.$set(_vm.folwForm, "cost_price", $$v);
      },
      expression: "folwForm.cost_price"
    }
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.cancelEdit
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.editFlowInfo
    }
  }, [_vm._v("确 定")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;