"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderRefund = require("@/api/orderRefund");
var _default = {
  props: ["refundId", "orderId", "refundStatus", 'type'],
  data: function data() {
    return {
      dialogTitle: "同意退款",
      dialogVisible: false,
      isSuccess: true,
      agreeLoading: false
    };
  },
  methods: {
    close: function close() {
      this.dialogVisible = false;
      this.agreeLoading = false;
    },
    submitRefund: function submitRefund() {
      var _this = this;
      this.agreeLoading = true;
      (0, _orderRefund.shopCheckPass)(this.refundId).then(function (res) {
        _this.close();
        _this.$parent.getList();
      }).catch(function (err) {
        if (err.status == 101) {
          _this.isSuccess = false;
        }
        _this.agreeLoading = false;
      });
    },
    topUp: function topUp() {
      var routeData = this.$router.resolve({
        name: "recharge"
      });
      window.open(routeData.href, '_blank');
    },
    toDetails: function toDetails() {
      this.dialogVisible = false;
      var id = this.orderId;
      this.$router.push({
        name: 'refundDetail',
        params: {
          id: id
        }
      });
    }
  },
  mounted: function mounted() {}
};
exports.default = _default;