"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex flex-between-center navbar"
  }, [_c("span", {
    staticClass: "logo_wrapper"
  }), _c("p", {
    staticClass: "horizontal_menu"
  }, [_vm._l(_vm.menuList, function (m, index) {
    return [_vm.screenWidth >= 1612 || index < 6 ? _c("span", {
      key: m.id,
      staticClass: "pointer",
      class: {
        active_menu: _vm.activeParentNav.id == m.id
      },
      on: {
        click: function click($event) {
          return _vm.goToPage(m.routes);
        }
      }
    }, [_vm._v(_vm._s(m.title))]) : _vm._e()];
  }), _vm.screenWidth < 1612 && _vm.menuList.length > 6 ? _c("el-dropdown", {
    staticClass: "navbar_drop"
  }, [_c("span", {
    staticClass: "el-dropdown-link pointer"
  }, [_vm._v(" 更多"), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm._l(_vm.menuList, function (m, index) {
    return [index >= 6 ? _c("el-dropdown-item", {
      key: m.id,
      staticClass: "navbar_drop_item",
      class: {
        active_menu: _vm.activeParentNav.id == m.id
      },
      nativeOn: {
        click: function click($event) {
          return _vm.goToPage(m.routes);
        }
      }
    }, [_vm._v(_vm._s(m.title))]) : _vm._e()];
  })], 2)], 1) : _vm._e()], 2), _c("div", {
    staticClass: "flex right-menu"
  }, [_c("div", {
    staticClass: "flex align-center user_info",
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_c("el-avatar", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      size: "medium",
      src: _vm.avatar ? _vm.$globalObj.imgBaseUrl + _vm.avatar : ""
    }
  }), _c("p", {
    staticStyle: {
      "line-height": "1.25"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.account))]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.userName))])])], 1), _c("el-dropdown", {
    staticClass: "avatar-container",
    attrs: {
      trigger: "click"
    }
  }, [_c("span", {
    staticClass: "pointer"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "exit",
      size: 14
    }
  })], 1), _c("el-dropdown-menu", {
    staticClass: "user-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("router-link", {
    attrs: {
      to: "/editPwd"
    }
  }, [_c("el-dropdown-item", [_vm._v("修改密码")])], 1), _c("el-dropdown-item", {
    attrs: {
      divided: ""
    },
    nativeOn: {
      click: function click($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    }
  }, [_vm._v("退出")])])], 1)], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;