"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _default = {
  props: ['orderNo'],
  data: function data() {
    return {
      dialogVisible: false,
      tableData: []
    };
  },
  methods: {},
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        (0, _order.getLeaseFlow)(this.orderNo).then(function (res) {
          _this.tableData = res || [];
        });
      }
    }
  }
};
exports.default = _default;