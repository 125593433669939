"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "banner_col"
  }, [_c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _vm._m(0)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, _vm._l(_vm.carousel, function (item, index) {
    return _c("el-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("banner-form", {
      attrs: {
        type: "bannerCol",
        "item-index": index,
        "form-data": item,
        "show-tool": true
      },
      on: {
        upSortItem: function upSortItem($event) {
          return _vm.upSortItem(index);
        },
        downSortItem: function downSortItem($event) {
          return _vm.downSortItem(index);
        },
        deleteItem: function deleteItem($event) {
          return _vm.deleteItem(index);
        }
      }
    })], 1);
  }), 1), _vm.carousel.length < 5 ? _c("div", {
    staticClass: "add-div",
    on: {
      click: _vm.addBanner
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v("添加图片(" + _vm._s(_vm.carousel.length) + "/5)\n  ")]) : _vm._e(), _c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _vm._m(1)], 1), _c("col-form", {
    ref: "colForm1",
    attrs: {
      title: "二栏式1",
      type: "bannerCol",
      "content-type": "img",
      "form-data": _vm.configData[0]
    }
  }), _c("col-form", {
    ref: "colForm2",
    attrs: {
      title: "二栏式2",
      type: "bannerCol",
      "content-type": "img",
      "form-data": _vm.configData[1]
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", [_vm._v("图片要求！")]), _c("br"), _vm._v("轮播图图片宽度335px，高度500px")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", [_vm._v("图片要求！")]), _c("br"), _vm._v("图片背景宽度335px，高度240px")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;