"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-scrollbar", {
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_c("el-menu", {
    attrs: {
      "background-color": "#28313d",
      "default-active": _vm.activeMenu,
      collapse: false,
      "unique-opened": false,
      "active-text-color": _vm.variables.menuActiveText,
      "collapse-transition": false,
      mode: "vertical"
    }
  }, [_vm._l(_vm.permission_routes, function (route) {
    return [_vm.hasRoutes.includes(route.name) ? _c("sidebar-item", {
      key: route.path,
      attrs: {
        item: route,
        "base-path": route.path
      }
    }) : _vm._e()];
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;