"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "details-page footer-page"
  }, [_c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("账号信息 - " + _vm._s(_vm.viewTitle))])]), _c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.id > 0 ? _vm.rules1 : _vm.rules,
      model: _vm.form,
      "label-width": "100px"
    }
  }, [_c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("div", {
    staticClass: "list_item"
  }, [_c("el-form-item", {
    attrs: {
      label: "账号:",
      prop: "mobile"
    }
  }, [_vm.id == 0 ? [_c("el-input", {
    attrs: {
      maxlength: "11",
      placeholder: "请输入手机号"
    },
    on: {
      input: _vm.handleMobileChange
    },
    model: {
      value: _vm.form.mobile,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mobile", $$v);
      },
      expression: "form.mobile"
    }
  })] : _c("span", [_vm._v(_vm._s(_vm.form.mobile))])], 2)], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名/备注:",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      maxlength: "11",
      placeholder: "请输入用户名"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1)], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("el-form-item", {
    attrs: {
      label: "密码:",
      prop: "pwd"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: _vm.id > 0 ? "(选填)留空则不修改" : "请输入密码",
      autocomplete: "new-password",
      "show-password": ""
    },
    model: {
      value: _vm.form.pwd,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pwd", $$v);
      },
      expression: "form.pwd"
    }
  })], 1)], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("el-form-item", {
    attrs: {
      label: "重复密码:",
      prop: "confirmPwd"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: _vm.id > 0 ? "(选填)留空则不修改" : "请再次输入密码",
      autocomplete: "new-password",
      "show-password": ""
    },
    model: {
      value: _vm.form.confirmPwd,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "confirmPwd", $$v);
      },
      expression: "form.confirmPwd"
    }
  })], 1)], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("el-form-item", {
    attrs: {
      label: "角色:",
      prop: "role_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择角色"
    },
    model: {
      value: _vm.form.role_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role_id", $$v);
      },
      expression: "form.role_id"
    }
  }, _vm._l(_vm.roleList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.title,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("el-form-item", {
    attrs: {
      label: "账号状态:",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择角色"
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, _vm._l(_vm.statusList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.title,
        value: item.id
      }
    });
  }), 1)], 1)], 1)])]), _c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _c("p", [_vm._v("重要提示：商户头像、商户名称会直接展示给买家，请认真填写。")])], 1), _c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("基础信息 " + _vm._s(_vm.id > 0 ? " - \u5546\u6237id\uFF1A".concat(_vm.id) : ""))])]), _c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("商户头像：")]), _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.photo,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: !_vm.$hasMethod("#edit")
    },
    on: {
      setVal: function setVal(val) {
        _vm.imgChange(val, "photo");
      }
    }
  })], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("运营区域：")]), _c("el-cascader", {
    attrs: {
      filterable: "",
      placeholder: "运营区域",
      options: _vm.areaList,
      props: _vm.optionPropsAreaId,
      separator: "-"
    },
    on: {
      change: _vm.changeArea
    },
    model: {
      value: _vm.infoData.area_id,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "area_id", $$v);
      },
      expression: "infoData.area_id"
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("商户名称：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.shopname,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "shopname", $$v);
      },
      expression: "infoData.shopname"
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }), _vm.id > 0 ? _c("div", {
    staticClass: "list_item flex-1"
  }, [_c("label", [_vm._v("经营项目：")]), _c("div", _vm._l(_vm.infoData.category_list, function (category, index) {
    return _c("div", {
      key: index
    }, [_c("span", {
      staticClass: "category-title"
    }, [_vm._v(_vm._s(category.name) + " - ")]), _vm._l(category.children, function (item, index) {
      return _c("span", {
        key: index
      }, [_vm._v(_vm._s(item.name) + " ")]);
    })], 2);
  }), 0), _c("span", [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.openCategory();
      }
    }
  }, [_vm._v("选择")])], 1)]) : _vm._e()]), _vm._m(0), _c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("商户联系人：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.people,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "people", $$v);
      },
      expression: "infoData.people"
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("联系人手机号：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.contact_tel,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "contact_tel", $$v);
      },
      expression: "infoData.contact_tel"
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("商户客服电话：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    on: {
      input: function input(val) {
        return _vm.changeNumberInput(val);
      }
    },
    model: {
      value: _vm.infoData.service_tel,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "service_tel", $$v);
      },
      expression: "infoData.service_tel"
    }
  })], 1)])]), _vm._m(1), _c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("银行卡账号：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.alipayaccount,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "alipayaccount", $$v);
      },
      expression: "infoData.alipayaccount"
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("收款人姓名：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.payeename,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "payeename", $$v);
      },
      expression: "infoData.payeename"
    }
  })], 1)])]), _vm._m(2), _c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("公司名称：")]), _c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.company,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "company", $$v);
      },
      expression: "infoData.company"
    }
  })], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("工商注册号：")]), _c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.companyid,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "companyid", $$v);
      },
      expression: "infoData.companyid"
    }
  })], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("公司所在区域：")]), _c("el-cascader", {
    attrs: {
      filterable: "",
      placeholder: "请选择公司所在区域",
      options: _vm.regionList,
      props: _vm.optionProps,
      separator: "-"
    },
    model: {
      value: _vm.companyArea,
      callback: function callback($$v) {
        _vm.companyArea = $$v;
      },
      expression: "companyArea"
    }
  })], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("公司地址：")]), _c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.company_addr,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "company_addr", $$v);
      },
      expression: "infoData.company_addr"
    }
  })], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("营业执照：")]), _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.shopcard,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: !_vm.$hasMethod("#edit")
    },
    on: {
      setVal: function setVal(val) {
        _vm.imgChange(val, "shopcard");
      }
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("公司门头照：")]), _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.doorphoto,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: !_vm.$hasMethod("#edit")
    },
    on: {
      setVal: function setVal(val) {
        _vm.imgChange(val, "doorphoto");
      }
    }
  })], 1)])]), _vm._m(3), _c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("法人姓名：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.faname,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "faname", $$v);
      },
      expression: "infoData.faname"
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("法人身份证号：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.idcard,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "idcard", $$v);
      },
      expression: "infoData.idcard"
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("法人手机号：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.fa_tel,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "fa_tel", $$v);
      },
      expression: "infoData.fa_tel"
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("法人邮箱：")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.infoData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.infoData, "email", $$v);
      },
      expression: "infoData.email"
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("身份证正面照：")]), _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.faidcard,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: !_vm.$hasMethod("#edit")
    },
    on: {
      setVal: function setVal(val) {
        _vm.imgChange(val, "faidcard");
      }
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("身份证背面照：")]), _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.faidcard_back,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: !_vm.$hasMethod("#edit")
    },
    on: {
      setVal: function setVal(val) {
        _vm.imgChange(val, "faidcard_back");
      }
    }
  })], 1)]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("法人身份证明书：")]), _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.fa_legal_img,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: !_vm.$hasMethod("#edit")
    },
    on: {
      setVal: function setVal(val) {
        _vm.imgChange(val, "fa_legal_img");
      }
    }
  })], 1)])]), _vm.id > 0 ? _c("div", {
    staticClass: "verify-info"
  }, [_vm._m(4), _c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("法人认证：")]), _c("p", [_vm._v(_vm._s(_vm.infoData.esign_psn_id ? "是" : "否"))]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.getEsignPsnId
    }
  }, [_vm._v("获取认证")])], 1), _c("div", {
    staticClass: "list_item flex align-center"
  }, [_c("label", [_vm._v("法人认证链接：")]), _c("el-link", {
    staticClass: "link",
    attrs: {
      href: _vm.psnAuthUrl,
      target: "_blank",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.psnAuthUrl))])], 1), _c("div", {
    staticClass: "list_item flex align-center"
  }, [_c("label", [_vm._v("企业认证：")]), _c("p", [_vm._v(_vm._s(_vm.infoData.esign_org_id ? "是" : "否"))]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.getEsignOrgId
    }
  }, [_vm._v("获取认证")])], 1), _c("div", {
    staticClass: "list_item flex align-center"
  }, [_c("label", [_vm._v("企业认证链接：")]), _c("el-link", {
    staticClass: "link",
    attrs: {
      href: _vm.orgAuthUrl,
      target: "_blank",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.orgAuthUrl))])], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("印章授权：")]), _c("p", [_vm._v(_vm._s(_vm.infoData.esign_seal_auth ? "是" : "否"))]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.getEsignSealId
    }
  }, [_vm._v("获取授权")])], 1), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("印章授权链接：")]), _c("el-link", {
    staticClass: "link",
    attrs: {
      href: _vm.sealAuthUrl,
      target: "_blank",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.sealAuthUrl))])], 1)])]) : _vm._e(), _c("div", {
    staticClass: "page-footer-btns"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("提交")]), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v("返回")])], 1), _c("editCategory", {
    ref: "editCategory",
    attrs: {
      list: _vm.categoryList,
      "edit-item": _vm.editItem
    },
    on: {
      getList: _vm.getData
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("联系方式")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("提现资料")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("营业执照信息")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("法人证件信息")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("易签宝认证信息")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;