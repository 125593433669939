"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: {
    formData: {
      type: Object,
      default: {}
    },
    itemIndex: {
      type: Number
    }
  },
  data: function data() {
    return {};
  }
};
exports.default = _default;