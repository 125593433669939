"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.string.sub");
require("core-js/modules/es6.regexp.split");
var _system = require("@/api/system");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _editCategory = _interopRequireDefault(require("@/components/merchant/editCategory"));
var _validate = require("@/utils/validate");
var _merchant = require("@/api/merchant");
var _index = require("@/api/index");
var _auth = require("@/utils/auth");
var _info = require("@/api/info");
var _default = {
  components: {
    cropUpload: _cropUpload.default,
    editCategory: _editCategory.default
  },
  data: function data() {
    var _this = this;
    var validateMobile = function validateMobile(rule, value, callback) {
      if (!(0, _validate.checkMobile)(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    var validateConfirm = function validateConfirm(rule, value, callback) {
      if (value !== _this.form.pwd) {
        callback(new Error('两次输入密码不同！'));
      } else {
        callback();
      }
    };
    var validatePwd = function validatePwd(rule, value, callback) {
      var reg = /^[^\u4e00-\u9fa5]{0,}$/;
      if (value && value.length < 6) {
        callback(new Error('密码必须大于等于6个字符'));
      } else if (!reg.test(value)) {
        callback(new Error('密码不能包含汉字！'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      id: 0,
      infoData: {
        photo: ''
      },
      form: {
        username: '',
        pwd: '',
        confirmPwd: '',
        role_id: null,
        mobile: '',
        status: 0
      },
      roleList: [],
      psnAuthUrl: '',
      orgAuthUrl: '',
      sealAuthUrl: '',
      statusList: [{
        id: 0,
        title: '禁用'
      }, {
        id: 1,
        title: '启用'
      }],
      regionList: [],
      shopcity: '',
      companyArea: [],
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      editItem: {},
      categoryList: [],
      rules: {
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }, {
          validator: validatePwd,
          trigger: 'blur'
        }],
        confirmPwd: [{
          required: true,
          message: '重复密码不能为空',
          trigger: 'blur'
        }, {
          validator: validateConfirm,
          trigger: 'blur'
        }],
        role_id: [{
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }]
      },
      rules1: {
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          validator: validatePwd,
          trigger: 'blur'
        }],
        confirmPwd: [{
          validator: validateConfirm,
          trigger: 'blur'
        }]
        // role_id: [{ required: true, message: '请选择角色', trigger: 'change' }],
      }
    };
  },
  created: function created() {
    this.id = (0, _auth.getMerchantId)();
    if (this.id > 0) {
      this.getData();
      this.getCategoryList();
    }
    this.getProvice();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      // console.log('id', this.id)
      this.loading = true;
      (0, _merchant.getMerchantInfo)(this.id).then(function (res) {
        _this2.loading = false;
        _this2.infoData = res || {};
        if (_this2.infoData.shopcity) {
          _this2.shopcity = _this2.infoData.shopcity;
          var cityArr = _this2.infoData.shopcity.split('-');
          _this2.infoData.shopcity = cityArr[0] && cityArr[1] && cityArr[2] ? cityArr : [];
        }
        if (_this2.infoData.company_area) {
          _this2.companyArea = _this2.infoData.company_area.split('-');
        }
      });
    },
    getCategoryList: function getCategoryList() {
      var _this3 = this;
      (0, _info.categoryList)().then(function (res) {
        _this3.categoryList = res.data;
        console.log(res.data);
      });
    },
    getProvice: function getProvice() {
      var _this4 = this;
      (0, _index.getRegion)().then(function (res) {
        _this4.regionList = res ? res[0].sub : [];
      });
    },
    changeNumberInput: function changeNumberInput(value) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(value)) {
        // input 框绑定的内容为空
        this.infoData.mobile = '';
      }
    },
    openCategory: function openCategory() {
      this.editItem = {
        id: this.id,
        category_ids: this.infoData.category_ids ? this.infoData.category_ids : ''
      };
      this.$refs.editCategory.dialogVisible = true;
    },
    handleSubmitData: function handleSubmitData() {
      var data = this.infoData;
      for (var key in data) {
        if (!data[key]) {
          delete data[key];
        }
      }
      if (this.companyArea.length) {
        data.company_area = this.companyArea.join('-');
      }
      console.log('handleSubmitData', data);
      return data;
    },
    submit: function submit() {
      var _this5 = this;
      console.log('submit');
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var infoData = _this5.handleSubmitData();
          if (_this5.id > 0) {
            (0, _system.editMerchant)({
              id: _this5.form.id,
              username: _this5.form.username,
              pwd: _this5.form.pwd ? _this5.$md5(_this5.form.pwd) : '',
              role_id: _this5.form.role_id,
              status: _this5.form.status,
              infoData: infoData
            }).then(function (res) {});
          } else {
            addMerchant({
              mobile: _this5.form.mobile,
              username: _this5.form.username,
              pwd: _this5.$md5(_this5.form.pwd),
              role_id: _this5.form.role_id,
              status: _this5.form.status,
              infoData: infoData
            }).then(function (res) {
              setTimeout(function () {
                _this5.$router.back();
              }, 3000);
            });
          }
        } else {
          _this5.$message({
            message: '请检查表单错误',
            type: 'error'
          });
          return false;
        }
      });
    },
    imgChange: function imgChange(val) {
      var _this6 = this;
      if (val) {
        (0, _merchant.editMerchantInfo)({
          id: this.infoData.id,
          photo: val
        }).then(function (res) {
          _this6.infoData.photo = val;
        });
      } else {
        this.infoData.photo = val;
      }
    },
    getEsignPsnId: function getEsignPsnId() {
      var _this7 = this;
      (0, _merchant.getEsignPsnId)({
        id: this.id
      }).then(function (res) {
        console.log('res', res);
        if (res.data) {
          _this7.psnAuthUrl = res.data.authShortUrl;
        } else {
          _this7.getData();
        }
      }).catch(function (err) {});
      console.log('getEsignPsnId');
    },
    getEsignOrgId: function getEsignOrgId() {
      var _this8 = this;
      (0, _merchant.getEsignOrgId)({
        id: this.id
      }).then(function (res) {
        console.log('res', res);
        if (res.data) {
          _this8.orgAuthUrl = res.data.authShortUrl;
        } else {
          _this8.getData();
        }
      }).catch(function (err) {});
      console.log('getEsignOrgId');
    },
    getEsignSealId: function getEsignSealId() {
      var _this9 = this;
      (0, _merchant.getEsignSealId)({
        id: this.id
      }).then(function (res) {
        console.log('res', res);
        if (res.data) {
          _this9.sealAuthUrl = res.data.authorizationSignShortUrl;
        } else {
          _this9.getData();
        }
      }).catch(function (err) {});
      console.log('getEsignSealId');
    },
    handleMobileChange: function handleMobileChange() {
      this.form.mobile = this.form.mobile.replace(/[^\d]/g, '');
    }
  }
};
exports.default = _default;