"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _validate = require("@/utils/validate");
var _default = {
  props: ["edItem"],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        amount: ""
      },
      rules: {
        remark: [{
          required: true,
          message: '退款原因不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {},
  methods: {
    submit: function submit() {
      var _this = this;
      var amount = parseFloat(this.form.amount).toFixed(2);
      var moreservice_price = parseFloat(this.edItem.moreservice_price).toFixed(2);
      if (amount == 0 || amount > moreservice_price) {
        this.$message({
          message: "金额错误",
          type: "error"
        });
        return false;
      }
      (0, _order.moreServicePay)({
        id: this.edItem.id,
        amount: amount
      }).then(function (res) {
        _this.dialogVisible = false;
        _this.$emit("getList");
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        console.log(this.edItem);
        this.form.amount = parseFloat(this.edItem.moreservice_price).toFixed(2);
      }
    }
  }
};
exports.default = _default;