"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      padding: "0"
    }
  }, [_c("div", {
    staticClass: "flex justify-center preview_page"
  }, [_c("div", {
    staticClass: "ipX_wrap",
    staticStyle: {
      "margin-right": "100px"
    }
  }, [_c("page-content", {
    attrs: {
      type: 1,
      pageData: _vm.pageData
    }
  })], 1), _c("div", {
    staticClass: "ip7_wrap"
  }, [_c("page-content", {
    attrs: {
      type: 2,
      pageData: _vm.pageData
    }
  })], 1)]), _c("div", {
    staticClass: "fix-footer-btns",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;