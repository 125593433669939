"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _validate = require("@/utils/validate");
var _default = {
  props: ["editItem", "roleList"],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        remark: ""
      },
      rules: {
        remark: [{
          required: true,
          message: '退款原因不能为空',
          trigger: 'blur'
        }]
      },
      rules1: {
        remark: [{
          required: true,
          message: '退款原因不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editItem ? "退款信息" : "添加账号";
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (_this.editItem) {
            console.log(_this.form);
            (0, _order.moreServiceRefund)({
              id: _this.form.id,
              remark: _this.form.remark
            }).then(function (res) {
              _this.dialogVisible = false;
              _this.$emit("getList");
            });
          }
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;
      if (val) {
        this.$nextTick(function () {
          _this2.$refs['form'].resetFields();
          if (_this2.editItem) {
            // 编辑
            _this2.form = {
              id: _this2.editItem
            };
          }
        });
      } else {
        this.form = {
          mobile: "",
          username: "",
          pwd: "",
          confirmPwd: "",
          role_id: null
        };
      }
    }
  }
};
exports.default = _default;