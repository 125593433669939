"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "category_item"
  }, [_c("div", {
    staticClass: "flex flex-between-center header"
  }, [_c("span", {
    staticClass: "color-666"
  }, [_vm._v(_vm._s(_vm.title))]), _c("p", {
    staticClass: "tools"
  }, [_c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("upSortItem");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-top"
  })]), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("downSortItem");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-bottom"
  })]), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        return _vm.$emit("deleteItem");
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-delete"
  })])])]), _c("div", {
    staticClass: "input_wrapper"
  }, [_c("div", {
    staticClass: "flex name"
  }, [_vm._m(0), _c("el-input", {
    attrs: {
      placeholder: "请输入主标题",
      clearable: ""
    },
    model: {
      value: _vm.formData.main.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.main, "title", $$v);
      },
      expression: "formData.main.title"
    }
  })], 1)]), _c("banner-form", {
    attrs: {
      type: "categoryForm",
      "form-data": _vm.imgData
    }
  }), _c("p", {
    staticClass: "color-666",
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("二级推荐商品")]), _vm._l(_vm.formData.goods, function (item, index) {
    return _c("goods-link", {
      key: item.goods_id,
      attrs: {
        "goods-data": item,
        "show-tool": true
      },
      on: {
        handleDelete: function handleDelete($event) {
          return _vm.deleteGoods(index);
        },
        upSort: function upSort($event) {
          return _vm.upSort(index);
        },
        downSort: function downSort($event) {
          return _vm.downSort(index);
        }
      }
    });
  }), _vm.formData.goods.length < 3 ? _c("div", {
    staticClass: "add-div",
    on: {
      click: _vm.openSelect
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v("添加商品(" + _vm._s(_vm.formData.goods.length) + "/3)\n  ")]) : _vm._e(), _c("goods-dialog", {
    ref: "goodsDialog",
    attrs: {
      multiple: true,
      maxNum: 3,
      "show-checked-tool": true
    },
    on: {
      setSelect: _vm.setSelect
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("label", {
    staticClass: "flex align-center justify-center"
  }, [_c("span", {
    staticClass: "required-icon"
  }, [_vm._v("*")]), _vm._v("主标题")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;