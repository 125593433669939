"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "拒绝接单信息",
      visible: _vm.dialogVisible,
      width: "1000px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.tableData,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      label: "订单",
      prop: "trade_no",
      width: "210px"
    }
  }, [_vm._v(_vm._s(this.orderNo))]), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "商户名",
      prop: "merchant_name",
      width: "160px"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "拒单员",
      prop: "merchant_child_name",
      width: "160px"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "商户备注",
      prop: "memo"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "拒绝时间",
      prop: "createtime",
      width: "170px"
    }
  })], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;