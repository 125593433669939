"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isExternal ? _c("div", _vm._g({
    staticClass: "svg-external-icon svg-icon",
    style: _vm.styleExternalIcon
  }, _vm.$listeners)) : _c("svg", _vm._g({
    class: _vm.svgClass,
    style: {
      width: _vm.size / 10 + "em",
      height: _vm.size / 10 + "em"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, _vm.$listeners), [_c("use", {
    style: _vm.iconColor,
    attrs: {
      "xlink:href": _vm.iconName
    }
  })]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;