"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "page flex align-center h-100 flex-column"
  }, [_c("div", {
    staticClass: "bg flex align-center justify-center flex-column",
    class: _vm.isAuth ? "success" : "fail"
  }, [!_vm.loading ? [_c("div", {
    staticClass: "icon"
  }, [_vm.isAuth ? _c("img", {
    attrs: {
      src: _vm.$globalObj.imgApiUrl + _vm.imgSuccess
    }
  }) : _vm._e()]), _vm.isAuth ? _c("div", [_vm._v("授权成功")]) : _c("div", [_vm._v("授权失败")])] : _vm._e()], 2), _c("div", {
    staticClass: "btn-box"
  }, [!_vm.isAuth ? _c("div", {
    staticClass: "retry"
  }, [_c("el-button", {
    staticClass: "w-100",
    attrs: {
      type: "danger",
      plain: ""
    },
    on: {
      click: function click($event) {
        return _vm.getAuth();
      }
    }
  }, [_vm._v("点击重试")])], 1) : _vm._e(), _c("div", [_c("a", {
    staticClass: "el-button w-100 el-button--primary",
    class: _vm.isAuth ? "el-button--primary" : "el-button--danger",
    attrs: {
      href: _vm.url
    }
  }, [_vm._v("返回App")])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;