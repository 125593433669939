"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WareOpenLogList = WareOpenLogList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 电池仓管理
 */
// 
function WareOpenLogList(params) {
  return _fetch.default.get("/Supplier/WareOpenLog/getList", params);
}