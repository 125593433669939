"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "90px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "轮播图:",
      prop: "banner_img"
    }
  }, [_c("crop-upload", {
    attrs: {
      accept: ".jpg",
      "show-type": "photo",
      "always-crop": true,
      "init-val": _vm.form.banner_img,
      "wrapper-width": "245px",
      "wrapper-height": "100px",
      "img-width": 690,
      "img-height": 280,
      "photo-width": 245,
      "photo-height": 100,
      autoCropWidth: 345,
      autoCropHeight: 140,
      cropperWidth: "400px",
      cropperHeight: "400px",
      dialogWidth: "600px",
      enlarge: 2,
      tip: "请上传 jpg 文件，大小不超过 2M。",
      "sub-tip": "尺寸要求：宽度690像素，高度280像素。"
    },
    on: {
      setVal: _vm.setUrl
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "商品链接:"
    }
  }, [_c("div", {
    staticClass: "flex align-center justify-between link_wrapper"
  }, [_c("p", {
    staticClass: "flex-1 text-ellipsis"
  }, [_vm._v(_vm._s(_vm.urlName))]), _c("el-link", {
    attrs: {
      type: "primary",
      underline: false
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openSelect.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.form.goods_id && _vm.urlName ? "更改" : "添加链接"))])], 1)])], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1), _c("goods-dialog", {
    ref: "goodsDialog",
    attrs: {
      "show-cate": false,
      "append-to-body": true,
      "show-checked-tool": true
    },
    on: {
      setSelect: _vm.setSelect
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;