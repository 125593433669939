"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "新增转账记录",
      visible: _vm.dialogVisible,
      width: "550px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "收款人"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "银行卡账号"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.account,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "account", $$v);
      },
      expression: "formData.account"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "转账金额(元)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.formData.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "amount", $$v);
      },
      expression: "formData.amount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "备注："
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3,
      placeholder: "请输入备注"
    },
    model: {
      value: _vm.formData.resaon,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "resaon", $$v);
      },
      expression: "formData.resaon"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("提交")]), _c("el-button", {
    attrs: {
      type: "primary",
      plain: ""
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;