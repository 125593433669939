"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "col_form office_first"
  }, [_vm.formData.is_control ? _c("div", {
    staticClass: "flex name flex-column",
    staticStyle: {
      margin: "10px 0"
    }
  }, [_c("div", {
    staticClass: "flex name"
  }, [_c("h5", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("是否显示: ")]), _c("el-switch", {
    attrs: {
      "active-text": "开启",
      "active-value": "1",
      "inactive-value": "0",
      "inactive-text": "关闭",
      value: _vm.formData.is_show
    },
    on: {
      change: _vm.changeIsShow
    }
  })], 1), _c("div", {
    staticClass: "flex name",
    staticStyle: {
      margin: "20px 20px 20px 0"
    }
  }, [_c("h5", [_vm._v("显示图标： ")]), _vm.formData.is_icon_conf ? _c("crop-upload", {
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.icon_img,
      "wrapper-width": "88px",
      "wrapper-height": "88px",
      "img-width": 92,
      "img-height": 92,
      "photo-width": 88,
      "photo-height": 88
    },
    on: {
      setVal: function setVal(val) {
        _vm.formData.icon_img = val;
      }
    }
  }) : _vm._e()], 1)]) : _vm._e(), _c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _c("p", [_c("span", [_vm._v("图片要求！")]), _c("br"), _vm._v("图片宽度" + _vm._s(_vm.imgWidth) + "px，高度" + _vm._s(_vm.imgHeight) + "px。")])], 1), _vm.levelName == "first_level" || _vm.levelName == "second_level" || _vm.levelName == "third_level" || _vm.levelName == "fourth_level" && !_vm.formData.is_nineGrid ? _c("crop-upload", {
    staticClass: "w-100 img_bg",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.background_image,
      "wrapper-width": _vm.photoWidth + "px",
      "wrapper-height": _vm.photoHeight + "px",
      "img-width": _vm.imgWidth,
      "img-height": _vm.imgHeight,
      "photo-width": _vm.photoWidth,
      "photo-height": _vm.photoHeight
    },
    on: {
      setVal: function setVal(val) {
        _vm.formData.background_image = val;
      }
    }
  }) : _vm._e(), !_vm.formData.is_control ? _c("div", {
    staticClass: "input_wrapper"
  }, [_vm.levelName == "first_level" ? _c("div", {
    staticClass: "flex name"
  }, [_c("label", {
    staticClass: "flex align-center justify-center"
  }, [_vm._v("\n                主标题\n            ")]), _c("el-input", {
    attrs: {
      placeholder: "请输入主标题",
      clearable: ""
    },
    model: {
      value: _vm.formData.main_title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "main_title", $$v);
      },
      expression: "formData.main_title"
    }
  })], 1) : _vm._e(), _vm.levelName == "first_level" ? _c("div", {
    staticClass: "flex name"
  }, [_c("label", {
    staticClass: "flex align-center justify-center"
  }, [_vm._v("\n                页面背景色\n            ")]), _c("el-color-picker", {
    model: {
      value: _vm.formData.background_color,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "background_color", $$v);
      },
      expression: "formData.background_color"
    }
  })], 1) : _vm._e(), _vm.levelName == "first_level" ? _c("div", {
    staticClass: "flex name"
  }, [_c("label", {
    staticClass: "flex align-center justify-center"
  }, [_vm._v("\n                按钮背景色\n            ")]), _c("el-color-picker", {
    model: {
      value: _vm.formData.btn_bg_color,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "btn_bg_color", $$v);
      },
      expression: "formData.btn_bg_color"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.levelName == "fourth_level" && _vm.formData.is_nineGrid ? _c("nine-grid", {
    ref: "nineGrid",
    attrs: {
      "img-width": _vm.imgWidth,
      "img-height": _vm.imgHeight
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;