"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.regexp.search");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "manage"
  }, [_c("div", {
    staticClass: "common_form_search"
  }, [_c("el-form", {
    ref: "searchForm",
    attrs: {
      inline: true,
      model: _vm.searchForm
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商品名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入商品名称",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "title", $$v);
      },
      expression: "searchForm.title"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.search();
      }
    }
  }, [_vm._v("搜索")])], 1)], 1)], 1), _c("div", {
    staticClass: "flex align-center table-top-btns"
  }, [_c("h5", {
    staticClass: "flex align-center"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      "icon-class": "list",
      size: 15
    }
  }), _vm._v("数据列表\n    ")], 1), _vm.$hasMethod("#addGoods") ? _c("el-button", {
    staticClass: "add-btn",
    attrs: {
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.openAdd();
      }
    }
  }, [_vm._v("添加商品")]) : _vm._e(), _c("p", {
    staticClass: "color-999",
    staticStyle: {
      "margin-left": "22px"
    }
  }, [_vm._v("共查询"), _c("span", {
    staticClass: "red-font"
  }, [_vm._v(_vm._s(_vm.total))]), _vm._v("个商品")])], 1), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      border: "",
      "empty-text": "该分类下暂无商品，请添加店内商品"
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      label: "排序",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!_vm.$hasMethod("#sortGoods") ? _c("span", [_vm._v(_vm._s(scope.row.sort))]) : _c("el-input", {
          attrs: {
            oninput: "value=value.replace(/[^\\d]/g,'')",
            maxLength: "9"
          },
          on: {
            change: function change($event) {
              return _vm.changeSort(scope.row.id, scope.row.sort);
            }
          },
          model: {
            value: scope.row.sort,
            callback: function callback($$v) {
              _vm.$set(scope.row, "sort", $$v);
            },
            expression: "scope.row.sort"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "id",
      label: "ID",
      width: "70",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "图片",
      width: "90",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-image", {
          staticStyle: {
            width: "50px",
            height: "50px"
          },
          attrs: {
            src: scope.row.imgurl ? _vm.$globalObj.imgBaseUrl + scope.row.imgurl : ""
          }
        }, [_c("div", {
          staticClass: "image-slot",
          attrs: {
            slot: "error"
          },
          slot: "error"
        }, [_c("i", {
          staticClass: "el-icon-picture-outline"
        })])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "商品名称",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "cat_name",
      label: "商品分类",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "create_time",
      label: "添加时间",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            type: scope.row.status == 1 ? "success" : "danger"
          }
        }, [_vm._v(_vm._s(scope.row.status == 1 ? "已上架" : "已下架"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      fixed: "right",
      align: "center",
      label: "操作",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.$hasMethod("#deleteGood") ? _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.removeItem(scope.row.id);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }])
  })], 1), _c("custom-page", {
    ref: "customPage",
    attrs: {
      total: _vm.total
    },
    on: {
      getList: _vm.getList
    }
  }), _c("goods-dialog", {
    ref: "goodsDialog",
    attrs: {
      multiple: true,
      "show-checked-tool": true,
      "show-cate": false,
      "append-to-body": true
    },
    on: {
      setSelect: _vm.setSelect
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;