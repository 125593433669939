"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "500px",
      title: "跟单分配",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "跟单员",
      prop: "merchantChildId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "跟单员",
      clearable: ""
    },
    model: {
      value: _vm.form.merchantChildId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "merchantChildId", $$v);
      },
      expression: "form.merchantChildId"
    }
  }, _vm._l(_vm.merchantList, function (item) {
    return item.id == 0 || item.status == 1 ? _c("el-option", {
      key: item.id,
      attrs: {
        label: item.username + (item.id > 0 ? " (\u8BA2\u5355: ".concat(item.audit_count, ")") : ""),
        value: item.id
      }
    }) : _vm._e();
  }), 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;