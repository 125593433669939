"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "分销信息",
      visible: _vm.dialogVisible,
      width: "1200px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.tableData,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "订单"
    }
  }, [_vm._v(_vm._s(_vm.orderNo))]), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "lease_num",
      label: "租期"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "租金"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v("￥" + _vm._s(scope.row.lease_amount))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", [_vm._v(_vm._s(_vm.getTagType(scope.row.status)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "分销金额",
      prop: "amount"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "支付时间",
      prop: "create_time"
    }
  })], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1), _c("deduct-log", {
    ref: "deductLog",
    attrs: {
      "order-no": _vm.selectedItem.trade_no,
      "lease-id": _vm.selectedItem.id
    }
  }), _c("setWithhold", {
    ref: "setWithhold",
    attrs: {
      item: _vm.selectedItem
    },
    on: {
      success: _vm.refresh
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;