"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-table", {
    attrs: {
      data: _vm.tableData,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      prop: "create_time",
      label: "时间"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "buy_num",
      label: "条数"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "pay_amount",
      label: "金额（元）"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "buy_type_name",
      label: "类型"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "username",
      label: "操作人"
    }
  })], 1), _c("custom-page", {
    ref: "customPage",
    attrs: {
      total: _vm.total
    },
    on: {
      getList: _vm.getList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;