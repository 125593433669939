"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "col_form"
  }, [_c("p", {
    staticClass: "color-666",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(_vm._s(_vm.title))]), _vm.type === "thirdHot" ? _c("crop-upload", {
    staticClass: "w-100 img_bg",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.backgroundImg,
      "wrapper-width": "359px",
      "wrapper-height": "215px",
      "img-width": 690,
      "img-height": 382,
      "photo-width": 359,
      "photo-height": 215
    },
    on: {
      setVal: function setVal(val) {
        _vm.formData.backgroundImg = val;
      }
    }
  }) : _vm._e(), _vm.type === "bannerCol" ? _c("crop-upload", {
    staticClass: "w-100 img_bg",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.backgroundImg,
      "wrapper-width": "359px",
      "wrapper-height": "258px",
      "img-width": 335,
      "img-height": 240,
      "photo-width": 359,
      "photo-height": 258
    },
    on: {
      setVal: function setVal(val) {
        _vm.formData.backgroundImg = val;
      }
    }
  }) : _vm._e(), _vm.type === "bannerColThree" ? _c("crop-upload", {
    staticClass: "w-100 img_bg",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.backgroundImg,
      "wrapper-width": "359px",
      "wrapper-height": "258px",
      "img-width": 341,
      "img-height": 148,
      "photo-width": 359,
      "photo-height": 258
    },
    on: {
      setVal: function setVal(val) {
        _vm.formData.backgroundImg = val;
      }
    }
  }) : _vm._e(), _vm.type === "twoColGoods" ? _c("crop-upload", {
    staticClass: "w-100 img_bg",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.backgroundImg,
      "wrapper-width": "359px",
      "wrapper-height": "305px",
      "img-width": 344,
      "img-height": 292,
      "photo-width": 359,
      "photo-height": 305
    },
    on: {
      setVal: function setVal(val) {
        _vm.formData.backgroundImg = val;
      }
    }
  }) : _vm._e(), _vm.type != "bannerColThree" ? _c("div", [_c("div", {
    staticClass: "input_wrapper"
  }, [_c("div", {
    staticClass: "flex name"
  }, [_vm._m(0), _c("el-input", {
    attrs: {
      placeholder: "请输入主标题",
      clearable: ""
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c("div", {
    staticClass: "flex name"
  }, [_vm._m(1), _c("el-input", {
    attrs: {
      placeholder: "请输入副标题",
      clearable: ""
    },
    model: {
      value: _vm.formData.subtitle,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "subtitle", $$v);
      },
      expression: "formData.subtitle"
    }
  })], 1), _c("div", {
    staticClass: "flex price"
  }, [_vm._m(2), _c("el-input", {
    attrs: {
      placeholder: "请输入链接",
      clearable: ""
    },
    model: {
      value: _vm.formData.jumpUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "jumpUrl", $$v);
      },
      expression: "formData.jumpUrl"
    }
  })], 1)]), _vm.contentType === "goods" ? [_vm._l(_vm.goodsList, function (item, index) {
    return _c("goods-link", {
      key: item.goods_id,
      attrs: {
        "goods-data": item,
        "show-tool": true
      },
      on: {
        handleDelete: function handleDelete($event) {
          return _vm.deleteGoods(index);
        },
        upSort: function upSort($event) {
          return _vm.upSort(index);
        },
        downSort: function downSort($event) {
          return _vm.downSort(index);
        }
      }
    });
  }), _vm.goodsList.length < _vm.goodsNum ? _c("div", {
    staticClass: "add-div",
    on: {
      click: _vm.openSelect
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v("添加商品(" + _vm._s(_vm.goodsList.length) + "/" + _vm._s(_vm.goodsNum) + ")\n\t\t  ")]) : _vm._e()] : _vm._e(), _vm.contentType === "img" ? [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("crop-upload", {
    staticClass: "w-100 img_bg",
    attrs: {
      "show-type": "photo",
      "is-square": true,
      "init-val": _vm.goods[0].imgurl,
      "wrapper-width": "170px",
      "wrapper-height": "170px",
      "photo-width": 170,
      "photo-height": 170
    },
    on: {
      setVal: function setVal(val) {
        _vm.goods[0].imgurl = val;
      }
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("crop-upload", {
    staticClass: "w-100 img_bg",
    attrs: {
      "show-type": "photo",
      "is-square": true,
      "init-val": _vm.goods[1].imgurl,
      "wrapper-width": "170px",
      "wrapper-height": "170px",
      "photo-width": 170,
      "photo-height": 170
    },
    on: {
      setVal: function setVal(val) {
        _vm.goods[1].imgurl = val;
      }
    }
  })], 1)], 1)] : _vm._e()], 2) : _c("div", [_vm.formData.jumpType && _vm.formData.jumpUrl ? _c("div", {
    staticClass: "flex align-center link_val pointer",
    on: {
      click: _vm.addLink
    }
  }, [_c("span", {
    staticClass: "type"
  }, [_vm._v(_vm._s(_vm.formData.jumpType == 1 ? "文本链接" : "商品链接"))]), _vm.linkVal.length > 20 ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm.linkVal,
      placement: "top-start"
    }
  }, [_c("p", {
    staticClass: "flex-1 text-ellipsis"
  }, [_vm._v(_vm._s(_vm.linkVal))])]) : _c("p", {
    staticClass: "flex-1 text-ellipsis"
  }, [_vm._v(_vm._s(_vm.linkVal))])], 1) : _c("div", {
    staticClass: "add-div",
    on: {
      click: _vm.addLink
    }
  }, [_c("i", {
    staticClass: "el-icon-link"
  }), _vm._v("添加链接\n\t\t\t\t")]), _c("link-dialog", {
    ref: "linkDialog",
    attrs: {
      editItem: _vm.editItem
    },
    on: {
      setVal: _vm.setVal
    }
  })], 1), _c("goods-dialog", {
    ref: "goodsDialog",
    attrs: {
      multiple: true,
      maxNum: _vm.goodsNum,
      "show-checked-tool": true
    },
    on: {
      setSelect: _vm.setSelect
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("label", {
    staticClass: "flex align-center justify-center"
  }, [_c("span", {
    staticClass: "required-icon"
  }, [_vm._v("*")]), _vm._v("主标题")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("label", {
    staticClass: "flex align-center justify-center"
  }, [_c("span", {
    staticClass: "required-icon"
  }, [_vm._v("*")]), _vm._v("副标题")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("label", {
    staticClass: "flex align-center justify-center"
  }, [_c("span", {
    staticClass: "required-icon"
  }, [_vm._v("*")]), _vm._v("链接")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;