"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wh100",
    attrs: {
      id: _vm.mapId
    }
  }, [_vm.loading ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "wh100"
  }) : _c("div", {
    staticClass: "wh100 flex flex-column"
  }, [_c("div", {
    staticClass: "function-box"
  }, [_vm.showSearch ? _c("el-select", {
    attrs: {
      placeholder: "请输入位置进行搜索",
      filterable: "",
      remote: "",
      "remote-method": _vm.remoteMethod,
      loading: _vm.selectLoading
    },
    on: {
      change: _vm.selectAddress
    },
    model: {
      value: _vm.addressUid,
      callback: function callback($$v) {
        _vm.addressUid = $$v;
      },
      expression: "addressUid"
    }
  }, _vm._l(_vm.selectAddressList, function (item, index) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.title,
        value: item.uid
      }
    });
  }), 1) : _vm._e()], 1), _c("div", {
    staticClass: "map-box flex-1"
  }, [_vm.showCenter ? _c("div", {
    staticClass: "center-marker"
  }) : _vm._e(), _vm.mapAK ? _c("baidu-map", {
    ref: "customComponent",
    staticClass: "wh100",
    attrs: {
      ak: _vm.mapAK,
      center: _vm.mapCenter,
      zoom: _vm.mapZoom,
      "inertial-dragging": "",
      "scroll-wheel-zoom": true
    },
    on: {
      ready: _vm.mapReady
    }
  }, [_c("BmLocalSearch", {
    attrs: {
      keyword: _vm.searchAddress,
      panel: false
    },
    on: {
      searchcomplete: _vm.handlerSearch
    }
  }), [_c("BmPolyline", {
    attrs: {
      path: _vm.pathLine,
      "stroke-color": "#4aa4f3",
      "stroke-opacity": 1,
      "stroke-weight": 3
    }
  }), _vm.startPoint ? _c("map-marker", {
    attrs: {
      position: _vm.startPoint,
      offset: {
        width: 0,
        height: -16
      },
      "z-index": 200,
      icon: {
        url: _vm.startPointIcon,
        size: {
          width: 32,
          height: 32
        }
      }
    }
  }) : _vm._e(), _vm.endPoint ? _c("map-marker", {
    attrs: {
      position: _vm.endPoint,
      offset: {
        width: 0,
        height: -16
      },
      "z-index": 200,
      icon: {
        url: _vm.endPointIcon,
        size: {
          width: 32,
          height: 32
        }
      }
    }
  }) : _vm._e()]], 2) : _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      height: "500px",
      "align-items": "center"
    }
  }, [_c("h1", [_vm._v("百度地图AK秘钥不存在，请先配置百度地图AK秘钥")])])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;