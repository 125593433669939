"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BatSwapLogList = BatSwapLogList;
exports.batteryDel = batteryDel;
exports.batterySave = batterySave;
exports.getSwappingStatusAndTypeList = getSwappingStatusAndTypeList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 换电柜
 */
// 换电柜列表
function BatSwapLogList(params) {
  return _fetch.default.get("/Supplier/BatSwapLog/getList", params);
}
//换电柜修改
function batterySave(params) {
  return _fetch.default.post("/Supplier/BatSwapLog/save", params, true);
}
//删除电柜修改
function batteryDel(params) {
  return _fetch.default.post("/Supplier/BatSwapLog/del", params, true);
}
//获取类型和状态列表
function getSwappingStatusAndTypeList(params) {
  return _fetch.default.post("/Supplier/BatSwapLog/getSwappingStatusAndTypeList", params);
}