"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "rate_info footer-page"
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "main_content"
  }, [_c("div", {
    staticClass: "label_title"
  }, [_vm.platform_exist ? _c("p", [_vm._v("黑白名单设置")]) : _c("p", [_vm._v("当前供应商未添加到浓度算法平台中，请联系管理员操作")])]), _vm.platform_exist ? _c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("setWhiteBlackList", {
    attrs: {
      "platform-id": _vm.platformId,
      "form-list": _vm.formList
    }
  })], 1) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;