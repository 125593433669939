import { render, staticRenderFns } from "./classify.vue?vue&type=template&id=f98ecd34&scoped=true&"
import script from "./classify.vue?vue&type=script&lang=js&"
export * from "./classify.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f98ecd34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\iot_vehicle\\iot_frontend\\iot_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f98ecd34')) {
      api.createRecord('f98ecd34', component.options)
    } else {
      api.reload('f98ecd34', component.options)
    }
    module.hot.accept("./classify.vue?vue&type=template&id=f98ecd34&scoped=true&", function () {
      api.rerender('f98ecd34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/aliApp/popularize/classify.vue"
export default component.exports