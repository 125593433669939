"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", {
    attrs: {
      title: "查看风控详情",
      visible: _vm.dialogVisible,
      size: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%",
      height: "400px"
    }
  }), !_vm.loading ? _c("div", {
    staticStyle: {
      position: "relative",
      height: "100%"
    }
  }, [_c("iframe", {
    attrs: {
      src: _vm.tableData,
      width: "100%",
      height: "100%",
      frameborder: "0"
    }
  })]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;