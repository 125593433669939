"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ['item'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        money: 0,
        payMoney: 0
      },
      rules: {
        evidence_url: [{
          required: true,
          message: '请上传图片',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {},
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.formData.money = this.item.rent;
        this.formData.payMoney = parseFloat(this.item.rent).toFixed(2);
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      if (this.formData.payMoney < 0) {
        this.$message.error("操作金额异常");
        return;
      }
      if (parseFloat(this.formData.payMoney) > parseFloat(this.formData.money)) {
        this.$message.error("操作金额过大");
        return;
      }
      var title = "";
      if (this.formData.payMoney == this.formData.money) {
        title = "确认全额还款是吗";
      } else {
        title = "还款金额比总金额少,线下还款只能一次性还清,请确认是否优惠";
      }
      this.$confirm(title, "线下还款", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.setOfflinePay)({
          order_id: _this.item.order_id,
          lease_id: _this.item.id,
          paid_rent: _this.formData.payMoney
        }).then(function (res) {
          _this.dialogVisible = false;
          _this.$emit('success', true);
        });
      }).catch(function () {});
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;