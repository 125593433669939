"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _goods = require("@/api/goods");
var _order = require("@/api/order");
var _default = {
  props: ['editItem'],
  data: function data() {
    return {
      loading: true,
      dialogVisible: false,
      form: {
        order_id: 0,
        goods_id: 0,
        service_ids: []
      },
      disabledServiceIds: [],
      serviceList: [],
      rules: {
        // setmeal_id: [
        // 	{ required: true, message: '请选择套餐', trigger: 'blur' },
        //  ],
        // spec_id: [
        // 	{ required: true, message: '请选择规格', trigger: 'blur' },
        //  ],
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      } else {
        // this.$refs['ruleForm'].resetFields()
        this.form = this.$options.data().form;
        this.disabledServiceIds = this.$options.data().disabledServiceIds;
      }
    }
  },
  methods: {
    init: function init() {
      console.log('item', (0, _objectSpread2.default)({}, this.editItem));
      this.form.order_id = this.editItem.id;
      this.form.goods_id = this.editItem.goods_id;
      this.initServiceIds();
      this.getGoodsServiceList();
    },
    initServiceIds: function initServiceIds() {
      var _this = this;
      if (this.editItem.service.length) {
        this.editItem.service.forEach(function (item) {
          _this.form.service_ids.push(item.moreservice_id);
          // console.log('service', item)
          if (item.status != 0) {
            _this.disabledServiceIds.push(item.moreservice_id);
          }
        });
      }
    },
    getGoodsServiceList: function getGoodsServiceList() {
      var _this2 = this;
      this.loading = true;
      (0, _goods.getGoodsServiceList)({
        goods_id: this.editItem.goods_id
      }).then(function (res) {
        _this2.serviceList = res;
        _this2.loading = false;
        // console.log('res', res)
      });
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = (0, _objectSpread2.default)({}, _this3.form);
          // console.log('submitParams', params)
          (0, _order.editOrderService)(params).then(function (res) {
            _this3.dialogVisible = false;
            _this3.$emit('getList', true);
          }).catch(function (error) {});
        } else {
          _this3.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};
exports.default = _default;