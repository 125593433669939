"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "link_dialog",
    attrs: {
      width: "500px",
      title: "添加链接",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    staticClass: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "链接类型",
      prop: "jumpType"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.resetUrl
    },
    model: {
      value: _vm.form.jumpType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "jumpType", $$v);
      },
      expression: "form.jumpType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "文本链接",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "商品链接",
      value: 2
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: _vm.form.jumpType == 1 ? "文本链接" : "商品链接",
      prop: "jumpUrl"
    }
  }, [_vm.form.jumpType == 1 ? [_c("el-input", {
    attrs: {
      placeholder: "请输入链接"
    },
    model: {
      value: _vm.form.jumpUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "jumpUrl", $$v);
      },
      expression: "form.jumpUrl"
    }
  })] : _vm._e(), _vm.form.jumpType == 2 ? [_vm.form.jumpUrl ? _c("div", {
    staticClass: "flex align-center goods_link"
  }, [_c("p", {
    staticClass: "flex-1 text-ellipsis"
  }, [_vm._v(_vm._s(_vm.form.goods.title))]), _c("span", {
    staticClass: "color-999 pointer",
    on: {
      click: _vm.resetUrl
    }
  }, [_c("i", {
    staticClass: "el-icon-error"
  })])]) : _c("div", {
    staticClass: "add-div",
    on: {
      click: _vm.openSelect
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v("选择商品链接\n                ")])] : _vm._e()], 2)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1), _c("goods-dialog", {
    ref: "goodsDialog",
    attrs: {
      "dialog-width": "1340px",
      "page-size": 12,
      "append-to-body": true,
      "show-type-select": true,
      "show-checked-tool": true
    },
    on: {
      setSelect: _vm.setSelect
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;