"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _goods = require("@/api/goods");
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      searchForm: {
        goods_id: null,
        goods_name: ""
      },
      total: 0,
      tableData: [],
      multipleSelection: []
    };
  },
  methods: {
    edit: function edit(goodsId) {
      this.$router.push({
        name: 'goodsAdd',
        params: {
          draftId: goodsId
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log('多选', val);
      this.multipleSelection = val;
    },
    handleSelectionRow: function handleSelectionRow(val) {
      console.log('xuanze', val);
    },
    handleDelete: function handleDelete(num, id) {
      var _this = this;
      var tip = "",
        title = "";
      var ids = [];
      if (num == 1) {
        // console.log('del',num,this.multipleSelection)
        if (this.multipleSelection.length == 0) {
          this.$message.warning('请先选择数据');
          return false;
        } else {
          var idList = [];
          this.multipleSelection.forEach(function (item) {
            idList.push(item.id);
          });
          ids = idList.join(',');
        }
        tip = "删除草稿后，已编辑的草稿内容将全部清除，需重新编辑，请确认是否要批量删除草稿？", title = "批量删除";
      } else {
        ids = id;
        tip = "您确定要删除这条草稿吗？";
        title = "删除";
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _goods.batchDeleteGoodsDraft)(ids).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        goods_id: null,
        goods_name: ""
      };
      this.getList();
    },
    getList: function getList(flag) {
      var _this2 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _goods.getGoodsDraft)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;