"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deviceOfflineLogList = deviceOfflineLogList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 
 */
//
function deviceOfflineLogList(params) {
  return _fetch.default.get("/Supplier/deviceOfflineLog/getList", params);
}