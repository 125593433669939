"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _shop = require("@/api/shop");
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  data: function data() {
    return {
      infoData: {}
    };
  },
  methods: {
    getData: function getData() {
      var _this = this;
      (0, _shop.shopMainInfo)().then(function (res) {
        _this.infoData = res || {};
      });
    }
  },
  mounted: function mounted() {
    this.getData();
  }
};
exports.default = _default;