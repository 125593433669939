"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      "label-width": "auto",
      rules: _vm.editItem ? _vm.rules1 : _vm.rules,
      model: _vm.form
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "网点名称",
      prop: "serviceName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入网点名称",
      clearable: ""
    },
    model: {
      value: _vm.form.serviceName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "serviceName", $$v);
      },
      expression: "form.serviceName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "公司名称",
      prop: "companyName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入公司名称",
      clearable: ""
    },
    model: {
      value: _vm.form.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "服务电话",
      prop: "servicePhone"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入网点名称",
      clearable: ""
    },
    model: {
      value: _vm.form.servicePhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "servicePhone", $$v);
      },
      expression: "form.servicePhone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "服务地址",
      prop: "serviceAddress"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入网点名称",
      clearable: ""
    },
    model: {
      value: _vm.form.serviceAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "serviceAddress", $$v);
      },
      expression: "form.serviceAddress"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "经纬度",
      prop: "siteMap"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入网点名称",
      clearable: ""
    },
    model: {
      value: _vm.form.siteMap,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "siteMap", $$v);
      },
      expression: "form.siteMap"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "网点状态",
      prop: "serviceState"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.serviceState,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "serviceState", $$v);
      },
      expression: "form.serviceState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "运行中",
      value: "1"
    }
  }), _c("el-option", {
    attrs: {
      label: "维护中",
      value: "2"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "服务开始结束时间",
      prop: "start_time"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "datetimerange",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "default-time": ["12:00:00"]
    },
    model: {
      value: _vm.form.start_end_time,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "start_end_time", $$v);
      },
      expression: "form.start_end_time"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "网点类型",
      prop: "serviceType"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.serviceType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "serviceType", $$v);
      },
      expression: "form.serviceType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "小区",
      value: "1"
    }
  }), _c("el-option", {
    attrs: {
      label: "商圈",
      value: "2"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "网点描述",
      prop: "serviceDesc"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入网点名称",
      clearable: ""
    },
    model: {
      value: _vm.form.serviceDesc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "serviceDesc", $$v);
      },
      expression: "form.serviceDesc"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "每度电价格",
      prop: "price"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入每度电价格",
      clearable: ""
    },
    model: {
      value: _vm.form.price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;