"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-width": "100px"
    }
  }, [_vm._v("\n\t需要完成蚂蚁平台注册并开通蚂蚁链和蚂蚁合同\n"), _c("el-divider", {
    attrs: {
      "content-position": "left"
    }
  }, [_vm._v("页面说明")]), _c("el-form-item", {
    attrs: {
      label: "公司名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "龙岩市鹿峰台网络科技有限公司"
    },
    model: {
      value: _vm.form.company,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "company", $$v);
      },
      expression: "form.company"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "营业执照号"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "91350802MAC1CNGDGE"
    },
    model: {
      value: _vm.form.companyid,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "companyid", $$v);
      },
      expression: "form.companyid"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "法人姓名"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "张三"
    },
    model: {
      value: _vm.form.faname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "faname", $$v);
      },
      expression: "form.faname"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "法人身份证"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "350815548745487569"
    },
    model: {
      value: _vm.form.idcard,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "idcard", $$v);
      },
      expression: "form.idcard"
    }
  })], 1), _vm.form.ant_organization_id == null || _vm.form.ant_organization_id == "" ? _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("立即创建")]), _c("el-button", [_vm._v("取消")])], 1) : _c("el-form-item", {
    attrs: {
      label: "蚂蚁组织ID"
    }
  }, [_vm._v("\n   \t     " + _vm._s(_vm.form.ant_organization_id) + "\n  ")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;