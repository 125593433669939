"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _categoryForm = _interopRequireDefault(require("../components/categoryForm"));
var _default = {
  components: {
    categoryForm: _categoryForm.default
  },
  data: function data() {
    return {
      dataList: []
    };
  },
  methods: {
    // 升序
    upSortItem: function upSortItem(index) {
      if (index === 0) {
        return;
      }
      var item = this.dataList[index];
      this.$set(this.dataList, index, this.dataList[index - 1]);
      this.$set(this.dataList, index - 1, item);
    },
    // 降序
    downSortItem: function downSortItem(index) {
      if (index === this.dataList.length - 1) {
        return;
      }
      var item = this.dataList[index];
      this.$set(this.dataList, index, this.dataList[index + 1]);
      this.$set(this.dataList, index + 1, item);
    },
    // 删除推荐位
    deleteItem: function deleteItem(index) {
      this.dataList.splice(index, 1);
    },
    addItem: function addItem() {
      this.dataList.push({
        main: {
          img: '',
          title: '',
          jumpType: '',
          jumpUrl: '',
          goods: {}
        },
        goods: []
      });
    }
  }
};
exports.default = _default;