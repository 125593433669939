"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ['item'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {},
      defaultForm: {
        request_img: '',
        request_remark: ''
      },
      rules: {
        request_img: [{
          required: true,
          message: '请上传图片',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        console.log('item', this.item);
        if (0) {} else {
          this.formData = this.$func.deepClone(this.defaultForm);
        }
      } else {
        this.$refs['formData'].resetFields();
      }
    }
  },
  methods: {
    setUrl: function setUrl(val) {
      this.formData.request_img = val;
    },
    /**
     * 只需要order_id、备注、图片
     **/
    submit: function submit() {
      var _this = this;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      console.log('item', this.item);
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var param = (0, _objectSpread2.default)({
            order_id: _this.item.order_id
          }, _this.formData);
          console.log('param', param);
          // return;
          // 更新原订单状态后，要将内容写到cancel表
          (0, _order.orderCloseMerchant)({
            order_id: _this.item.order_id,
            apply_type: 1
          }).then(function (res) {
            (0, _order.requestOrderOverAPI)(param).then(function (res) {
              // this.getList(true)
              _this.$emit('success');
              _this.close();
            });
          });
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;