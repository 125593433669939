"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "preview_img",
    style: {
      width: _vm.width + "px",
      height: _vm.height + "px"
    }
  }, [_c("el-image", {
    staticClass: "w-100 h-100",
    attrs: {
      fit: "fill",
      src: _vm.imgUrl ? _vm.$globalObj.imgBaseUrl + _vm.imgUrl : ""
    }
  }, [_c("div", {
    staticClass: "image-slot",
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_c("i", {
    staticClass: "el-icon-picture-outline"
  })])]), _vm.imgUrl ? _c("p", {
    staticClass: "mask_tool text-center pointer",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        _vm.showViewer = true;
      }
    }
  }, [_vm._v("\n    查看\n  ")]) : _vm._e(), _vm.showViewer ? _c("el-image-viewer", {
    attrs: {
      "on-close": _vm.closeViewer,
      "url-list": [_vm.$globalObj.imgBaseUrl + _vm.imgUrl]
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;