"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "500px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    staticClass: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "风险等级",
      prop: "risk_type"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "全部",
      clearable: ""
    },
    model: {
      value: _vm.form.risk_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "risk_type", $$v);
      },
      expression: "form.risk_type"
    }
  }, [_c("el-option", {
    attrs: {
      label: "中风险",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "高风险",
      value: 2
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "快速选择",
      prop: ""
    }
  }, [_c("el-cascader", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择地区",
      options: _vm.areaList,
      props: _vm.optionProps,
      separator: "-"
    },
    on: {
      change: _vm.setArea
    },
    model: {
      value: _vm.area,
      callback: function callback($$v) {
        _vm.area = $$v;
      },
      expression: "area"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "省",
      prop: "province"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入省",
      maxlength: 200
    },
    on: {
      change: function change($event) {
        return _vm.changeArea("province");
      }
    },
    model: {
      value: _vm.form.province,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "province", $$v);
      },
      expression: "form.province"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "省编码",
      prop: "province"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入省",
      maxlength: 200
    },
    on: {
      change: function change($event) {
        return _vm.changeArea("province");
      }
    },
    model: {
      value: _vm.form.province_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "province_id", $$v);
      },
      expression: "form.province_id"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "市",
      prop: "city"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入市",
      maxlength: 200
    },
    on: {
      change: function change($event) {
        return _vm.changeArea("city");
      }
    },
    model: {
      value: _vm.form.city,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "city", $$v);
      },
      expression: "form.city"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "市编码",
      prop: "city"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入市",
      maxlength: 200
    },
    on: {
      change: function change($event) {
        return _vm.changeArea("city");
      }
    },
    model: {
      value: _vm.form.city_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "city_id", $$v);
      },
      expression: "form.city_id"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "区",
      prop: "district"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入区",
      maxlength: 200
    },
    on: {
      change: function change($event) {
        return _vm.changeArea("district");
      }
    },
    model: {
      value: _vm.form.district,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "district", $$v);
      },
      expression: "form.district"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "户籍黑名单",
      prop: "di_risky"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    },
    model: {
      value: _vm.form.di_risky,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "di_risky", $$v);
      },
      expression: "form.di_risky"
    }
  }, [_vm._v("否默认")]), _c("el-radio", {
    attrs: {
      label: 1
    },
    model: {
      value: _vm.form.di_risky,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "di_risky", $$v);
      },
      expression: "form.di_risky"
    }
  }, [_vm._v("是")])], 1), _c("el-form-item", {
    attrs: {
      label: "街道",
      prop: "street"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3,
      placeholder: "请输入街道",
      maxlength: 200
    },
    model: {
      value: _vm.form.street,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "street", $$v);
      },
      expression: "form.street"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;