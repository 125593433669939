"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tab-title-page goods_add"
  }, [_c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _c("p", [_vm._v(" 商品图片和文字描述不能存在手机号和任何二维码，不得存在微信字样。")])], 1), _c("div", {
    staticClass: "nav_tabs"
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "商品基础信息",
      name: "base"
    }
  }), _vm._l(_vm.mealTabs, function (item) {
    return _c("el-tab-pane", {
      key: item,
      attrs: {
        name: "meal".concat(item)
      }
    }, [_c("span", {
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_vm._v("套餐" + _vm._s(item)), _vm.activeName === "meal".concat(item) ? _c("i", {
      staticClass: "el-icon-error blue-font",
      staticStyle: {
        "margin-left": "5px"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.removeTab(item - 1);
        }
      }
    }) : _vm._e()])]);
  })], 2)], 1), _c("el-divider", {
    staticClass: "middle_divider"
  }), _c("div", {
    staticClass: "child_page"
  }, [_vm.activeName === "base" ? [_c("base-info", {
    ref: "baseInfo",
    attrs: {
      "draft-id": _vm.draftId,
      "active-tab": _vm.activeName
    },
    on: {
      changeTab: function changeTab($event) {
        _vm.activeName = "meal1";
      }
    }
  })] : _vm._e(), _vm.activeName !== "base" ? [_c("meal-info", {
    ref: "mealInfo",
    attrs: {
      "draft-id": _vm.draftId,
      "current-meal": _vm.currentMeal
    },
    on: {
      setTab: _vm.setTab,
      addTab: _vm.addTab
    }
  })] : _vm._e()], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;