"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStore = exports.useRouter = exports.useRoute = void 0;
var _vue = require("vue");
// 访问vuex
var useStore = function useStore() {
  var vm = (0, _vue.getCurrentInstance)();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$store;
};
// 访问router
exports.useStore = useStore;
var useRouter = function useRouter() {
  var vm = (0, _vue.getCurrentInstance)();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$router;
};
// 访问route
exports.useRouter = useRouter;
var useRoute = function useRoute() {
  var vm = (0, _vue.getCurrentInstance)();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$route;
};
exports.useRoute = useRoute;