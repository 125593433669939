"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typeMap = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _xcx = require("@/api/xcx");
var _typeMap;
var typeMap = (_typeMap = {
  home: {
    NAME: '首页推荐位',
    TYPE: 1
  },
  user: {
    NAME: '我的推荐位',
    TYPE: 1,
    APIGET: _xcx.getBannerInfo,
    APISAVE: _xcx.saveBannerInfo
  },
  bike: {
    NAME: '我的电动',
    TYPE: 2,
    APIGET: _xcx.getBannerInfo,
    APISAVE: _xcx.saveBannerInfo
  }
}, (0, _defineProperty2.default)(_typeMap, "bike", {
  NAME: '分享配置',
  TYPE: 3,
  APIGET: _xcx.getBannerInfo,
  APISAVE: _xcx.saveBannerInfo
}), (0, _defineProperty2.default)(_typeMap, "invite", {
  NAME: '邀请配置',
  TYPE: 4,
  APIGET: _xcx.getBannerInfo,
  APISAVE: _xcx.saveBannerInfo
}), (0, _defineProperty2.default)(_typeMap, "recommend", {
  NAME: '首页推荐位',
  TYPE: 5,
  APIGET: _xcx.getBannerInfo,
  APISAVE: _xcx.saveBannerInfo
}), (0, _defineProperty2.default)(_typeMap, "guard", {
  NAME: '使用指南',
  TYPE: 6,
  APIGET: _xcx.getBannerInfo,
  APISAVE: _xcx.saveBannerInfo
}), (0, _defineProperty2.default)(_typeMap, "business_one", {
  NAME: '招商详情',
  TYPE: 7,
  APIGET: _xcx.getBannerInfo,
  APISAVE: _xcx.saveBannerInfo
}), (0, _defineProperty2.default)(_typeMap, "business_two", {
  NAME: '招商详情',
  TYPE: 8,
  APIGET: _xcx.getBannerInfo,
  APISAVE: _xcx.saveBannerInfo
}), _typeMap);

// 使用typeMap来获取对应的值
exports.typeMap = typeMap;
function getTypeNAME(TYPE) {
  return typeMap[TYPE] || '未知类型';
}