"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _goodsAdd = _interopRequireDefault(require("./goodsAdd"));
var _default = {
  components: {
    goodsAdd: _goodsAdd.default,
    cropUpload: _cropUpload.default
  },
  props: ['goodsNum', 'levelName', 'compFormData', 'imgWidth', 'imgHeight'],
  data: function data() {
    return {
      formData: {
        main_title: '',
        background_image: '',
        background_color: '#FFFFFF',
        goods: []
      },
      photoWidth: 359
    };
  },
  computed: {
    photoHeight: function photoHeight() {
      var height = 359 * this.imgHeight / this.imgWidth;
      return height;
    }
  },
  watch: {
    compFormData: function compFormData(val) {
      this.formData = (0, _objectSpread2.default)({}, val);
      if (this.levelName == 'first_level') {
        this.formData.background_color = this.formData.background_color ? this.formData.background_color : '#FFFFFF';
      }
    }
  },
  methods: {
    setGoods: function setGoods(list) {
      this.formData.goods = list;
    },
    getSubmitData: function getSubmitData() {
      var goodsidStr = '';
      if (this.formData.goods.length > 0) {
        if (!this.formData.main_title) {
          this.$message({
            message: "主标题不能为空",
            type: "error"
          });
          return false;
        }
        goodsidStr += this.formData.goods.map(function (k) {
          return k.goods_id;
        }).join(',');
      }
      return {
        level: this.levelName,
        background_image: this.formData.background_image,
        main_title: this.formData.main_title,
        background_color: this.formData.background_color,
        goodsidStr: goodsidStr
      };
    }
  }
};
exports.default = _default;