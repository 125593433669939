"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "申请完结",
      visible: _vm.dialogVisible,
      width: "500px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上传图片：",
      "label-width": "120px",
      prop: "request_img"
    }
  }, [_c("crop-upload", {
    attrs: {
      "show-type": "photo",
      "init-val": _vm.formData.request_img,
      "file-size": "100K",
      "wrapper-width": "95px",
      "wrapper-height": "95px"
    },
    on: {
      setVal: function setVal(val) {
        return _vm.setUrl(val);
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "备注：",
      "label-width": "120px"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3,
      placeholder: "请输入备注"
    },
    model: {
      value: _vm.formData.request_remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "request_remark", $$v);
      },
      expression: "formData.request_remark"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("提交")]), _c("el-button", {
    attrs: {
      type: "primary",
      plain: ""
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;