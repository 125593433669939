"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delOrderEmeConById = delOrderEmeConById;
exports.getListEmeConByOrderId = getListEmeConByOrderId;
exports.orderListExport = orderListExport;
exports.saveOrderEmeCon = saveOrderEmeCon;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 *订单紧急联系人管理
 */
// 添加或修改联系人
function saveOrderEmeCon(params) {
  return _fetch.default.post("/Supplier/OrderEC/saveOrderEmeCon", params);
}
// 删除联系人
function delOrderEmeConById(params) {
  return _fetch.default.post("/Supplier/OrderEC/delOrderEmeConById", params);
}

// 获取联系人
function getListEmeConByOrderId(params) {
  return _fetch.default.get("/Supplier/OrderEC/getListEmeConByOrderId", params);
}
// 订单列表导出
function orderListExport(params) {
  return _fetch.default.export("/Supplier/OrderEC/orderListExport", params, "订单列表");
}