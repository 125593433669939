"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "main_info"
  }, [_vm._m(0), _c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("公司名称：")]), _c("p", [_vm._v(_vm._s(_vm.infoData.mechanism))])]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("工商注册号：")]), _c("p", [_vm._v(_vm._s(_vm.infoData.companyid))])]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("公司所在区域：")]), _c("p", [_vm._v(_vm._s(_vm.infoData.company_area))])]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("公司地址：")]), _c("p", [_vm._v(_vm._s(_vm.infoData.company))])]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("营业执照：")]), _vm.infoData.shopcard ? _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.shopcard,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: true
    }
  })], 1) : _c("p", [_vm._v("暂未上传")])]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("公司门头照：")]), _vm.infoData.doorphoto ? _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.doorphoto,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: true
    }
  })], 1) : _c("p", [_vm._v("暂未上传")])]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("公章图片：")]), _vm.infoData.seal_url ? _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.seal_url,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: true
    }
  })], 1) : _c("p", [_vm._v("暂未上传")])])]), _vm._m(1), _c("div", {
    staticClass: "flex flex-wrap list_info"
  }, [_c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("法人姓名：")]), _c("p", [_vm._v(_vm._s(_vm.infoData.faname))])]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("法人身份证号：")]), _c("p", [_vm._v(_vm._s(_vm.infoData.idcard))])]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("身份证正面照：")]), _vm.infoData.faidcard ? _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.faidcard,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: true
    }
  })], 1) : _c("p", [_vm._v("暂未上传")])]), _c("div", {
    staticClass: "list_item"
  }, [_c("label", [_vm._v("身份证背面照：")]), _vm.infoData.faidcard_back ? _c("div", [_c("crop-upload", {
    ref: "cropUpload",
    attrs: {
      "show-type": "photo",
      "init-val": _vm.infoData.faidcard_back,
      "wrapper-width": "95px",
      "wrapper-height": "95px",
      "photo-width": 95,
      "photo-height": 95,
      hideDelete: true
    }
  })], 1) : _c("p", [_vm._v("暂未上传")])])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("营业执照信息")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label_title"
  }, [_c("p", [_vm._v("法人证件信息")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;