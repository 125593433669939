"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "goods_add"
  }, [_vm._l(_vm.goodsList, function (item, index) {
    return _c("goods-link", {
      key: item.goods_id,
      attrs: {
        "goods-data": item,
        "show-tool": true
      },
      on: {
        handleDelete: function handleDelete($event) {
          return _vm.deleteGoods(index);
        },
        upSort: function upSort($event) {
          return _vm.upSort(index);
        },
        downSort: function downSort($event) {
          return _vm.downSort(index);
        }
      }
    });
  }), _vm.goodsList.length < _vm.maxNum ? _c("div", {
    staticClass: "add-div",
    on: {
      click: _vm.openSelect
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v("添加商品(" + _vm._s(_vm.goodsList.length) + "/" + _vm._s(_vm.maxNum) + ")\n  ")]) : _vm._e(), _c("goods-dialog", {
    ref: "goodsDialog",
    attrs: {
      multiple: true,
      maxNum: _vm.maxNum,
      "show-checked-tool": true
    },
    on: {
      setSelect: _vm.setSelect
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;