"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "500px",
      title: "修改用户信息",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    staticClass: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "邮箱地址",
      prop: "email"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "请输入邮箱"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "详细地址",
      prop: "real_addr"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 4,
      placeholder: "请输入身份证上的详细地址",
      maxlength: 200
    },
    model: {
      value: _vm.form.real_addr,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "real_addr", $$v);
      },
      expression: "form.real_addr"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "商品颜色",
      prop: "goods_colour"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: "商品颜色"
    },
    model: {
      value: _vm.form.goods_colour,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "goods_colour", $$v);
      },
      expression: "form.goods_colour"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;