"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.formData,
      "label-width": "140px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商品名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "商品名称"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "商品图片",
      prop: "image"
    }
  }, [_c("cropUpload", {
    ref: "cropUpload",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "init-val": _vm.formData.image,
      "wrapper-width": "250px",
      "wrapper-height": "140px",
      "photo-width": 250,
      "photo-height": 140,
      maxNum: 1
    },
    on: {
      setVal: _vm.imgChange
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "商品数量",
      prop: "quantity"
    }
  }, [_c("el-input-number", {
    attrs: {
      placeholder: "商品数量",
      "controls-position": "right"
    },
    model: {
      value: _vm.formData.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "quantity", $$v);
      },
      expression: "formData.quantity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "已兑换数量",
      prop: "has_redeemed"
    }
  }, [_c("el-input-number", {
    attrs: {
      placeholder: "已兑换数量",
      "controls-position": "right"
    },
    model: {
      value: _vm.formData.has_redeemed,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "has_redeemed", $$v);
      },
      expression: "formData.has_redeemed"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "商品描述",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "商品描述"
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "所需积分",
      prop: "point"
    }
  }, [_c("el-input-number", {
    attrs: {
      placeholder: "所需积分",
      "controls-position": "right"
    },
    model: {
      value: _vm.formData.point,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "point", $$v);
      },
      expression: "formData.point"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;