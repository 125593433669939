"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default2 = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: {
    editItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        id: 0,
        app_name: '',
        key: '',
        name: '',
        status: 1
      },
      appList: ['银行卡账号'],
      rules: {
        app_name: [{
          required: true,
          message: '请选择应用',
          trigger: 'blur'
        }],
        key: [{
          required: true,
          message: '请输入渠道key',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入渠道名',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return '渠道 -' + (this.formData.id == 0 ? '新增' : '编辑');
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          _this.formData = Object.assign(_this.formData, _this.editItem);
          _this.formData.app_name = _this.formData.app_name ? _this.formData.app_name : _this.appList[0];
        });
      } else {
        this.formData = this.$options.data().formData;
        this.$refs.formData.clearValidate();
      }
    }
  },
  methods: {
    open: function open() {
      this.dialogVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    changeRadio: function changeRadio(val) {
      this.formData.status = val;
    },
    submitForm: function submitForm() {
      var _this2 = this;
      console.log('submitForm');
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          (0, _marketing.editChannel)({
            form_data: _this2.formData
          }).then(function (res) {
            _this2.$emit('getList');
            _this2.close();
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default2;