"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex align-center top_nav"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      "icon-class": "home",
      size: 11
    }
  }), _c("el-breadcrumb", {
    attrs: {
      separator: "/"
    }
  }, [_vm._l(_vm.list, function (item, index) {
    return [item.pathName ? _c("el-breadcrumb-item", {
      key: index,
      attrs: {
        to: {
          name: item.pathName
        }
      }
    }, [_vm._v(_vm._s(item.title))]) : _c("el-breadcrumb-item", {
      key: index
    }, [_vm._v(_vm._s(item.title))])];
  }), _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.$route.meta.title))])], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;