"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "风险地区",
      visible: _vm.dialogVisible,
      width: "800px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.tableData,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      prop: "id",
      label: "id",
      width: "60"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "risk_type",
      label: "风险等级"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.risk_type == 1 ? _c("el-tag", {
          attrs: {
            type: "warning"
          }
        }, [_vm._v("中风险")]) : _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v("高风险")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "province",
      label: "省"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "city",
      label: "市"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "district",
      label: "区"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "street",
      label: "地址"
    }
  })], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("关闭")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;