"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _secBanner = _interopRequireDefault(require("./child/secBanner"));
var _validate = require("@/utils/validate");
var _xcx = require("@/api/xcx");
var _merchantSelect = _interopRequireDefault(require("@/components/merchant/merchantSelect.vue"));
var _default = {
  components: {
    secBanner: _secBanner.default,
    MerchantSelector: _merchantSelect.default
  },
  data: function data() {
    return {
      type: 1,
      title: '我的推荐位',
      bannerInfo: {
        status: 0
      },
      bannerList: [],
      merchant_id: ''
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    handleMerchantIdUpdate: function handleMerchantIdUpdate(merchantId) {
      this.merchant_id = merchantId;
      console.log('this.searchForm', this.merchant_id);
      this.getData();
    },
    init: function init() {
      this.merchant_id = this.sMerchantId;
      this.getData();
    },
    getData: function getData() {
      var _this = this;
      if (!this.merchant_id) {
        this.$message({
          message: '请先选择商户',
          type: 'warning'
        });
        return;
      }
      (0, _xcx.getBannerInfo)({
        type: this.type,
        merchant_id: this.merchant_id
      }).then(function (res) {
        console.log('getBannerInfo', res);
        if (res) {
          _this.bannerInfo = res;
          _this.bannerList = res.banner_list ? res.banner_list : [];
          _this.$refs.secBanner.bannerList = (0, _toConsumableArray2.default)(_this.bannerList);
        }
      });
    },
    // 保存顶部导航栏
    save: function save() {
      var _this2 = this;
      var list = (0, _toConsumableArray2.default)(this.$refs.secBanner.bannerList);
      if (this.validateBannerList(list)) {
        if (!this.merchant_id) {
          this.$message({
            message: '请先选择商户',
            type: 'warning'
          });
          return;
        }
        var param = {
          type: this.type,
          status: this.bannerInfo.status,
          merchant_id: this.merchant_id,
          banner_list: JSON.stringify(list)
        };
        (0, _xcx.saveBannerInfo)(param).then(function (res) {
          // console.log('saveBannerInfo', res)
          _this2.getData();
        });
      }
    },
    // 校验轮播图列表
    validateBannerList: function validateBannerList(list) {
      if (list.length === 0) {
        this.$message({
          message: '请添加轮播图',
          type: 'warning'
        });
        return false;
      }
      for (var index = 0; index < list.length; index++) {
        var num = index + 1;
        if (!list[index].img) {
          this.$message({
            message: '请添加轮播图' + num + '的图片',
            type: 'warning'
          });
          return false;
        }
        if (!list[index].jumpUrl) {
          this.$message({
            message: '请添加轮播图' + num + '的链接',
            type: 'warning'
          });
          return false;
        }
      }
      return true;
    }
  }
};
exports.default = _default;