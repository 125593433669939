"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.autoRecharge = autoRecharge;
exports.autoRechargeInfo = autoRechargeInfo;
exports.batchSmsInfo = batchSmsInfo;
exports.buyInfo = buyInfo;
exports.delChannel = delChannel;
exports.editChannel = editChannel;
exports.getChannelList = getChannelList;
exports.getRiskCreditBuyLog = getRiskCreditBuyLog;
exports.getShopRiskCreditList = getShopRiskCreditList;
exports.getShopRiskCreditStuatus = getShopRiskCreditStuatus;
exports.getSmsTemplate = getSmsTemplate;
exports.riskCreditAutoRecharge = riskCreditAutoRecharge;
exports.setBatchSms = setBatchSms;
exports.setSingleSms = setSingleSms;
exports.singleSmsInfo = singleSmsInfo;
exports.smsRecord = smsRecord;
exports.smsRemind = smsRemind;
exports.toBuy = toBuy;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 增值服务
 */
// 购买信息
function buyInfo(type) {
  return _fetch.default.get("/Supplier/IncrementService/buyInfo", {
    type: type
  });
}
// 去购买
function toBuy(params) {
  return _fetch.default.post("/Supplier/IncrementService/toBuy", params, true);
}
// 自动充值信息
function autoRechargeInfo(type) {
  return _fetch.default.get("/Supplier/IncrementService/autoRechargeInfo", {
    type: type
  });
}
// 自动充值
function autoRecharge(params) {
  return _fetch.default.post("/Supplier/IncrementService/autoRecharge", params, true);
}
// 短信提醒
function smsRemind() {
  return _fetch.default.get("/Supplier/IncrementService/smsRemind");
}
// 获取单个短信信息
function singleSmsInfo(sms_template_id) {
  return _fetch.default.get("/Supplier/IncrementService/singleSmsInfo", {
    sms_template_id: sms_template_id
  });
}
// 获取批量短信信息
function batchSmsInfo(sms_type) {
  return _fetch.default.get("/Supplier/IncrementService/batchSmsInfo", {
    sms_type: sms_type
  });
}
// 设置单个短信
function setSingleSms(params) {
  return _fetch.default.post("/Supplier/IncrementService/setSingleSms", params, true);
}
// 设置批量短信
function setBatchSms(params) {
  return _fetch.default.post("/Supplier/IncrementService/setBatchSms", params, true);
}
// 获取短信发送场景
function getSmsTemplate() {
  return _fetch.default.get("/Supplier/IncrementService/getSmsTemplate");
}
// 短信发送记录
function smsRecord(params) {
  return _fetch.default.post("/Supplier/IncrementService/smsRecord", params);
}

// 百融风控查询列表
function getShopRiskCreditList(params) {
  return _fetch.default.post("/Supplier/IncrementService/getShopRiskCreditList", params);
}
// 查看店铺百融风控开启状态
function getShopRiskCreditStuatus() {
  return _fetch.default.get("/Supplier/IncrementService/getShopRiskCreditStuatus");
}
// 获取百融风控购买记录
function getRiskCreditBuyLog(params) {
  return _fetch.default.get("/Supplier/IncrementService/getRiskCreditBuyLog", params);
}
// 百融风控自动充值
function riskCreditAutoRecharge(params) {
  return _fetch.default.get("/Supplier/IncrementService/riskCreditAutoRecharge", params, true);
}

// 渠道列表
function getChannelList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _fetch.default.post("/Supplier/IncrementService/getChannelList", params);
}
// 编辑渠道
function editChannel(params) {
  return _fetch.default.post("/Supplier/IncrementService/editChannel", params, true);
}
// 编辑渠道
function delChannel(params) {
  return _fetch.default.post("/Supplier/IncrementService/delChannel", params, true);
}