"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _setAddress = _interopRequireDefault(require("./dialogs/setAddress"));
var _default = {
  components: {
    customPage: _customPage.default,
    setAddress: _setAddress.default
  },
  data: function data() {
    return {
      searchForm: {
        province: "",
        city: "",
        district: "",
        street: "",
        di_risky: ""
      },
      total: 0,
      tableData: [],
      editItem: {}
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _order.getRiskAreaList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
        // console.log(this.tableData.length)
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      var searchForm = this.$options.data().searchForm;
      this.searchForm = (0, _objectSpread2.default)({}, searchForm);
      this.getList();
    },
    // 修改地址
    editAddress: function editAddress() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (item) {
        this.editItem = (0, _objectSpread2.default)({}, item);
      } else {
        this.editItem = {
          id: 0
        };
      }
      this.$refs.setAddress.dialogVisible = true;
    },
    delAddress: function delAddress(id) {
      var _this2 = this;
      (0, _order.delRiskArea)(id).then(function (res) {
        _this2.getList();
      });
    }
  }
};
exports.default = _default;