"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      width: "800px",
      title: "认证资料",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "form-container"
  }, [_c("el-form", {
    ref: "formRef",
    staticClass: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.submitForm,
      "label-width": "130px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "代理人",
      prop: "is_proxy"
    }
  }, [_c("el-switch", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      "active-value": 1,
      "inactive-value": 0
    },
    model: {
      value: _vm.submitForm.is_proxy,
      callback: function callback($$v) {
        _vm.$set(_vm.submitForm, "is_proxy", $$v);
      },
      expression: "submitForm.is_proxy"
    }
  })], 1), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.submitForm.is_proxy ? _c("el-form-item", {
    attrs: {
      prop: "proxy_percent",
      label: "分佣比例"
    }
  }, [_c("el-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      min: 0,
      placeholder: "请输入分佣比例（0到1）"
    },
    model: {
      value: _vm.submitForm.proxy_percent,
      callback: function callback($$v) {
        _vm.$set(_vm.submitForm, "proxy_percent", $$v);
      },
      expression: "submitForm.proxy_percent"
    }
  })], 1) : _vm._e()], 1), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.submitForm.is_proxy ? _c("el-form-item", {
    attrs: {
      prop: "cycle",
      label: "分佣周期(月)"
    }
  }, [_c("el-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      min: 1,
      placeholder: "请输入分佣周期（月份数）"
    },
    model: {
      value: _vm.submitForm.cycle,
      callback: function callback($$v) {
        _vm.$set(_vm.submitForm, "cycle", $$v);
      },
      expression: "submitForm.cycle"
    }
  })], 1) : _vm._e()], 1), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.submitForm.is_proxy ? _c("el-form-item", {
    attrs: {
      prop: "amount",
      label: "分佣总金额(元)"
    }
  }, [_c("el-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      min: 1,
      placeholder: "请输入分佣金额"
    },
    model: {
      value: _vm.submitForm.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.submitForm, "amount", $$v);
      },
      expression: "submitForm.amount"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.closeDialog("formRef");
      }
    }
  }, [_vm._v("关闭")]), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: function click($event) {
        return _vm.handleSubmit("formRef");
      }
    }
  }, [_vm._v("保存")])], 1), _vm.isShow ? _c("el-image-viewer", {
    attrs: {
      "on-close": _vm.close,
      "url-list": _vm.image,
      "z-index": 9000
    }
  }) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;