"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _index = require("@/utils/index");
var _default = {
  data: function data() {
    return {
      currentDate: '',
      tableData: []
    };
  },
  methods: {
    getData: function getData() {
      this.currentDate = (0, _index.formatDate)(new Date(), 'yyyy-MM-dd hh:mm');
      console.log(sessionStorage.getItem('amount'));
      this.tableData = [{
        account: this.userName,
        type: '银行卡账号',
        amount: sessionStorage.getItem('amount'),
        out_trade_no: sessionStorage.getItem('out_trade_no')
      }];
    }
  },
  created: function created() {
    this.getData();
  },
  computed: {
    userName: function userName() {
      return (0, _auth.getName)();
    }
  },
  beforeDestroy: function beforeDestroy() {
    sessionStorage.removeItem('amount');
    sessionStorage.removeItem('out_trade_no');
  }
};
exports.default = _default;