"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "return_ad"
  }, [_c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _c("p", [_vm._v("归还地址为用户申请归还商品时，订单页面展示的物流归还地址。")])], 1), _c("div", {
    staticClass: "flex align-center table-top-btns"
  }, [_c("h5", {
    staticClass: "flex align-center"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      "icon-class": "list",
      size: 15
    }
  }), _vm._v("数据列表\n      ")], 1), _vm.$hasMethod("#add") ? _c("el-button", {
    staticClass: "add-btn",
    attrs: {
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "shopAddReturnAd"
        });
      }
    }
  }, [_vm._v("添加归还地址")]) : _vm._e()], 1), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      fixed: "",
      prop: "id",
      label: "ID",
      width: "70",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "merchant_name",
      label: "商户名",
      align: "center",
      width: "110"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "realname",
      label: "收货人姓名",
      align: "center",
      width: "110"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "mobile",
      label: "手机",
      width: "120",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "province",
      label: "省份",
      width: "80",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "city",
      label: "城市",
      width: "80",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "area",
      label: "区/县",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "地址",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("p", [_vm._v(_vm._s(scope.row.address) + "\n            "), scope.row.isdefault == 1 ? _c("el-tag", {
          staticClass: "tagClass",
          attrs: {
            size: "small",
            type: "danger"
          }
        }, [_vm._v("默认地址")]) : _vm._e()], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "addtime",
      label: "创建时间",
      align: "center",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "状态",
      width: "80",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          staticClass: "tagClass",
          class: {
            tag_success: scope.row.status == 2,
            tag_danger: scope.row.status == 1,
            pointer: _vm.$hasMethod("#enable")
          },
          attrs: {
            size: "small",
            type: scope.row.status == 2 ? "success" : "danger"
          },
          on: {
            click: function click($event) {
              return _vm.changeStatus(scope.row.id, scope.row.status);
            }
          }
        }, [_vm._v(_vm._s(scope.row.status == 1 ? "禁用" : "启用") + "\n          ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "280",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.$hasMethod("#edit") ? _c("el-button", {
          attrs: {
            size: "small",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.editItem(scope.row.id);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), _vm.$hasMethod("#delete") ? _c("el-button", {
          attrs: {
            size: "small",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.deleteItem(scope.row.id);
            }
          }
        }, [_vm._v("删除")]) : _vm._e(), _vm.$hasMethod("#set") && scope.row.isdefault != 1 ? _c("el-button", {
          attrs: {
            size: "small",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.setItem(scope.row.id);
            }
          }
        }, [_vm._v("设为默认地址")]) : _vm._e()];
      }
    }])
  })], 1), _c("custom-page", {
    ref: "customPage",
    attrs: {
      total: _vm.total
    },
    on: {
      getList: _vm.getList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;