"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user.js");
var _imageViewer = _interopRequireDefault(require("element-ui/packages/image/src/image-viewer"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _default = {
  components: {
    ElImageViewer: _imageViewer.default,
    cropUpload: _cropUpload.default
  },
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      isShow: false,
      image: [],
      options: [],
      rules: {
        // 加一个userIds的校验
        cycle: [{
          required: true,
          message: '请选择分佣周期',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '必须为数字值'
        }],
        amount: [{
          required: true,
          message: '请输入分佣金额',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '必须为数字值'
        }]
      },
      proxyInfo: {},
      cycleList: [{
        value: 6,
        label: '6个月'
      }, {
        value: 12,
        label: '12个月'
      }],
      submitForm: {
        amount: null,
        cycle: null,
        id: null,
        proxyed_name: null
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.idcard_back = this.editItem.idcard_back;
        this.idcard_front = this.editItem.idcard_front;
        var _this$editItem = this.editItem,
          amount = _this$editItem.amount,
          cycle = _this$editItem.cycle,
          id = _this$editItem.id,
          proxyed_name = _this$editItem.proxyed_name;
        this.submitForm = {
          amount: amount,
          cycle: cycle,
          id: id,
          proxyed_name: proxyed_name
        };
      } else {
        this.resetForm('formRef');
      }
    }
  },
  methods: {
    // 处理shareUserList；得到[{label:'xxx',value:'xxx'}]格式
    handleOptions: function handleOptions() {
      this.options = this.proxyInfo.shareUserList.map(function (item) {
        return {
          label: item.cert_name,
          value: item.id,
          disabled: item.is_proxy == 1 ? true : false
        };
      });
    },
    closeDialog: function closeDialog(formName) {
      this.resetForm(formName);
      this.dialogVisible = false;
    },
    validate: function validate(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var params = _this.submitForm;
          (0, _user.setProxyMemberInfo)(params).then(function (res) {
            console.log('setProxyMemberInfo', res);
            _this.closeDialog('formRef');
            _this.$emit('getList', true);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 注意后端拿到数据的时候，优先处理是否为代理人设置，如果关闭，则不处理其他字段
    handleSubmit: function handleSubmit(formName) {
      console.log('submitForm', this.submitForm);
      this.validate(formName);
      // this.dialogVisible = false
    },
    handleChangeUser: function handleChangeUser(val) {
      this.submitForm.userIds = val;
      console.log('handleChangeUser', val);
    },
    showImg: function showImg(img) {
      this.image = [this.$globalObj.imgBaseUrl + img];
      // console.log('showImg', this.image)
      this.isShow = true;
    },
    // 关闭预览
    close: function close() {
      this.isShow = false;
      this.image = [];
    },
    imgChange: function imgChange(val, type) {
      var _this2 = this;
      if (val) {
        var param = {
          uid: this.editItem.uid
        };
        param[type] = val;
        editMemberInfo(param).then(function (res) {
          _this2[type] = val;
          _this2.$emit('getList', true);
        });
      } else {
        this[type] = val;
      }
    }
  }
};
exports.default = _default;