"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper"
  }, [_c("div", {
    staticClass: "fixed-header"
  }, [_c("navbar")], 1), _vm.$route.name === "editPwd" ? _c("pwd-edit") : _c("div", {
    staticClass: "main-content"
  }, [_c("sidebar", {
    staticClass: "sidebar-container"
  }), _c("div", {
    staticClass: "main-container"
  }, [_c("top-nav"), _c("app-main")], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;