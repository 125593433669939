"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getChannelOrderList = getChannelOrderList;
exports.getOrderStatus = getOrderStatus;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 *  游客免登录接口
 */
// 获取渠道引流订单
function getChannelOrderList(params) {
  return _fetch.default.post("/Supplier/Guest/getChannelOrderList", params);
}

// 获取渠道引流订单
function getOrderStatus() {
  return _fetch.default.post("/Supplier/Guest/getOrderStatus");
}