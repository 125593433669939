"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _payHistory = _interopRequireDefault(require("./payHistory.vue"));
var _showAreaDetail = _interopRequireDefault(require("./showAreaDetail.vue"));
var _default = {
  components: {
    payHistory: _payHistory.default,
    showMap: _showAreaDetail.default
  },
  data: function data() {
    return {};
  },
  methods: {}
};
exports.default = _default;