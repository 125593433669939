"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _default = {
  props: ['orderId', 'orderNo'],
  components: {},
  data: function data() {
    return {
      dialogVisible: false,
      tableData: [],
      selectedItem: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getRefushDistribution();
      }
    }
  },
  methods: {
    getRefushDistribution: function getRefushDistribution() {
      var _this = this;
      (0, _order.getRefushDistribution)(this.orderId).then(function (res) {
        _this.tableData = res || [];
      });
    }
  }
};
exports.default = _default;