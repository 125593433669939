"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "two_col_goods"
  }, [_c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _vm._m(0)], 1), _c("col-form", {
    ref: "colForm1",
    attrs: {
      type: "twoColGoods",
      title: "二栏式1",
      "goods-num": 2,
      "form-data": _vm.configData[0]
    }
  }), _c("col-form", {
    ref: "colForm2",
    attrs: {
      type: "twoColGoods",
      title: "二栏式2",
      "goods-num": 2,
      "form-data": _vm.configData[1]
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", [_vm._v("图片要求！")]), _c("br"), _vm._v("图片宽度344px，高度292px。")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;