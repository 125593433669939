"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: ['editItem', 'roleList'],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        username: '',
        pwd: '',
        confirmPwd: '',
        role_id: null,
        mobile: ''
      },
      tableData: [{}, {}],
      rules: {
        username: [{
          required: true,
          message: '请选择商户名称',
          trigger: 'change'
        }],
        role_id: [{
          required: true,
          message: '请选择终端状态',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {} else {
        this.reset();
      }
    }
  },
  methods: {
    add: function add() {
      this.tableData.push({});
      this.reset();
    },
    reset: function reset() {
      this.form = this.$options.data().form;
      this.$refs['form'].resetFields();
    },
    removeItem: function removeItem(index) {
      this.tableData.splice(index, 1);
    },
    submit: function submit() {
      var _this = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          // editUser({
          //     id: this.form.id,
          //     username: this.form.username,
          //     pwd: this.form.pwd ? this.$md5(this.form.pwd) : '',
          //     role_id: this.form.role_id,
          // }).then((res) => {
          _this.dialogVisible = false;
          //     this.$emit('getList')
          // })
        } else {
          // this.$message({
          //     message: '请检查表单错误',
          //     type: 'error',
          // })
          return false;
        }
      });
    }
  }
};
exports.default = _default;