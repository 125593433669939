"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.formData,
      "label-width": "140px"
    }
  }, [_vm.sMerchantId == 0 ? [_c("el-form-item", {
    attrs: {
      label: "商户名称",
      prop: "merchant_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.merchant_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "merchant_id", $$v);
      },
      expression: "formData.merchant_id"
    }
  }, _vm._l(_vm.merchantList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.username,
        value: item.id
      }
    });
  }), 1)], 1)] : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "优惠券名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入礼包名称"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "抵用金额(元)",
      prop: "amount"
    }
  }, [_c("el-input-number", {
    attrs: {
      "controls-position": "right"
    },
    model: {
      value: _vm.formData.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "amount", $$v);
      },
      expression: "formData.amount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "门槛金额(元)",
      prop: "min_amount"
    }
  }, [_c("el-input-number", {
    attrs: {
      "controls-position": "right"
    },
    model: {
      value: _vm.formData.min_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "min_amount", $$v);
      },
      expression: "formData.min_amount"
    }
  }), _c("span", [_vm._v(" (0 为无限制)")])], 1), _c("el-form-item", {
    attrs: {
      label: "优惠券有效期(天)",
      prop: "expire_day"
    }
  }, [_c("el-input-number", {
    attrs: {
      "controls-position": "right"
    },
    model: {
      value: _vm.formData.expire_day,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "expire_day", $$v);
      },
      expression: "formData.expire_day"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "开启",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "关闭",
      value: 0
    }
  })], 1)], 1)], 2), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;