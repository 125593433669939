"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "recharge"
  }, [_vm._m(0), _c("div", {
    staticStyle: {
      "background-color": "#f5f5f5",
      padding: "15px 0"
    }
  }, [_c("div", {
    staticClass: "pay_code"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("h5", {
    staticClass: "bold-font"
  }, [_vm._v("支付金额")]), _c("p", {
    staticClass: "red-font bold-font",
    staticStyle: {
      "font-size": "35px",
      margin: "20px 0"
    }
  }, [_vm._v("￥" + _vm._s(_vm.amount))]), _c("div", {
    staticClass: "flex"
  }, [_vm._m(1), _c("p", {
    staticClass: "flex-1 color-666"
  }, [_c("label", {
    staticClass: "bold-font"
  }, [_vm._v("供应商名称：")]), _vm._v(_vm._s(_vm.userName))])])]), _c("div", {
    staticClass: "text-center bottom"
  }, [_c("p", {
    staticClass: "color:666"
  }, [_vm._v("距离二维码过期：")]), _c("p", {
    staticClass: "red-font bold-font",
    staticStyle: {
      "font-size": "30px",
      margin: "10px 0 40px"
    }
  }, [_vm._v(_vm._s(_vm.minute) + ":" + _vm._s(_vm.seconds))]), _c("div", {
    staticClass: "code_wrap"
  }, [_c("el-image", {
    staticStyle: {
      width: "250px",
      height: "250px"
    },
    attrs: {
      src: _vm.code_url
    }
  }, [_c("div", {
    staticClass: "image-slot",
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_c("i", {
    staticClass: "el-icon-picture-outline"
  })])]), _vm._m(2)], 1), _c("p", {
    staticClass: "blue-font pointer",
    staticStyle: {
      "margin-top": "40px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "recharge"
        });
      }
    }
  }, [_vm._v("返回选择其他支付方式")])])])]), _c("div", {
    staticClass: "login_footer"
  })]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "login_top flex"
  }, [_c("div", {
    staticClass: "left_logo"
  }), _c("div", {
    staticClass: "flex-1 flex flex-between-center"
  }, [_c("p", {
    staticStyle: {
      "font-size": "24px",
      "padding-left": "35px"
    }
  }, [_vm._v("充值中心")]), _c("div", {
    staticClass: "flex align-center"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "28px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("../../assets/images/tel.png"),
      alt: "咨询热线"
    }
  }), _c("div", {
    staticClass: "flex-1",
    staticStyle: {
      "line-height": "18px"
    }
  }, [_c("p", [_vm._v("咨询热线")]), _c("p", {
    staticClass: "blue-font"
  }, [_vm._v("400-686-8341")])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", {
    staticClass: "flex-1 color-666"
  }, [_c("label", {
    staticClass: "bold-font"
  }, [_vm._v("交易类型：")]), _vm._v("店铺金额充值")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex align-center",
    staticStyle: {
      padding: "4px 30px 24px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "42px",
      height: "42px",
      "margin-right": "25px"
    },
    attrs: {
      src: require("../../assets/images/scan.png"),
      alt: "扫码"
    }
  }), _c("p", {
    staticClass: "flex-1",
    staticStyle: {
      "font-size": "16px",
      "line-height": "16px"
    }
  }, [_vm._v("打开手机支付宝扫一扫继续付款")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;