"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _default = {
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      loading: true,
      leaseList: false,
      contractInfo: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      } else {
        this.leaseList = this.$options.data().leaseList;
      }
    }
  },
  methods: {
    init: function init() {
      this.getLease();
      this.getOrderContract();
    },
    getLease: function getLease() {
      var _this = this;
      this.loading = true;
      (0, _order.getLease)({
        orderId: this.editItem.id
      }).then(function (res) {
        console.log('getLease', res);
        _this.leaseList = res.data || [];
        _this.loading = false;
      });
    },
    getOrderContract: function getOrderContract() {
      var _this2 = this;
      (0, _order.getOrderContract)({
        orderId: this.editItem.id
      }).then(function (res) {
        console.log('getOrderContract', res);
        _this2.contractInfo = res || {};
      });
    }
  }
};
exports.default = _default;