"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "nine_grid"
  }, _vm._l(_vm.iconList, function (item, index) {
    return _c("icon-form", {
      key: index,
      attrs: {
        "img-width": _vm.imgWidth,
        "img-height": _vm.imgHeight,
        "item-index": index,
        "form-data": item
      },
      on: {
        handleDelete: function handleDelete($event) {
          return _vm.deleteItem(index);
        },
        handleAdd: function handleAdd($event) {
          return _vm.addItem(index);
        },
        upSortItem: function upSortItem($event) {
          return _vm.upSortItem(index);
        },
        downSortItem: function downSortItem($event) {
          return _vm.downSortItem(index);
        }
      }
    });
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;