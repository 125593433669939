"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delPrize = delPrize;
exports.delRedeem = delRedeem;
exports.delTask = delTask;
exports.delUser = delUser;
exports.editPrizeConfig = editPrizeConfig;
exports.editRedeemConfig = editRedeemConfig;
exports.editTaskConfig = editTaskConfig;
exports.editUserConfig = editUserConfig;
exports.getAdUserList = getAdUserList;
exports.getPrizeList = getPrizeList;
exports.getRedeemList = getRedeemList;
exports.getTaskConfig = getTaskConfig;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/* *
 * 广告管理
 */

// 奖品
function getPrizeList(params) {
  return _fetch.default.get('/Supplier/adAdmin/getPrizeList', params);
}
function editPrizeConfig(params) {
  return _fetch.default.post('/Supplier/adAdmin/editPrizeConfig', params);
}
function delPrize(params) {
  return _fetch.default.post('/Supplier/adAdmin/delPrize', params, true);
}
// 兑奖
function getRedeemList(params) {
  return _fetch.default.get('/Supplier/adAdmin/getRedeemList', params);
}
function editRedeemConfig(params) {
  return _fetch.default.post('/Supplier/adAdmin/editRedeemConfig', params);
}
function delRedeem(params) {
  return _fetch.default.post('/Supplier/adAdmin/delRedeem', params, true);
}
// 广告用户
function getAdUserList(params) {
  return _fetch.default.get('/Supplier/adAdmin/getAdUserList', params);
}
function editUserConfig(params) {
  return _fetch.default.post('/Supplier/adAdmin/editUserConfig', params);
}
function delUser(params) {
  return _fetch.default.post('/Supplier/adAdmin/delUser', params, true);
}
// 任务
function getTaskConfig(params) {
  return _fetch.default.get('/Supplier/adAdmin/getTaskConfig', params);
}
function editTaskConfig(params) {
  return _fetch.default.post('/Supplier/adAdmin/editTaskConfig', params);
}
function delTask(params) {
  return _fetch.default.post('/Supplier/adAdmin/delTask', params, true);
}