"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.dialogVisible ? _c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "120px"
    }
  }, [_vm.sMerchantId == 0 ? [_c("el-form-item", {
    attrs: {
      label: "商户名称",
      prop: "merchant_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择角色"
    },
    model: {
      value: _vm.form.merchant_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "merchant_id", $$v);
      },
      expression: "form.merchant_id"
    }
  }, _vm._l(_vm.merchantList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.username,
        value: item.id
      }
    });
  }), 1)], 1)] : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "网点名称",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入网点名称"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "网点图片",
      prop: "carousel_img"
    }
  }, [_c("cropUpload", {
    ref: "cropUpload",
    attrs: {
      multiple: true,
      "show-type": "photo",
      "init-val": _vm.form.imgs,
      "wrapper-width": "250px",
      "wrapper-height": "140px",
      "photo-width": 250,
      "photo-height": 140,
      maxNum: 1
    },
    on: {
      setVal: _vm.imgChange
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "网点地址",
      prop: "address"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入网点地址"
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1), _c("div", {
    staticClass: "map"
  }, [_c("mapBox", {
    attrs: {
      initCenter: this.editItem ? {
        lng: _vm.editItem.longitude,
        lat: _vm.editItem.latitude
      } : ""
    },
    on: {
      sendPointInfo: _vm.sendPointInfo
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "经度",
      prop: ""
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "经纬度"
    },
    model: {
      value: _vm.form.longitude,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "longitude", $$v);
      },
      expression: "form.longitude"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "纬度",
      prop: ""
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "经纬度"
    },
    model: {
      value: _vm.form.latitude,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "latitude", $$v);
      },
      expression: "form.latitude"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "网点状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, _vm._l(_vm.branchStatusList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.label,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "网点描述",
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入网点描述"
    },
    model: {
      value: _vm.form.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)], 2), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary",
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;