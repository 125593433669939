"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chkWarn = chkWarn;
exports.deviceWarnLogList = deviceWarnLogList;
exports.saveDeviceWarnLog = saveDeviceWarnLog;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 换电柜报警日志
 */
//
function deviceWarnLogList(params) {
  return _fetch.default.get('/Supplier/deviceWarnLog/getList', params);
}

//
function saveDeviceWarnLog(params) {
  return _fetch.default.get('/Supplier/deviceWarnLog/save', params);
}

// 检测报警未处理记录
function chkWarn(params) {
  return _fetch.default.get('/Supplier/deviceWarnLog/chkWarn', params);
}