"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _default = {
  props: ['editItem'],
  components: {},
  data: function data() {
    return {
      dialogVisible: false,
      tableData: [],
      loading: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getData();
      } else {
        this.tableData = [];
      }
    }
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.loading = true;
      var district;
      if (this.editItem.type == 1) {
        district = this.editItem.al_district;
      } else {
        district = this.editItem.area;
      }
      (0, _order.addressGetRiskArea)({
        district: district
      }).then(function (res) {
        _this.tableData = res || [];
        _this.loading = false;
      });
    }
  }
};
exports.default = _default;