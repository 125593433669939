"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "450px",
      title: "修改用户归属地",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "120px"
    }
  }, [_vm.sMerchantId == 0 ? [_c("el-form-item", {
    attrs: {
      label: "修改商户"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择商户名称",
      clearable: ""
    },
    model: {
      value: _vm.form.merchant_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "merchant_id", $$v);
      },
      expression: "form.merchant_id"
    }
  }, _vm._l(_vm.merchantList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.username,
        value: item.id
      }
    });
  }), 1)], 1)] : _c("h5", {
    staticStyle: {
      margin: "20px"
    }
  }, [_vm._v("是否将用户修改为当前商户下的用户")])], 2), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;