"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      width: "650px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("h5", {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("开启自动充值，防止业务停滞")]), _c("div", {
    staticClass: "flex justify-center",
    staticStyle: {
      "margin-top": "20px",
      "margin-bottom": "10px"
    }
  }, [_vm.type == 2 ? _c("p", {
    staticClass: "font-12 color-666 flex align-center"
  }, [_c("img", {
    staticStyle: {
      width: "18px",
      height: "18px",
      "margin-right": "7px"
    },
    attrs: {
      src: require("../../../assets/images/tip3.png"),
      alt: ""
    }
  }), _vm._v("随时随地，短信提醒\n    ")]) : _vm._e(), _c("p", {
    staticClass: "font-12 color-666 flex align-center",
    style: {
      marginLeft: _vm.type == 2 ? "80px" : "0"
    }
  }, [_c("img", {
    staticStyle: {
      width: "18px",
      height: "18px",
      "margin-right": "7px"
    },
    attrs: {
      src: require("../../../assets/images/tip1.png"),
      alt: ""
    }
  }), _vm._v("防止欠费，避免损失\n    ")]), _c("p", {
    staticClass: "font-12 color-666 flex align-center",
    staticStyle: {
      "margin-left": "80px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "18px",
      height: "18px",
      "margin-right": "7px"
    },
    attrs: {
      src: require("../../../assets/images/tip2.png"),
      alt: ""
    }
  }), _vm._v("减少操作，省时省力\n    ")])]), _c("div", {
    staticClass: "form_info"
  }, [_c("div", {
    staticClass: "form_item"
  }, [_c("label", [_vm._v("充值方式：")]), _c("div", {
    staticClass: "flex-1 flex align-center"
  }, [_vm._v("\n        " + _vm._s(_vm.type == 1 ? "合同" : _vm.type == 2 ? "短信" : "风控") + _vm._s(_vm.type != 3 ? "余量" : "次数") + "小于\n        "), _c("el-input", {
    staticStyle: {
      width: "90px",
      "margin-left": "10px"
    },
    attrs: {
      placeholder: "10",
      clearable: ""
    },
    on: {
      input: function input(val) {
        return _vm.changeNumberInput("baseline", val);
      }
    },
    model: {
      value: _vm.formData.baseline,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "baseline", $$v);
      },
      expression: "formData.baseline"
    }
  }), _c("span", {
    staticClass: "suffix",
    staticStyle: {
      "margin-right": "10px"
    }
  }, [_vm._v(_vm._s(_vm.type != 3 ? "条" : "次"))]), _vm._v("\n        时自动充值\n        "), _c("el-input", {
    staticStyle: {
      width: "90px",
      "margin-left": "10px"
    },
    attrs: {
      placeholder: "100",
      clearable: ""
    },
    on: {
      blur: _vm.calculate,
      input: function input(val) {
        return _vm.changeNumberInput("recharge_num", val);
      }
    },
    model: {
      value: _vm.formData.recharge_num,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "recharge_num", $$v);
      },
      expression: "formData.recharge_num"
    }
  }), _c("span", {
    staticClass: "suffix"
  }, [_vm._v(_vm._s(_vm.type != 3 ? "条" : "次"))])], 1)]), _c("div", {
    staticClass: "form_item"
  }, [_c("label", [_vm._v("花费金额：")]), _c("p", {
    staticClass: "flex-1"
  }, [_vm._v("\n        " + _vm._s(_vm.infoData.pay_total) + "元"), _c("span", {
    staticClass: "color-999"
  }, [_vm._v("（" + _vm._s(_vm.type == 1 ? _vm.infoData.contract : _vm.type == 2 ? _vm.infoData.sms_amount : _vm.infoData.risk_credit_price) + "元/" + _vm._s(_vm.type != 3 ? "条" : "次") + "）")])])]), _c("div", {
    staticClass: "form_item"
  }, [_c("label", [_vm._v("账户余额：")]), _c("div", {
    staticClass: "flex-1 flex"
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v(_vm._s(_vm.infoData.shop_balance) + "元")]), _c("p", [_vm._v("余额不足？"), _c("span", {
    staticClass: "blue-font pointer",
    on: {
      click: _vm.goToRecharge
    }
  }, [_vm._v("去充值")])])])]), _c("div", {
    staticClass: "form_item"
  }, [_c("label", [_vm._v("短信提示：")]), _c("div", {
    staticClass: "flex-1"
  }, [_c("el-radio", {
    attrs: {
      label: 1
    },
    model: {
      value: _vm.formData.sms_remind,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sms_remind", $$v);
      },
      expression: "formData.sms_remind"
    }
  }, [_vm._v("开启")]), _c("el-radio", {
    attrs: {
      label: 0
    },
    model: {
      value: _vm.formData.sms_remind,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sms_remind", $$v);
      },
      expression: "formData.sms_remind"
    }
  }, [_vm._v("关闭")]), _c("span", {
    staticClass: "color-999",
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(_vm._s(_vm.type == 1 ? "开启后将在短信增值服务自动开启" : "开启后，账户金额不足将短信提醒"))])], 1)]), _c("el-divider"), _c("div", {
    staticClass: "form_item"
  }, [_vm.type != 3 ? _c("label", [_vm._v("短信提示：")]) : _vm._e(), _c("div", {
    staticClass: "flex-1 rule_tip"
  }, [_vm.type == 1 ? [_vm._v("\n          1. 系统每日定时检查一次余量，满足条件则自动充值。"), _c("br"), _vm._v("\n          2. 优先使用可用现金付款，若账户余额不足，则无法充值。"), _c("br"), _vm._v("\n          3. 短信提醒开启后，扣款金额不足时将短信通知店铺联系人。\n        ")] : _vm._e(), _vm.type == 2 ? [_vm._v("\n          1. 系统每日定时检查一次余量，满足条件则自动充值。"), _c("br"), _vm._v("\n          2. 充值金额将从账户余额扣除，若账户余额不足，则无法充值。"), _c("br"), _vm._v("\n          3. 短信提醒开启后，扣款金额不足时将短信通知店铺联系人。\n        ")] : _vm._e()], 2)])], 1), _c("p", {
    staticStyle: {
      "padding-left": "50px",
      "margin-top": "20px",
      "margin-bottom": "10px"
    }
  }, [_c("el-checkbox", {
    class: {
      check_form: _vm.showError
    },
    on: {
      change: function change($event) {
        _vm.showError = false;
      }
    },
    model: {
      value: _vm.checked,
      callback: function callback($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }, [_vm._v("开启及同意"), _c("span", {
    staticClass: "blue-font"
  }, [_vm._v("《增值服务自动充值协议》")])])], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;