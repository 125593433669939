"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _setAddress = _interopRequireDefault(require("./dialogs/setAddress"));
var _lookJson = _interopRequireDefault(require("./dialogs/lookJson"));
var _default = {
  components: {
    customPage: _customPage.default,
    setAddress: _setAddress.default,
    lookJson: _lookJson.default
  },
  data: function data() {
    return {
      searchForm: {
        name: "",
        mobile: "",
        type: ""
      },
      total: 0,
      tableData: [],
      editItem: {},
      riskList: [{
        id: '1',
        name: '风控一'
      }, {
        id: '2',
        name: '风控二'
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _order.getRiskBlacklistList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
        // console.log(this.tableData.length)
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      var searchForm = this.$options.data().searchForm;
      this.searchForm = (0, _objectSpread2.default)({}, searchForm);
      this.getList();
    },
    lookJson: function lookJson(item) {
      console.log("sdfsdfsd");
      this.editItem = item.json;
      console.log(this.editItem);
      this.$refs.lookJson.dialogVisible = true;
    }
  }
};
exports.default = _default;