"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sec_banner"
  }, [_c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _c("p", [_c("span", [_vm._v("图片要求！")]), _c("br"), _vm._v("图片宽度" + _vm._s(_vm.imgLimit.width) + "px，高度" + _vm._s(_vm.imgLimit.height) + "px。")])], 1), _vm._l(_vm.bannerList, function (item, index) {
    return [_c("banner-form", {
      key: index,
      staticStyle: {
        "margin-top": "10px"
      },
      attrs: {
        "item-index": index,
        "show-tool": true,
        "form-data": item,
        type: "secBanner",
        "img-limit": _vm.imgLimit
      },
      on: {
        upSortItem: function upSortItem($event) {
          return _vm.upSortItem(index);
        },
        downSortItem: function downSortItem($event) {
          return _vm.downSortItem(index);
        },
        deleteItem: function deleteItem($event) {
          return _vm.deleteItem(index);
        }
      }
    })];
  }), _vm.bannerList.length > 0 && _vm.bannerList.length < 5 ? _c("el-divider") : _vm._e(), _vm.bannerList.length < 5 ? _c("div", {
    staticClass: "add-div",
    on: {
      click: _vm.addItem
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  }), _vm._v("添加图片(" + _vm._s(_vm.bannerList.length) + "/5)\n\t")]) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;