"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _web = require("@/api/web");
var _default = {
  props: ["parentId", "editId"],
  data: function data() {
    return {
      typeList: [],
      form: {
        parentid: 0,
        type_name: "",
        sort: 0,
        status: 1
      },
      rules: {
        type_name: [{
          required: true,
          message: '请输入信息配置名称',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    // 编辑获取信息
    getData: function getData() {
      var _this = this;
      (0, _web.getInfoConfig)(this.editId).then(function (res) {
        _this.form = res;
      });
    },
    // 表单提交
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          (0, _web.addOrEditInfoConfig)(_this2.form).then(function (res) {
            if (!_this2.editId) {
              // 新增跳转列表页
              _this2.$router.push({
                name: "articleInfoConfig"
              });
            }
          });
        } else {
          _this2.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    },
    // 重置
    resetForm: function resetForm() {
      this.$refs['form'].resetFields();
      if (this.editId) {
        this.getData();
      } else {
        this.form = {
          parentid: this.parentId ? this.parentId : 0,
          type_name: "",
          sort: 0,
          status: 1
        };
      }
    },
    // 获取下拉列表
    getList: function getList() {
      var _this3 = this;
      (0, _web.getInfoTypeList)().then(function (res) {
        _this3.typeList = res;
        if (_this3.parentId) {
          _this3.form.parentid = parseInt(_this3.parentId);
        }
      });
    }
  },
  mounted: function mounted() {
    // console.log(this.parentId, this.editId);
    this.getList();
    if (this.editId) {
      this.$route.meta.title = "编辑信息";
      this.getData();
    } else {
      this.$route.meta.title = "添加信息";
    }
  }
};
exports.default = _default;