"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _editAPaymentData = _interopRequireDefault(require("./dialog/editAPaymentData"));
var _editAPayPwd = _interopRequireDefault(require("./dialog/editAPayPwd.vue"));
var _apayment = require("@/api/apayment");
var _default = {
  components: {
    customPage: _customPage.default,
    editAPaymentData: _editAPaymentData.default,
    editAPayPwd: _editAPayPwd.default
  },
  data: function data() {
    return {
      loading: true,
      searchForm: {
        pay_no: '',
        name: '',
        account: '',
        status: ''
      },
      tableData: [],
      total: 0,
      selectList: [],
      selectedItem: {}
    };
  },
  methods: {
    // 获取数据
    getList: function getList() {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _apayment.getAPaymentList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total || 0;
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        pay_no: '',
        name: '',
        account: '',
        status: ''
      };
      this.getList();
    },
    // 导出
    exportFile: function exportFile() {
      leaseListExport(this.searchForm);
    },
    addAPaymentData: function addAPaymentData() {
      this.selectedItem = {};
      this.$refs.editAPaymentData.dialogVisible = true;
    },
    saveAPaymentData: function saveAPaymentData(item) {
      this.selectedItem = item;
      this.$refs.editAPaymentData.dialogVisible = true;
    },
    delAPayment: function delAPayment(id) {
      var _this2 = this;
      this.$confirm("您确定要删除记录吗？", "删除信息", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _apayment.delAPayment)({
          id: id
        }).then(function (res) {
          setTimeout(function () {
            _this2.getList();
          }, 1000);
        });
      }).catch(function () {});
    },
    handlePayPwd: function handlePayPwd(id) {
      this.selectedItem = id;
      console.log('selectedItem', this.selectedItem);
      this.$refs.editAPayPwd.dialogVisible = true;
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;