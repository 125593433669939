"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "top_banner"
  }, [_c("div", {
    staticClass: "warn-box"
  }, [_c("svg-icon", {
    staticStyle: {
      "margin-right": "17px"
    },
    attrs: {
      "icon-class": "warn",
      size: 20
    }
  }), _vm._m(0)], 1), _c("banner-form", {
    attrs: {
      type: "capsuleBanner",
      "item-index": 0,
      "form-data": _vm.banner
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", [_vm._v("图片要求！")]), _c("br"), _vm._v("图片宽度690px，高度170px")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;