"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "ruleForm",
    staticClass: "base_form",
    attrs: {
      model: _vm.submitForm,
      "label-width": "200px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "租赁说明"
    }
  }, [_c("div", {
    staticClass: "editor_wrap"
  }, [_c("text-editor", {
    model: {
      value: _vm.submitForm.rent_content,
      callback: function callback($$v) {
        _vm.$set(_vm.submitForm, "rent_content", $$v);
      },
      expression: "submitForm.rent_content"
    }
  })], 1)]), _c("el-form-item", {
    attrs: {
      label: "监管说明"
    }
  }, [_c("div", {
    staticClass: "editor_wrap"
  }, [_c("text-editor", {
    model: {
      value: _vm.submitForm.supervise_content,
      callback: function callback($$v) {
        _vm.$set(_vm.submitForm, "supervise_content", $$v);
      },
      expression: "submitForm.supervise_content"
    }
  })], 1)])], 1), _c("div", {
    staticClass: "fix-footer-btns"
  }, [_vm.$hasMethod("#edit") ? _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")]) : _vm._e()], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;