"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _shop = require("@/api/shop");
var _default = {
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        cat_name: ""
      },
      rules: {
        cat_name: [{
          required: true,
          message: '分类名称不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    // 提交
    submit: function submit() {
      var _this = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          (0, _shop.addShopCategory)(_this.form.cat_name).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit("getList");
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;
      if (val) {
        this.$nextTick(function () {
          _this2.$refs['form'].resetFields();
          _this2.form = {
            cat_name: ''
          };
        });
      }
    }
  }
};
exports.default = _default;