"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    staticClass: "add_dialog",
    attrs: {
      width: "600px",
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      "label-width": "120px"
    }
  }, [_vm.sMerchantId == 0 ? [_c("el-form-item", {
    attrs: {
      label: "商户名称",
      prop: "merchant_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.merchant_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "merchant_id", $$v);
      },
      expression: "form.merchant_id"
    }
  }, _vm._l(_vm.merchantList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.username,
        value: item.id
      }
    });
  }), 1)], 1)] : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "换电柜编号",
      prop: "device_id"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请输入关键词",
      filterable: "",
      remote: "",
      "remote-method": _vm.getdeviceList,
      loading: _vm.loading
    },
    model: {
      value: _vm.form.device_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "device_id", $$v);
      },
      expression: "form.device_id"
    }
  }, _vm._l(_vm.deviceList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.terminal_no,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "仓门号",
      prop: "warehouse_no"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.warehouse_no,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "warehouse_no", $$v);
      },
      expression: "form.warehouse_no"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "启用状态",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "启用",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "停用",
      value: 0
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "有无电池",
      prop: "is_battery"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.is_battery,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_battery", $$v);
      },
      expression: "form.is_battery"
    }
  }, [_c("el-option", {
    attrs: {
      label: "有",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "无",
      value: 0
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "电池编号",
      prop: "battery_no"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.battery_no,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "battery_no", $$v);
      },
      expression: "form.battery_no"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "电池电压",
      prop: "battery_voltage"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.battery_voltage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "battery_voltage", $$v);
      },
      expression: "form.battery_voltage"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "电池电量",
      prop: "battery_electric"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.battery_electric,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "battery_electric", $$v);
      },
      expression: "form.battery_electric"
    }
  })], 1)], 2), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary",
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;