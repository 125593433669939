"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "office_index"
  }, [_c("div", {
    staticClass: "flex main"
  }, [_c("div", {
    staticClass: "flex-1 left"
  }, [_c("div", {
    staticClass: "m-auto model_wrap"
  }, _vm._l(_vm.sectionList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "section_wrap",
      class: {
        active: index + 1 == _vm.activeNum
      },
      on: {
        click: function click($event) {
          _vm.activeNum = index + 1;
        }
      }
    }, [_c("img", {
      staticClass: "block w-100",
      attrs: {
        src: require("../../../assets/images/xcx/businessCooperation/" + item.imgName),
        alt: item.title
      }
    })])]);
  }), 0)]), _c("div", {
    staticClass: "right"
  }, [_c("h5", {
    staticClass: "color-666 normal-font header"
  }, [_vm._v(_vm._s(_vm.sectionList[_vm.activeNum - 1].title) + " ")]), _c("div", {
    staticClass: "main_form"
  }, [_c("comp-form", {
    ref: "compForm",
    attrs: {
      "goods-num": _vm.sectionList[_vm.activeNum - 1].num,
      "level-name": _vm.sectionList[_vm.activeNum - 1].key,
      "comp-form-data": _vm.compFormData,
      imgWidth: _vm.imgWidth,
      imgHeight: _vm.imgHeight
    }
  })], 1), _c("div", {
    staticClass: "text-center footer"
  }, [_c("el-button", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")])], 1)])]), _c("div", {
    staticClass: "fix-footer-btns"
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("发布")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;